<template>
	<div class="hyperpay-form" v-if="actionUrl !== '' && cardType" :style="{ '--main-color': $root.mainColor }">		
		<form v-if="cardType" :action="actionUrl" class="paymentWidgets" :data-brands="cardType"></form>
	</div>
</template>
<style lang="scss">
  .hyperpay-form{
    margin-top: 50px;
    .wpwl-form-card {
      background: none;
      padding: 12px;
      border: 0;
      border-radius: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    .wpwl-button-pay {
        color: #fff;
        background-color: var(--main-color);
        border-color: var(--main-color);
    }
    .wpwl-wrapper-cardNumber{
      text-transform: uppercase;
      height: 44px;
      border: none;      
      background: #f0f0f0;
      border-radius: 5px;
      margin-bottom: 20px;
      font-size: 14px;	    
      font-family: 'Poppins' !important;        
    }
    .wpwl-control{
      width: 100%;
        height: 44px;
        border: none;
        padding: 0 18px;
        background: #f0f0f0;
        border-radius: 5px;
        margin-bottom: 20px;
        font-size: 14px;	    
    }
    .wpwl-brand-VISA{
      margin-top: 10px;
    }
    .wpwl-brand-MASTER {	    
        margin-top: 3px;
    }
    .wpwl-label {
        padding-right: 24px;	    
        line-height: 45px;
        text-transform: uppercase;
        @media (max-width: 767px)
        {
          width: 100%;
        }
    }
    .wpwl-has-error~.wpwl-hint {
        color: #a94442;
        text-transform: uppercase;
        font-size: 12px;
        font-variant: small-caps;
        text-transform: unset;
    }
    .wpwl-message{
      text-align: center;
      font-variant: small-caps;
        text-transform: inherit;
    }
    input::placeholder{
        text-transform: uppercase;
      }
      @media (max-width: 768px) {
        padding: 20px;
      }
  }

  .wpwl-apple-pay-button.wpwl-apple-pay-button-white-with-line {
    display: inline-block !important;
    -webkit-appearance: -apple-pay-button !important;
    cursor: pointer !important;
  }
</style>
<script>
  import { TranslationMixin, HelperMixin } from "@/mixins";
import { mapGetters } from 'vuex';
	export default {		
		mixins: [HelperMixin,TranslationMixin],
		data()
		{
			return {
        actionUrl: '',
        cardType: null,
        cardIsReady: false
			}
    },		
    computed: {
      ...mapGetters({
        getConcept: 'concept/getConcept'
      })
    },
    beforeCreate() {
      
    },
		mounted()
		{	
      if(this.$route.params.method) {
        this.cardType = this.$route.params.method.toUpperCase().replace('-','')
      } else {
        this.cardType = 'VISA MASTER'
      }					                              

      var wpwlOptions = {
          style: "card"
      }
      let oppwa = ''
      if(this.getConcept.attributes['payment-gateway'] === 'hyperpay')
      {
        oppwa = 'https://oppwa.com/v1/paymentWidgets.js'
      } else {
        oppwa = 'https://test.oppwa.com/v1/paymentWidgets.js'
      }

      this.actionUrl = document.location.protocol+ '//' +document.location.host + `/order/${this.$route.params.orderId}/status/${this.$route.params.id}`

			let recaptchaScript = document.createElement('script')
		    recaptchaScript.setAttribute('src', `${oppwa}?checkoutId=${this.$route.params.id}`)
        document.head.appendChild(recaptchaScript)            
		},		
	}
</script>