<template>
    <div class="add-ons-wrapper">
        <label>{{child.attributes.name}} </label>
        <AdditionalModifierOptions ref="modifier" :index="index" :modifier="modifier" v-for="(modifier, index) in modifiers" :key="index"></AdditionalModifierOptions>        
    </div>
</template>
<script>
import { TranslationMixin } from "@/mixins";
import { mapGetters } from 'vuex';
import AdditionalModifierOptions from './AdditionalModifierOptions'
export default {
    props: ['child', 'included', 'modifier'],
    data()
    {
        return {
      
        }
    },
    computed: {
        modifiers()
        {
            return this.child.relationships.modifiers.data.map(modifier => {
                let data = this.included.filter(include => {
                    return modifier.id === include.id
                })                
                return data[0]
            })
        }
    },
    components: {AdditionalModifierOptions},
    mounted()
    {                
        console.log(this.child)
    },
    methods: {
        
    }
}
</script>