<template>
    <section class="header-wrapper order-history">
        <h5 class="order-history-caps">{{translation('order_history')}}</h5>
        <b-card v-for="(order, index) in orders" :key="index" @click="orderStatus(order.id)">
            <div class="ellipses" :style="{ '--main-color': $root.mainColor }">
                <i class="fa fa-ellipsis-h"></i>
            </div>

            <div class="order-details">
                <p class="display-id" :style="{ '--main-color': $root.mainColor }">{{ order.attributes['current-status']['description'] }}</p>
                <span class="progress" :style="{ '--main-color': $root.mainColor, '--secondary-color': hexToRgbA($root.mainColor) }">{{translateNumber(order.attributes.total)}} {{translation(currency.key, currency.value)}}</span>
                <small>
                    {{translation('order_id_label', 'Order ID (x)').replace('(x)', order.attributes['display-id'])}}
                    <span class="promised-date">{{getPromisedDate(order.attributes['promised-time'].replace('T',' '))}}</span>
                    <span class="promised-time">{{getPromisedTime(order.attributes['promised-time'].replace('T',' '))}}</span>
                </small>
            </div>
        </b-card>
    </section>
</template>
<script>
import { TranslationMixin } from "@/mixins";
import { mapGetters } from "vuex";
import { Customer } from '@/services/SOLO'
import moment from 'moment'
export default {
    data()
    {
        return {
            orders: []
        }
    },
    mixins: [TranslationMixin],
    computed: {
        ...mapGetters({
            getFirstName: "account/getFirstName",
            getMobile: "account/getMobile",
            getEmail: "account/getEmail",
            getUser: 'account/getUser',
            getLocale: 'app/getLocale',
            currency: 'concept/currency',
        })
    },
    mounted()
    {
        this.getOrders()
    },
    methods: {
        hexToRgbA(hex){
            var c;
            if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
                c= hex.substring(1).split('');
                if(c.length== 3){
                    c= [c[0], c[0], c[1], c[1], c[2], c[2]];
                }
                c= '0x'+c.join('');
                return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',0.05)';
            }
            throw new Error('Bad Hex');
        },
        orderStatus(id)
        {
            this.$router.push({name: 'status', params: { id: id }})
        },
        setProfileComponent(component) {
            this.$parent.current = component;
        },
        getOrders() {
            Customer.getOrders(this.getUser.id).then(response => {
                this.orders = response.data.data;
            });
        },
        getPromisedDate(promisedTime)
        {
            return moment.utc(promisedTime,'YYYY-MM-DD h:mm:ss').locale(this.getLocale).local().format('MM-DD-YY')
        },
        getPromisedTime(promisedTime)
        {
            return moment.utc(promisedTime,'YYYY-MM-DD h:mm:ss').locale(this.getLocale).local().format('h:mm A')
        },
    }
};
</script>
