<template>
    <b-row>
        <b-col md="6">
            <b-card class="order-default-type-card bg-light-blue border-light-blue">
                <template v-if="hasDefault">

                    <div class="title" v-if="getOrderType === 'deliver'">
                        <img src="/images/icons/delivery blue logo.png" alt=""> {{$parent.translation('deliver_to')}}
                        <button class="btn btn-sm btn-change-default">Change</button>
                    </div>
                    <div class="title" v-else-if="getOrderType === 'pickup' || getOrderType === 'eat-in' ">
                        <img src="/images/icons/delivery blue logo.png" alt=""> {{$parent.translation(getOrderType === 'pickup' ? 'pickup_at' : 'dine_in_at')}}
                        <button class="btn btn-sm btn-change-default">Change</button>
                    </div>

                    <div class="description" v-if="getOrderType === 'deliver'">
                        <img src="/images/icons/person.png" alt="">
                        <div class="sub-description">
                            <p class="customer-name">Julia hasan</p>
                            <small class="customer-address">
                                <img class="pin" src="/images/icons/blue-pin-small.png" width="20" alt=""> 17/14 Garden Road, Tejgaon
                            </small> 
                        </div>
                    </div>

                    <div class="description" v-else-if="getOrderType === 'pickup' || getOrderType === 'eat-in' ">
                        <img src="/images/icons/branch logo.png" alt="">                        
                        <div class="sub-description">
                            <p class="customer-name">{{getLocation.attributes.name}}</p>
                            <small class="customer-address d-none">
                                <img class="pin" src="/images/icons/blue-pin-small.png" width="20" alt=""> {{getLocation.attributes.name}}
                            </small> 
                        </div>
                    </div>

                </template>
                <template v-else>
                    <div class="title text-center">
                        <h4>NO DEFAULT SET</h4>
                    </div>
                </template>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            getLocation: 'cart/getLocation',
            hasDefault: 'cart/hasDefault',
            getOrderType: 'cart/getOrderType',
            getLocation: 'cart/getLocation',
        })
    },    
    mounted()
    {
        console.log(this.getLocation)
    }
}
</script>