<template>
    <b-card
        class="text-muted modifier-content"
        :style="checkedStyle"
        :class="{ 'selected' : checked && $parent.modifierGroup.attributes.maximum === 1, 'selected' : checked && modifier.attributes.maximum === 1 }"
        @click="toogleModifier"
    >
        <img
            :src="modifier.attributes['image-uri']"
            v-if="!modifier.attributes['image-uri'].includes('default')"
            height="40"
            width="40"
            alt
        />
        <span v-if="modifier.attributes['image-uri'].includes('default')" class="space"></span>
        <div class="text" :style="{ color: quantity ? '#fff' : '#6c757d' }" style="line-height: 12px; max-width: 80px">
            {{modifier.attributes.name}}
        </div>
        <div class="side-content">
            <small v-if="modifier.attributes.price" :style="!quantity && modifier.attributes.price ? buttonTextStyle : {}">{{translateNumber(modifier.attributes.price)}} {{translation(currency.key, currency.value)}}</small>
            <b-button-group size="sm" v-if="$parent.modifierGroup.attributes.maximum > 1 && modifier.attributes.maximum > 1 && quantity || minIsMax">
                <b-button :disabled="minIsMax" :style="{...buttonTextStyle, ...hideBtn}" ref="decrement" @click.stop="decrementQty" class="mod-dec">
                    <i class="fa" aria-hidden="true" :class="{ 'fa-angle-right' : getLocale != 'en', 'fa-angle-left' : getLocale == 'en'}"></i>
                </b-button>
                <b-button :style="buttonTextStyle">{{translateNumber(quantity)}}</b-button>
                <b-button :disabled="minIsMax" :style="{...buttonTextStyle, ...hideBtn}" ref="increment" @click.stop="incrementQty" class="mod-inc">
                    <i class="fa" aria-hidden="true" :class="{ 'fa-angle-left' : getLocale != 'en', 'fa-angle-right' : getLocale == 'en'}"></i>
                </b-button>
            </b-button-group>
        </div>
    </b-card>
</template>
<script>
import { mapGetters } from 'vuex';
import { TranslationMixin } from "@/mixins";
export default {
    props: ["modifier"],
    data() {
        return {
            quantity: 0,
            checked: false,
            selected: 0,
            numOfFree: 0
        };
    },
    mixins: [TranslationMixin],
    watch: {
        quantity(data)
        {
            this.$parent.$parent.$parent.$refs.Addtocart.modifierPrice = this.$parent.$parent.$parent.$refs.Addtocart.setTotalModifiersPrice();
        },
        checked(data)
        {
            if (data && this.$parent.modifierGroup.attributes.maximum === 1) {
                console.log(1)
                this.quantity = 1;
                this.$parent.$refs.modifier.map(ref =>
                {
                    if (ref.modifier.id != this.modifier.id)
                    {
                        ref.checked = false;
                        ref.quantity = 0;
                    }
                });
            } else
            if (data && this.$parent.modifierGroup.attributes.maximum > 1 && this.modifier.attributes.maximum === 1 ) {
                console.log(2)
                if(this.getTotalQty() < this.$parent.modifierGroup.attributes.maximum)
                {
                    this.quantity = 1;
                }else{
                    this.checked = false
                }
            }else
            if(this.minIsMax)
            {
                this.quantity = this.$parent.modifierGroup.attributes.minimum
            } else {
                this.quantity = 0;
            }
        }
    },
    computed: {
        ...mapGetters({
            getLocale: 'app/getLocale',
            currency: 'concept/currency',
        }),
        minIsMax()
        {
            return this.$parent.modifierGroup.attributes.minimum === this.$parent.modifierGroup.attributes.maximum && this.$parent.modifierGroupData.length === 1
        },
        hideBtn()
        {
            if(this.minIsMax)
            {
                return {
                    display: 'none'
                }
            }

            return {

            }
        },
        checkedStyle()
        {
            if (this.quantity)
            {
                return {
                    background: this.$root.mainColor,
                    "--main-color": this.$root.mainColor,
                    color: '#fff'
                };
            }

            return {

            };
        },
        buttonTextStyle()
        {
            return {
                color: this.$root.mainColor
            }
        }
    },
    mounted() {
        // console.log(this.modifier)
        this.checkMinimum()

        if(this.$parent.modifierGroup.attributes.minimum === this.$parent.modifierGroup.attributes.maximum && this.$parent.modifierGroupData.length === 1)
        {
            this.quantity = this.$parent.modifierGroup.attributes.minimum
        } else if(this.$parent.modifierGroup.attributes.minimum === this.$parent.modifierGroup.attributes.maximum && this.modifier.attributes.minimum === this.modifier.attributes.maximum) {
            this.quantity = this.modifier.attributes.minimum
        }
    },
    methods: {
        checkMinimum()
        {
            if(this.modifier.attributes.minimum && (this.$parent.modifierGroup.attributes.maximum === 1 || this.modifier.attributes.maximum === 1))
            {
                this.checked = true
            } else if (this.modifier.attributes['is-default']) {
                this.checked = true
            }
        },
        toogleModifier()
        {
            if(!this.minIsMax)
            {
                if (this.$parent.modifierGroup.attributes.maximum === 1 || this.modifier.attributes.maximum === 1)
                {
                    this.checked = this.checked ? false : true;
                }else
                if(!this.quantity)
                {
                    this.incrementQty()
                }else
                {
                    this.quantity = 0
                }
            }
        },
        getTotalQty()
        {
            return this.$parent.$refs.modifier
                .map(ref =>
                {
                    return ref.quantity;
                })
                .reduce((a, b) => {
                    return a + b;
                });
        },
        checkMax()
        {
            if ( this.getTotalQty() < this.$parent.modifierGroup.attributes.maximum && this.quantity < this.modifier.attributes.maximum )
            {
                return false;
            }
            return true;
        },
        incrementQty()
        {
            if (!this.checkMax())
            {
                this.quantity++;
            }
        },
        decrementQty()
        {
            if (this.quantity) this.quantity--;
        }
    }
};
</script>
