<template>    
    <div>
        <b-card
            class="text-muted modifier-content"
            :style="checkedStyle"                        
            ref="ingredient"
            @click="toogleIngredient"                                    
        >        
            <img
                :src="ingredient.attributes['image-uri']"            
                v-if="checkIfImageIsAttributes(ingredient)"
                height="40"
                width="40"
                alt
            />
            <span v-if="checkIfImageIsAttributes(ingredient)" class="space"></span>
            <div class="text" :style="{ color: checked ? '#fff' : '#6c757d' }">
                {{ingredient.attributes.name}}
            </div>            
        </b-card>
    </div>
</template>
<style lang="scss" scoped>
    .card-body{
        min-height: 43px;
    }
</style>
<script>
import { mapGetters } from 'vuex';
export default {
    props: ['ingredient'],
    data()
    {
        return {
            checked: false
        }
    },
    computed: {
        ...mapGetters({
            getLocale: 'app/getLocale'
        }),        
        buttonTextStyle()
        {
            return {
                color: this.$root.mainColor
            }
        },
        checkedStyle()
        {
            if (this.checked)
            {
                return {
                    background: this.$root.mainColor,
                    "--main-color": this.$root.mainColor,
                    color: '#fff'
                };
            }

            return {};
        },
    },
    mounted()
    {
                
    },
    methods: {
        toogleIngredient()
        {
            this.checked = this.checked ? false : true;
        },
        checkIfImageIsAttributes(ingredient)
        {
            if(ingredient.attributes['image-uri'])
            {            
                return !ingredient.attributes['image-uri'].includes('default')
            }
            return false
        }        
    }
}
</script>