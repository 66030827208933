import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

/**
 *==================================
 ************ plugins **************
 *==================================
 */

import './assets/plugins/bootstrap-vue';
import './assets/plugins/vue-toasted';
import './assets/plugins/vuelidate';
import './assets/plugins/vue-google-maps.js';

/**
 *==================================
 ************ mixins ***************
 *==================================
 */

/**
 *==================================
 *********** services **************
 *==================================
 */

import { Config, Concept } from './services/SOLO'

/**
 *==================================
 *********** components ************
 *==================================
 */

import './layouts';

/**
 *==================================
 ******** data configuration *******
 *==================================
 */

import config from './data';
import { mapMutations, mapGetters, mapState } from 'vuex';
import { eventHub } from './assets/plugins/events';
import moment from 'moment'

Vue.config.productionTip = false;
Vue.prototype.translation = []

import VueMeta from 'vue-meta'
import data from './data';
Vue.use(VueMeta)

new Vue({
    data() {
        return {
            ...config,
            appReady: false,
            embedded: window.self !== window.top? true : false
        }
    },
    metaInfo() : any
    {
        return this.metaTags
    },
    router,
    store,
    computed: {
        ...mapGetters({
            getSettings: 'app/getSettings',
            getConcept: 'concept/getConcept',
            getItemUpdateTime: 'cart/getItemUpdateTime',
            isLoggedIn: 'account/isLoggedIn',
            getPagesRules: 'app/getPagesRules',
            getCart: 'cart/getCart',
            getLocale: 'app/getLocale',
            getTableTimestamp: 'cart/getTableTimestamp'
        }),
        ...mapState({
            cart: 'cart'
        }),
        items()
        {
            return this.cart.items
        },
        metaTags(): any
        {
            return {}
        },
        mainColor() : any
		{
            if(this.getSettings.hasOwnProperty('attributes'))
            {
                return this.getSettings.attributes.theme.primary_color
            }

            return '#fff'
        },
        secondaryColor() : any
		{
            if(this.getSettings.hasOwnProperty('attributes'))
            {
                return this.getSettings.attributes.theme.secondary_color
            }

            return '#fff'
        },
        gtmId() {
            if(this.getSettings.hasOwnProperty('attributes'))
            {
                return this.getSettings.attributes['gtm-id']
            }
            return null
        }
    },

    mounted()
    {
        this.checkTableTimestamp();

        this.cacheItems()

        this.getTapPublicKey()

        if(this.$route.query.hasOwnProperty('soloApp'))
        {
            this.setConcept({})
            this.getApplication(this.$route.query.soloApp)
        }else
        {
            this.findConcept()
        }

        let root = document.documentElement;
        root.style.setProperty('--main-color', this.mainColor)

    },
    watch: {
        items(oldVal, newVal)
        {
            this.setItemUpdateTime()
        },
        getSettings: function(settings, newVal) {
            this.setGtmId()
        },
        $route(to, from) {
            this.setDataLayer()
        }
    },
    methods: {
        ...mapMutations({
            setConcept: 'concept/setConcept',
            setSettings: 'app/setAppSettings',
            setTapCredentials: 'app/setTapCredentials',
            setItemUpdateTime: 'cart/setItemUpdateTime',
            disableForceLocation: 'app/disableForceLocation',
            emptyCart: 'cart/emptyCart',
            emptyLocations: 'cart/emptyLocations',
            setCoupon: 'cart/setCoupon',
            setCouponResult: 'cart/setCouponResult',
            setTable: 'cart/setTable',
            setTimestamp: 'cart/setTimestamp',
        }),

        checkTableTimestamp()
        {
            setInterval(()=>{

                let timestamp = new Date(parseInt(this.getTableTimestamp));

                if(timestamp < new Date())
                {
                    this.setTable(null);
                    this.setTimestamp(null);
                }

            }, 60000); //1 min
        },

        getTapPublicKey()
        {
            Concept.getTapPublicKey().then((response: any) => {
                this.setTapCredentials(response.data.data)
            })
        },
        setGtmId() {
            if(this.getSettings.attributes['gtm-id'])
            {
                let externalScript = document.createElement('script')
                externalScript.text= `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${this.getSettings.attributes['gtm-id']}');`
                document.head.appendChild(externalScript)
                this.setDataLayer()
            }
        },
        setDataLayer(){
            let ifLayerExist = document.getElementById('data-layer')
            if(!ifLayerExist) {
                let dataLayer: any = document.createElement('script')
                dataLayer.setAttribute("id", "data-layer");
                dataLayer.innerHTML = `dataLayer[{ pageName: '${this.$route.name}', language: '${this.getLocale}' }]`
                document.head.appendChild(dataLayer)
            } else {
                ifLayerExist.innerHTML = `dataLayer[{ pageName: '${this.$route.name}', language: '${this.getLocale}' }]`
            }
        },
        cacheItems()
        {
            if(this.getItemUpdateTime)
            {
                if(moment().diff(moment(this.getItemUpdateTime).add(1, 'hours')) >= 0)
                {
                    this.emptyCart()
                    // this.emptyLocations()
                    this.setItemUpdateTime()
                }
            }
        },
        findConcept()
        {
            this.getApplicationSettings()
        },
        forceLocation()
        {
            if(this.getSettings.attributes['force-location'] === 1 && !this.getCart.queue)
            {
                this.disableForceLocation(0)
                this.$router.push({name: 'delivery-location'})
            }
        },
        getApplication(soloAppKey: string = '')
        {
            Config.getApplicationsByKey(soloAppKey).then((response: any) => {
                let settings = response.data.data;
                let conceptSettings = response.data.included[0];

                if(this.checkAttributes(settings.attributes))
                {

                    conceptSettings.attributes = {
                        ...conceptSettings.attributes,
                        'allowed-order-types': settings.attributes['allowed-order-types'],
                        'allowed-payments-deliver': settings.attributes['allowed-payments-deliver'],
                        'allowed-payments-pickup': settings.attributes['allowed-payments-pickup'],
                        'key': settings.attributes['key'],
                        'slider-id': settings.attributes['slider-id'],
                        'payment-gateway': settings.attributes['payment-gateway'],
                        'menu-only': settings.attributes['menu-only'],
                    };
                    this.setDefaultLocale(conceptSettings);

                    this.$store.commit('concept/setConcept', conceptSettings)
                    this.$store.commit('app/setAppSettings', settings)

                    document.title = settings.attributes.label;
                    if(this.$route.name === 'home')
                    {
                        this.forceLocation()
                    }
                    eventHub.$emit('app-ready')
                }
            })
        },
        getApplicationSettings()
        {
            Config.getApplications().then((response: any) => {
                let settings = response.data.data;
                let conceptSettings = response.data.included[0];

                if(this.checkAttributes(settings.attributes))
                {
                    if(this.getSettings.hasOwnProperty('attributes'))
                    {
                        settings.attributes['force-location'] = this.getSettings.attributes['force-location']
                    }

                    conceptSettings.attributes = {
                        ...conceptSettings.attributes,
                        'allowed-order-types': settings.attributes['allowed-order-types'],
                        'allowed-payments-deliver': settings.attributes['allowed-payments-deliver'],
                        'allowed-payments-pickup': settings.attributes['allowed-payments-pickup'],
                        'key': settings.attributes.key,
                        'slider-id': settings.attributes['slider-id'],
                        'payment-gateway': settings.attributes['payment-gateway'],
                        'menu-only': settings.attributes['menu-only'],
                    };

                    this.setDefaultLocale(conceptSettings);

                    this.$store.commit('concept/setConcept', conceptSettings)
                    this.$store.commit('app/setAppSettings', settings)

                    document.title = settings.attributes.label;
                    if(this.$route.name === 'home')
                    {
                        this.forceLocation()
                    }
                    eventHub.$emit('app-ready')
                }
            }).catch((err: any) => {
                console.error(err)
            })
        },
        checkAttributes(attributes: any)
        {
            if(attributes.hasOwnProperty('slider-id') && attributes.hasOwnProperty('theme') && attributes.hasOwnProperty('default-menu-id'))
            {
                if(attributes.theme != null && attributes['default-menu-id'] != null )
                {
                    return true
                }else
                if(attributes['default-menu-id'] === null)
                {
                    this.$bvModal.msgBoxOk('Default menu id is not set! please contact support/admin', this.modalOption())
                }else
                if(attributes.theme === null)
                {
                    this.$bvModal.msgBoxOk('Default theme is not configured! please contact support/admin', this.modalOption())
                }else
                if(attributes['slider-id'] === null)
                {
                    this.$bvModal.msgBoxOk('Slider is not configured! please contact support/admin', this.modalOption())
                }

                return false
            }

            return false
        },
        modalOption()
        {
            return {
                 title: 'System Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
            }
        },
        setDefaultLocale(conceptSettings: any) {
            if (
                conceptSettings.attributes.languages &&
                Array.isArray(conceptSettings.attributes.languages) &&
                conceptSettings.attributes.languages.length === 1
            ) {
                const locale = conceptSettings.attributes['primary-language'] === 'en-us' ? 'en' : 'ar';
                this.$store.commit('app/setLocale', locale);
            }
        }
    },
    render: h => h(App)
}).$mount("#app");
