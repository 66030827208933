<template>
    <b-container fluid class="spicy-level">
        <b-row>
            <b-col md="10" class="offset-md-2">
                <label for="spice-range">{{translation('spice_level', 'Spice level')}}</label>
                <b-row class="mt-2">
                    <b-col md="4" class="text-left range-setter">
                        <small class="text-muted" @click="setLevel(0)">{{translation('regular', 'Regular')}}</small>
                    </b-col>
                    <b-col md="4" class="text-center range-setter">
                        <small class="text-muted" @click="setLevel(1)">{{translation('spicy', 'Spicy')}}</small>
                    </b-col>
                    <b-col md="4" class="text-right range-setter">
                        <small class="text-muted" @click="setLevel(2)">{{translation('naga', 'Naga')}}</small>
                    </b-col>
                </b-row>
                <b-form-input id="spice-range" v-model="value" type="range" min="0" max="2"></b-form-input>
                <output for="range" class="output" :value="value"></output>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { TranslationMixin } from '@/mixins'
export default {
    data() {
        return {
            value: 0
        };
    },
    mixins: [TranslationMixin],
    methods: {
        setLevel(lvl) {
            this.value = lvl;
        }
    }
};
</script>