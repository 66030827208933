import store from '../store'
import translation from '@/assets/json/translation'
import { eventHub } from '@/assets/plugins/events'
import {Decimal} from 'decimal.js';

const fields = translation.data.data[0].fields


let TranslationMixin = {
    methods: {
        translation(slug: keyof typeof fields, defaultValue: string){
            const language = store.getters['app/getLocale'] === 'en' ? 'en_US' : 'ar_SA'
            let translations_collection: any = translation.data.data[0].fields;
            if(translations_collection[slug]) {
                return translations_collection[slug][language]
            }

            return defaultValue;

        },
        getLang(locale: string) {
            return locale === 'en' ? 'en-US' : 'ar-SA';
        },
        setLocale(locale: any){
            store.commit('app/setLocale', locale);
            eventHub.$emit('change-lang', this.getLang(locale));
            eventHub.$emit('update-categories', true);
        },
        addDecimal(val = 0, decimal: number) {
            const locale = store.getters['app/getLocale'];
            const localeTranslate = locale === 'en' ? 'en-US' : 'ar-SA';

            return Number(val.toFixed(decimal)).toLocaleString(localeTranslate, {
                minimumFractionDigits: decimal,
                maximumFractionDigits: decimal,
            });
        },
        translateNumber(val: number, override = null): any {
            const concept = store.getters['concept/getConcept'];
            const decimal = override !== null ? override : concept?.attributes?.['decimal-places'] || 0;

            if (!val) {
                return this.addDecimal(0, decimal);
            }

            if (decimal > 0) {
                let localeString = this.addDecimal(Number(val), decimal);
                const dec = localeString.split('.')[1];

                if (dec && dec.length !== decimal && dec.length < decimal) {
                    for (let i = 0; i < decimal - dec.length; i++) {
                        localeString += '0';
                    }
                }
                return localeString;
            }
            return this.addDecimal(Number(val), decimal);
        }

    }
};

export default TranslationMixin
