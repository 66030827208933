export default {

    getCategories(state: { categories: any; }) {
        return state.categories
    },

    isReady(state: { ready: any; }){
        return state.ready;
    },

    getBranches(state: { branches: any; }){
        return state.branches
    },

    getLocale(state: { locale: any; }){
        return state.locale
    },

    getViewedItem(state: { viewedItem: any; }){
        return state.viewedItem
    },

    getViewedItemModifier(state: { viewedItem: { modifiers: any; }; }){
        return state.viewedItem.modifiers
    },

    getKuduCategory(state: { kuduCategory: any; })
    {
        return state.kuduCategory
    },
    addedToFavorites(state: { addedToFavorites: any; })
    {
        return state.addedToFavorites
    },

    getPagesRules(state: { pageRules: Object; })
    {
        return state.pageRules
    },

    getSettings(state: { settings: any; })
    {
        return state.settings
    },

    getAllowedOrderTypes(state: { settings: { attributes: { [x: string]: any; }; }; })
    {
        if(state.settings.hasOwnProperty('attributes'))
        {
            return state.settings.attributes['allowed-order-types'].split(',')
        }

        return []
    },

    getAllowedDeliveryPayments(state: { settings: { hasOwnProperty: (arg0: string) => any; attributes: { [x: string]: string; }; }; })
    {
        if(state.settings.hasOwnProperty('attributes'))
        {
            return state.settings.attributes['allowed-payments-deliver'].split(',')
        }

        return {}
    },

    getAllowedPickupPayments(state: { settings: { hasOwnProperty: (arg0: string) => any; attributes: { [x: string]: string; }; }; })
    {
        if(state.settings.hasOwnProperty('attributes'))
        {
            return state.settings.attributes['allowed-payments-pickup'].split(',')
        }

        return {}
    },

    getTapOrderID(state: { [x: string]: any; })
    {
        return state['tap-order-id']
    },

    getTapOrderAmount(state: { [x: string]: any; })
    {
        return state['tap-order-amount']
    },

    getTapPaymentResponse(state: { [x: string]: any; })
    {
        return state['tap-payment-response']
    },

    getTapOrderData(state: { [x: string]: any; })
    {
        return state['tap-payment-order-data']
    },

    getTapPaymentStatus(state: { [x: string]: any; })
    {
        return state['tap-payment-status']
    },

    getTapCredentials(state: { [x: string]: any; })
    {
        return state['tap-payment-public-credentials']
    },

    getCheckoutCredentials(state: { [x: string]: any; })
    {
        return state['checkout-payment-public-credentials']
    },

    getDisabledCategories(state: { disabledCategories: any; }) {
        return state.disabledCategories
    },

    getPaymentType(state: { paymentType: any; }) {
        return state.paymentType
    },

    getModifiers(state: { modifiers: any; }) {
        return state.modifiers
    },

    getIngredients(state: { ingredients: any; }) {
        return state.ingredients
    },

    getAllergens(state: { allergens: any }) {
        return state.allergens;
    },

    getKioskOrder(state: { kioskOrder: any; }) {
        return state.kioskOrder
    },

    getKioskVerifyResponse(state: { kioskVerifyResponse: any; }) {
        return state.kioskVerifyResponse
    },
    getToken(state: { token: any; }) {
        return state.token
    }
}
