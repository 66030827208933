<template>
  <section class="text-center">
    <section id="order-success">
      <div class="container d-flex justify-content-center mt-5" v-if="!ready">
        <div class="spinner-grow" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="container" v-if="ready">
        <div class="row">
          <div
            class="col-md-8 offset-md-2"
            :class="{ 'mr-auto': getLocale == 'ar' }"
          >
            <h2 v-if="paymentStatus.payment_successful">
              THANK YOU FOR ORDERING
            </h2>
            <div v-if="paymentStatus.payment_successful">
              YOUR ORDER WILL ARRIVE SHORTLY
            </div>

            <h2 class="text-danger" v-if="!paymentStatus.payment_successful">
              ORDER FAILED
            </h2>
            <h5 class="text-yellow">ORDER ID {{ $route.params.orderId }}</h5>
            <div class="text-danger" v-if="!paymentStatus.payment_successful">
              {{ paymentStatus.result.description.toUpperCase() }}
            </div>            
            
            <button
              :class="{
                'btn bg-danger text-white': !paymentStatus.payment_successful,
                'btn-track-order': paymentStatus.payment_successful,
              }"
              @click="trackOrder"
            >
              VIEW ORDER
            </button>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>
<script>
import { Order } from "@/services/SOLO";
import { TranslationMixin } from "@/mixins";
import { mapGetters, mapMutations } from 'vuex';
export default {
  data() {
    return {
      paymentStatus: {},
      ready: false,
    };
  },
	mixins: [TranslationMixin],
	computed: {
		...mapGetters({
			getLocale: "app/getLocale",  
		})
	},
  mounted() {    
    let params = {
      checkout_id: this.$route.params.id,
    };

    Order.hyperpayStatus(params)
      .then((response) => {        
				console.log(response.data)
        this.paymentStatus = response.data;
        this.emptyCart()
        this.resetCoupon()
        this.ready = true;
      })
      .catch((error) => {
        console.log(error.response.data.messages);
        this.emptyCart()
        this.resetCoupon()
        this.paymentStatus = error.response.data.messages;
        this.paymentStatus.payment_successful = false;
        this.ready = true;
      });
  },
  methods: {
		...mapMutations({
			emptyCart: "cart/emptyCart",
      resetCoupon: "cart/resetCoupon"
		}),
    trackOrder() {
      // this.$router.push({
      //   name: "orderview",
      //   params: { id: this.hyperPay.orderId },
      // });
    }    
  },
};
</script>