var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[(_vm.$route.name === 'default-location')?_c('gmap-map',{ref:"mapRef",staticStyle:{"width":"100%","height":"100%"},attrs:{"center":{lat: _vm.lat, lng: _vm.lng},"zoom":_vm.zoom,"options":{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: false
        }}},_vm._l((_vm.branches),function(m,index){return _c('GmapMarker',{key:index,staticClass:"info-window",attrs:{"position":m.position,"clickable":true,"icon":'/images/icons/blue-pin-small.png',"z-index":1},on:{"click":function($event){return _vm.markerClicked(m)}}},[(_vm.shouldOpenInfoWindow(m.id))?_c('gmap-info-window',{attrs:{"options":_vm.infoWindowStyle,"fontFamily":"Poppins"}},[_c('small',{staticClass:"d-block info-window-title"},[_vm._v("Location")]),_c('small',{staticClass:"font-weight-bold info-window-sub-title"},[_vm._v(_vm._s(m.attributes.name))])]):_vm._e()],1)}),1):_c('gmap-map',{ref:"mapRef",staticStyle:{"width":"100%","height":"100%"},attrs:{"center":{lat: _vm.lat, lng: _vm.lng},"zoom":_vm.zoom,"options":{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: false
        }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }