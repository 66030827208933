<template>
    <div class="container">        
        <ProfileCard></ProfileCard>        
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ProfileCard from './ProfileCard'
import { TranslationMixin } from '@/mixins'
export default {
    data() {
        return {
            
        };
    },
    mixins: [TranslationMixin],
    computed: {
        ...mapGetters('cart', {
            getItems: 'getItems'
        })
    },
    components: {
        ProfileCard
    }
};
</script>