<template>
    <div class="cart-content">
        <b-card v-for="(item, index) in getItems" :key="index">
            <b-button-group vertical>
                <b-button :disabled="qtyIsDisabled" @click="increase(index)">
                    <div class="item-plus" :style="{ background: $root.mainColor }">
                        <i class="fa fas fa-plus"></i>
                    </div>
                </b-button>
                <b-button>{{ translateNumber(item.quantity, 0) }}</b-button>
                <b-button :disabled="qtyIsDisabled" @click="decrease(index)">
                    <div class="item-minus" :style="{ background: $root.mainColor }">
                        <i class="fa fas fa-minus" aria-hidden="true"></i>
                    </div>
                </b-button>
            </b-button-group>
            <!-- :style="'background-image: url('+ item.imageUri +')'" -->

                <div class="item">
                    <img class="cartImg" :src="item.imageUri"/>
                    <button class="close" :disabled="qtyIsDisabled" @click="remove(index)">
                        <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                    <small class="title d-block">{{getName(item.attributes.name)}}</small>
                    <small class="text-primary price d-block" :style="priceStyle">{{ translateNumber((item.price + item.modifierPrice) * item.quantity) }} {{ translation(currency.key, currency.value) }}</small>
                    <div class="modifier-wrapper" v-if="getIngredients(item).length || item.hasOwnProperty('combo-items')">
                        <small class="text-muted">
                            {{translation('remove_text')}} {{getIngredients(item).join(', ')}}
                        </small>
                    </div>
                    <div class="modifier-wrapper" v-if="modifiersText(item).length || item.hasOwnProperty('combo-items')">
                        <small class="text-muted">
                            {{translation('options')}} {{modifiersText(item).join(', ')}}
                        </small>
                    </div>
                </div>

        </b-card>
    </div>
</template>
<style lang="scss" scoped>
    .cart-content {
        .item-plus, .item-minus {
            color: #ffffff;
            height: 24px;
            width: 24px;
            line-height: 25px;
            border-radius: 100%;
            font-size: 11px;
        }
        .cartImg {
            width: 70px;
            height: 65px;
            position: absolute;
            margin: 5px 0 0 -80px;
        }
    }
    .btn-group, .btn-group-vertical {
        position: relative;
        display: inline-flex;
        vertical-align: top;
    }
    .modifier-wrapper {
        // padding: 0;
        // display: flex;
        // overflow-x: scroll;
        // overflow-y: hidden;
        // height: 30px;
        margin-left: -70px;
        &::-webkit-scrollbar {
            width: 2px;
            height: 2px;
        }
        &::-webkit-scrollbar-thumb {
            background: #000;
            border-radius: 5px;
        }
        &::-webkit-scrollbar-track {
            background: #fff;
            border-radius: 5px;
        }
        small {
            // align-items: center;
            // height: 2rem;
            // display: flex;
            // align-items: center;
            // white-space: nowrap;
        }
    }
    body.rtl{
        .modifier-wrapper {
            margin-right: -70px;
            margin-left: 0;
        }

    .cart-content {
        direction: rtl !important;
        .cartImg {
            width: 70px;
            height: 65px;
            position: absolute;
            margin: 5px -70px 0 0px;
        }
        .title {
            margin-right: 10px !important;
        }
        .price {
            margin-right: 10px !important;
        }
    }
    }
</style>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { TranslationMixin, HelperMixin } from '@/mixins'
import { Coupon } from '@/services/SOLO'
export default {
    data() {
        return {
            locales: {
                en: 'en-us',
                ar: 'ar-sa'
            }
        }
    },
    props: {
        qtyIsDisabled: {
            default: false,
            type: Boolean
        }
    },
    mixins: [TranslationMixin, HelperMixin],
    computed: {
        ...mapGetters({
            getItems: 'cart/getItems',
            getConcept: "concept/getConcept",
            getLocale: 'app/getLocale',
            getLastLocation: 'cart/getLastLocation',
            getDisabledCategories: 'app/getDisabledCategories',
            getCart: "cart/getCart",
            getOrderType: 'cart/getOrderType',
            currency: 'concept/currency',
        }),
        priceStyle()
        {
            return {
                color: this.$root.mainColor + ' !important'
            }
        },
        cartItems() {
            return this.getCart.items
        }
    },
    watch: {
        getLastLocation() {

        },
        cartItems: {
            handler(data) {

            },
            deep: true
        },
        getOrderType: {
            handler(data) {
                this.filterDisabledItems()
                if(this.getAllDisabledItems(data).length) {
                    this.removeDisabledItems(data)
                    setTimeout(() => {
                        this.$parent.$refs.payment.error = true
                        this.$parent.$refs.payment.errorMessage = this.translation('order_type_disabled_items', 'Some item/s added on your cart is not available for _ORDER_TYPE_').replace('_ORDER_TYPE_', data)
                        this.$parent.$refs.payment.okTitle = this.translation('okay')
                    }, 100)
                }
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        ...mapMutations('cart', {
            removeItem: 'removeItem',
            increaseQuantity: 'increaseQuantity',
            decreaseQuantity: 'decreaseQuantity'
        }),
        remove(index) {
            this.removeItem(index)
            this.$emit('changeqty')
        },
        increase(index) {
            this.increaseQuantity(index)
            this.$emit('changeqty')
        },
        decrease(index) {
            this.decreaseQuantity(index)
            this.$emit('changeqty')
        },
        removeDisabledItems(type) {
            if(type === 'deliver') {
                this.getItems.map((item, index) => {
                    if(item.attributes['disable-for-delivery'] === 1) {
                        this.removeItem(index)
                    }
                 })
            } else {
                this.getItems.map((item, index) => {
                    console.log(item.attributes['disable-for-pickup'])
                    if(item.attributes['disable-for-pickup'] === 1) {
                        this.removeItem(index)
                    }
                 })
            }
        },
        getAllDisabledItems(type) {
            if(type === 'deliver') {
                return this.getItems.filter(item => {
                    return item.attributes['disable-for-delivery'] === 1
                 })
            } else {
                return this.getItems.filter(item => {
                    console.log(item.attributes)
                    return item.attributes['disable-for-pickup'] === 1
                 })
            }
        },
        getName(name) {
            if(typeof name === 'object' && name) {
                return name[this.locales[this.getLocale.toLowerCase()]] || name['en-us']
            }
            return name
        },
        filterDisabledItems()
        {
            this.getItems.map((location, index) => {
                if(this.getLastLocation.hasOwnProperty('id')) {
                    if(location.attributes['excluded-locations'].includes(Number(this.getLastLocation.id))) {
                        this.removeItem(index)
                    }
                }
            })
        },
        modifiersText(item)
        {
            let modifierText = item.modifiers.length ? item.modifiers.filter(modifier => { return modifier.hasOwnProperty('attributes') }).map(modifier => { return this.getName(modifier.attributes.name) }) : []
            console.log(modifierText)
            return [
                ...modifierText,
                ...this.getComboItems(item)
            ]
        },
        getIngredients(item)
        {
            if(item.hasOwnProperty('removed'))
            {
                return item.removed.map(ingredient => { return this.getName(ingredient.name) })
            }
            return []
        },
        getComboItems(item)
        {
            let combined = []
            if (item.hasOwnProperty("combo-items")) {
                let partial = []
                item["combo-items"].map(modifier => {
                    return modifier.modifiers.map(modifier => {
                        partial.push(modifier.name);
                    });
                });

                partial = [...partial, ...item["combo-items"].map(modifier => {
                    return modifier.name;
                })]

                combined = [...combined, ...partial]
            }

            return combined

        }
    }
}
</script>
