<template>
	<section id="menu" class="px-3">
		<b-row v-show="false">
			<b-col md="8" v-if="!$parent.isMoble">
				<h5 class="title">{{translation('what_would_you_like_to_eat')}}</h5>
			</b-col>
			<b-col md="4" v-if="!$parent.isMoble" class="next-prev">
				<button :style="btnStyle" class="btn mr-3" @click="prev" ref="prev">
					<i class="fa fa-angle-left" aria-hidden="true"></i>
				</button>
				<button :style="btnStyle" class="btn"  @click="next" ref="next">
					<i class="fa fa-angle-right" aria-hidden="true"></i>
				</button>
			</b-col>
		</b-row>
		<b-row class="mt-4">
			<b-col md="12">
				<nav class="slider-wrapper" :style="{'direction': getLocale !== 'en' ? 'rtl' : 'inherit'}">
					<Card :category="category" :index="index" v-for="(category, index) in categories.filter(category => { return category.attributes.category.enabled })" :key="index"></Card>
				</nav>
			</b-col>
		</b-row>
	</section>
</template>

<script>
import { mapGetters } from 'vuex'
import { TranslationMixin } from "@/mixins";
import carousel from 'vue-owl-carousel'
import Card from './Card'
export default {
	name: "Menu",
	props: ['categories'],
	data()
	{
		return {
			itemNum: screen.width > 768 ? 4 : 0,
			activeCategory: null
		}
	},
	mixins: [TranslationMixin],
	components: { carousel, Card },
	computed: {
		...mapGetters({
			getLocale: 'app/getLocale'
		}),
		btnStyle()
		{
			return {
				color: this.$root.mainColor,
				background: this.$root.secondaryColor
			}
		},
	},
	watch: {
		categories: function(data)
		{
			console.log(data)
		}
	},
	methods: {
		next(){
			document.querySelector('.owl-next').click()
        },
        prev()
        {
			document.querySelector('.owl-prev').click()
        }
	}
}
</script>
