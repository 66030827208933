<template>
    <div class="container register-page">
        <div class="row">
            <div class="col-md-8"></div>
            <div class="col-md-4 pb-4">
                <form class="register-form" @submit.prevent>
                    <div class="title">
                        <h3 class="font-weight-bold">{{ translation('sign_up_now') }}</h3>
                    </div>

                    <div class="form-group">
                        <b-form-input
                            id="input-1"
                            type="text"
                            name="input-1"
                            :placeholder="translation('enter_first_name')"
                            v-model="$v.register.firstName.$model"
                            :state="$v.register.firstName.$dirty ? !$v.register.firstName.$error : null"
                            aria-describedby="live-feedback-1"
                        ></b-form-input>

                        <b-form-invalid-feedback id="live-feedback-1">
                            <span v-html="iconInfo"></span> This is a required field.
                        </b-form-invalid-feedback>
                    </div>

                    <div class="form-group">
                        <b-form-input
                            id="input-6"
                            type="text"
                            name="input-6"
                            :placeholder="translation('enter_last_name')"
                            v-model="$v.register.lastName.$model"
                            :state="$v.register.lastName.$dirty ? !$v.register.lastName.$error : null"
                            aria-describedby="live-feedback-6"
                        ></b-form-input>

                        <b-form-invalid-feedback id="live-feedback-6">
                            <span v-html="iconInfo"></span> This is a required field.
                        </b-form-invalid-feedback>
                    </div>

                    <div class="form-group">
                        <b-form-input
                            id="input-2"
                            type="text"
                            name="input-2"
                            :placeholder="translation('enter_mobile')"
                            v-model="$v.register.phoneNumber.$model"
                            :state="$v.register.phoneNumber.$dirty ? !$v.register.phoneNumber.$error : null"
                            aria-describedby="live-feedback-2"
                        ></b-form-input>

                        <b-form-invalid-feedback id="live-feedback-2">
                            <span v-html="iconInfo"></span> This is a required field with minimum length of 6.
                        </b-form-invalid-feedback>
                    </div>

                    <div class="form-group">
                        <b-form-input
                            id="input-3"
                            type="email"
                            name="input-3"
                            :placeholder="translation('enter_email')"
                            v-model="$v.register.email.$model"
                            :state="$v.register.email.$dirty ? !$v.register.email.$error : null"
                            aria-describedby="live-feedback-3"
                        ></b-form-input>

                        <b-form-invalid-feedback id="live-feedback-3">
                            <span v-html="iconInfo"></span> This is a required field and should be a valid email address.
                        </b-form-invalid-feedback>
                    </div>


                    <div class="form-group">
                        <b-form-input
                            id="input-4"
                            type="password"
                            name="input-4"
                            :placeholder="translation('enter_new_password')"
                            v-model="$v.register.password.$model"
                            :state="$v.register.password.$dirty ? !$v.register.password.$error : null"
                            aria-describedby="live-feedback-4"
                        ></b-form-input>

                        <b-form-invalid-feedback id="live-feedback-4">
                            <span v-html="iconInfo"></span> This is a required field and minimum length is 8.
                        </b-form-invalid-feedback>
                    </div>

                    <div class="form-group">
                        <b-form-input
                            id="input-5"
                            type="password"
                            name="input-5"
                            :placeholder="translation('confirm_new_password')"
                            v-model="$v.register.repeatPassword.$model"
                            :state="$v.register.repeatPassword.$dirty ? !$v.register.repeatPassword.$error : null"
                            aria-describedby="live-feedback-5"
                        ></b-form-input>

                        <b-form-invalid-feedback id="live-feedback-5">
                            <span v-html="iconInfo"></span> Password and repeat password does not match.
                        </b-form-invalid-feedback>
                    </div>

                    <div class="form-group">
                        <button
                            ref="registerBtn"
                            class="btn btn-primary form-control"
                            @click="registerUser($event)"
                            :style="btnBackground"
                        >{{ translation('sign_up') }}</button>
                    </div>
                </form>
                <div class="social-media-logins text-center">
                    <hr />
                    <small class="or">Or</small>
                    <small v-show="false" class="signin-text">Sign in using social account</small>
                    <ul v-show="false">
                        <li>
                            <span>
                                <i class="fa fa-facebook fa-2x" aria-hidden="true"></i>
                            </span>
                            <a href="javascript:;">Facebook</a>
                        </li>
                        <li>
                            <span>
                                <i class="fa fa-twitter fa-2x" aria-hidden="true"></i>
                            </span>
                            <a href="javascript:;">Twitter</a>
                        </li>
                        <li>
                            <span>
                                <i class="fa fa-google fa-2x" aria-hidden="true"></i>
                            </span>
                            <a href="javascript:;">Google</a>
                        </li>
                    </ul>
                    <router-link :to="{ name: 'login' }">
                        <div class="dont-have-account text-dark">
                            {{ translation('already_have_account') }}
                            <span class="text-primary text-sign-up">Sign In</span>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { eventHub } from '../../assets/plugins/events'
import { TranslationMixin, HelperMixin } from "@/mixins";
import {
    required,
    minLength,
    numeric,
    email,
    sameAs,
    helpers
} from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';
const alpha = helpers.regex("alpha", /^(?=.*[a-zA-Z])(?=.*\d).+$/);
export default {
    data() {
        return {
            customer: this.$root.customerService,
            register: {
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
                password: "",
                repeatPassword: ""
            },
            iconInfo: '<i class="fa fa-info-circle" aria-hidden="true"></i>'
        };
    },
    mixins: [ TranslationMixin, HelperMixin ],
    validations: {
        register: {
            firstName: {
                required,
                minLength: minLength(2)
            },
            lastName: {
                required,
                minLength: minLength(2)
            },
            email: {
                email
            },
            phoneNumber: {
                required,
                minLength: minLength(10)
            },
            password: {
                required,
                minLength: minLength(8),
                alpha
            },
            repeatPassword: {
                sameAsPassword: sameAs("password")
            }
        }
    },
    computed: {
        ...mapGetters({
            getSettings: 'app/getSettings'
        }),
        btnBackground()
        {
            return {
                background: this.$root.mainColor,
                backgroundColor: this.$root.mainColor,
                border: '1px solid ' + this.$root.mainColor
            }
        },
        isLogged() {
            return this.$store.getters["account/isLoggedIn"];
        },
        user() {
            return this.$store.getters["account/getUser"];
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
        // access to component instance via `vm`
        if (vm.$store.getters['concept/getConcept'].attributes['menu-only']) {
            next('/');
        } else {
            next();
        }
        });
    },
    mounted() {
        this.$parent.$data.sidebarOpen = true;
    },
    methods: {
        setGender(num) {
            this.register.gender = num;
        },
        registerUser(e) {
            this.$v.$touch();

            if (this.$v.$anyError) {
                return console.log("Incomplete or invalid fields");
            }

            let defaultText = e.target.innerHTML;
            e.target.innerHTML = '<i class="fa fa-circle-o-notch fa-spin"></i> Waiting...';
            e.target.disabled = true;

            const data = {
                "first-name": this.register.firstName,
                "last-name": this.register.lastName,
                username: this.register.phoneNumber,
                email: this.register.email,
                password: this.register.repeatPassword,
                mobile: this.register.phoneNumber,
                "account-type": "full"
            };

            this.customer
                .store(data)
                .then(response => {
                    e.target.innerHTML = defaultText;
                    e.target.disabled = false;
                    this.$root.$children[0].$refs.modalOtp.disabled = false
                    eventHub.$emit("main-otp", response.data.data);
                })
                .catch(err => {
                    this.$bvModal
                        .msgBoxOk(err.response.data.error[0].detail, {
                            title: "Failed!",
                            size: "sm",
                            okVariant: "danger",
                            footerClass: "p-2",
                            hideHeaderClose: false,
                            centered: true
                        })
                        .then(value => {
                            e.target.innerHTML = defaultText;
                            e.target.disabled = false;
                        });
                });
        }
    }
};
</script>
