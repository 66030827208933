export default {
    setReady(state: { ready: any; }, data: any){
        state.ready = data;
    },

    setBranches(state: { branches: any; }, data: any){
        state.branches = data
    },

    setLocale(state: { locale: any; }, data: any){
        state.locale = data
    },

    setCategories(state: { categories: any; }, data: any) {
        state.categories = data
    },

    setKioskOrder(state: { kioskOrder: any; }, data: any) {
        state.kioskOrder = data
    },

    setKioskVerifyResponse(state: { kioskVerifyResponse: any; }, data: any) {
        state.kioskVerifyResponse =data
    },

    setModifiers(state: { modifiers: any; }, data: any) {
        state.modifiers = data
    },

    setIngredients(state: { ingredients: any; }, data: any) {
        state.ingredients = data
    },

    setAllergens(state: { allergens: any; }, data: any) {
        state.allergens = data || [];
    },

    disableForceLocation(state: { settings: { attributes: { [x: string]: any; }; }; }, data: any)
    {
        if(state.settings.hasOwnProperty('attributes'))
        {
            state.settings.attributes['force-location'] = data
        }
    },

    setViewedItem(state: { viewedItem: any; }, data: any){
        state.viewedItem = data
    },

    setViewedItemModifier(state: { viewedItem: { modifiers: { [x: string]: any; }; }; }, data: { id: string | number; }){
        state.viewedItem.modifiers[data.id] = data
    },

    removeViewedItemModifier(state: { viewedItem: { modifiers: any[]; }; }, index: any) {
        state.viewedItem.modifiers.splice(index, 1);
    },

    setAddedToFavorites(state: { addedToFavorites: any; }, data: any)
    {
        state.addedToFavorites = data
    },

    setAppSettings(state: { settings: any; }, data: any)
    {
        state.settings = data
    },

    setTapOrderID(state: { [x: string]: any; }, data: any)
    {
        state['tap-order-id'] = data
    },

    setTapOrderAmount(state: { [x: string]: any; }, data: any)
    {
        state['tap-order-amount'] = data
    },

    setTapPaymentResponse(state: { [x: string]: any; }, data: any)
    {
        state['tap-payment-response'] = data
    },

    storeTapOrderData(state: { [x: string]: any; }, data: any)
    {
        state['tap-payment-order-data'] = data
    },

    setTapPaymentStatus(state: { [x: string]: any; }, data: any)
    {
        state['tap-payment-status'] = data
    },

    setTapCredentials(state: { [x: string]: any; }, data: any)
    {
        state['tap-payment-public-credentials'] = data
    },
    setCheckoutCredentials(state: { [x: string]: any; }, data: any)
    {
        state['checkout-payment-public-credentials'] = data
    },
    setDisabledCategories(state: { disabledCategories: any[]; }, data: any) {
        if(!state.disabledCategories.includes(data))
        {
            state.disabledCategories.push(Number(data))
        }
    },

    removeDisabledCategory(state: { disabledCategories: any[]; }, data: any) {
        state.disabledCategories.map((category, index) => {
            if(category === data) {
                state.disabledCategories.splice(index, 1)
            }
        })
    },

    setPaymentType(state: { paymentType: any; }, data: any) {
        state.paymentType = data
    },

    setToken(state: { token: any; }, data: any) {
        state.token = data
    }
}
