<template>
    <b-modal
        id="modalGuestPhoneNumber"
        centered
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        :hide-footer="showHeaderFooter"
        :hide-header="showHeaderFooter"
        ok-only
    >
        <button @click="close" class="close" style="position: absolute;right: 16px;top: 16px;">
          <img style="width: 20px" src="/images/illustrations/x button for pages@3x.png" alt="">
<!--            <i class="fa fa-times" aria-hidden="true" :class="{ 'default-times': getLocale === 'ar' }"></i>-->
        </button>
        <div class="row mt-4 guest-form">
            <div class="col-md-12" v-if="!status">
                <h5
                    class="font-weight-bold mb-3 mt-3"
                    :style="h5Style"
                >{{translation('verify_your_phone_number_to_proceed', 'Verify your phone number to proceed.')}}</h5>

                <div class="form-group">
                    <b-form-input
                        type="text"
                        class="form-control"
                        :placeholder="translation('first_name', 'First name')"
                        v-model="$v.firstName.$model"
                        :state="$v.firstName.$dirty ? !$v.firstName.$error : null"
                        aria-describedby="input-1-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-1-live-feedback">
                        {{translation('phone_name_required_valid','This is a required field and must be at least 2 characters.')}}
                    </b-form-invalid-feedback>
                </div>
                <div class="form-group">
                    <base-input
                        class="phone-input"
                        ref="base-input"
                        :maxlength="13"
                        v-model="phone"
                        :message="translation('phone_number_required_valid', 'This is a required field and must be a valid phone number.')"
                    />
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6">
                            <b-button
                                :style="btnStyle"
                                type="submit"
                                @click="onSubmit($event)"
                                class="form-control"
                            >{{translation('verify_number', 'Verify number')}}</b-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12" v-else-if="status === 'success'">
                <h5
                    class="font-weight-bold mb-3 mt-3 text-center"
                    :style="h5Style"
                >{{translation('otp_has_been_sent', 'OTP has been sent to your mobile number')}}
                <span class="text-name"><input v-model="phone" style="border:none;"/></span>

                </h5>
                <div class="form-group">
                    <input inputmode="numeric" pattern="[0-9]*" type="text" class="form-control" :placeholder="translation('enter_otp', 'Enter OTP')" v-model="otp" @keypress="isNumber($event)"/>
                    <div class="row link-holder">
                        <div class="r-otp">
                            <p @click="resendCode($event)" style="margin-top: 8px; font-size: 12px; text-decoration: underline; padding-left: 2px; cursor: pointer;">
                                <span v-if="isSendingOtp">
                                    <i class="fa fa-circle-o-notch fa-spin"></i>
                                </span>
                                <span v-if="!enableResend">({{showCounter}})</span>
                                {{translation('resend_otp', 'Resend OTP')}}
                            </p>
                        </div>
                        <div class="change-pn">
                            <p @click="ChangePhone($event)" style="margin-bottom: 0px; font-size: 12px; text-decoration: underline; padding-left: 2px; cursor: pointer;">{{translation('change_phone_number', 'Change phone number')}}</p>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12">
                            <b-button
                                :style="btnStyle"
                                type="submit"
                                @click="login($event)"
                                class="form-control"
                            >{{translation('submit')}}</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>
<style lang="scss" scoped>
    input[inputmode="numeric"] {
        direction: ltr !important;
    }
    body {
        .phone-input {
            input {
                &.form-control {
                    direction: ltr !important;
                }
            }

        }
    }
    .form-group {
        .phone-input input {
            direction: ltr !important;
            text-align: left !important;
        }
    }
    body.rtl {
        .form-group {
            .phone-input input {
                direction: ltr !important;
                text-align: left !important;
            }
        }
        .col-md-12 {
            .text-center {
                direction: ltr !important;
                text-align: center !important;
                .text-name {
                    margin-left: 5.5rem;
                }
            }
        }

    }
</style>
<script>
import { Customer, Concept } from "@/services/SOLO";
import { Auth } from "@/services/core";
import { TranslationMixin } from "@/mixins";
import { mapGetters, mapMutations } from 'vuex';
import { eventHub } from '@/assets/plugins/events'
import {required, minLength} from 'vuelidate/lib/validators'
export default {
    data() {
        return {
            showHeaderFooter: true,
            updateMobile: true,
            code: "",
            customerResponse: {},
            isValidCode: false,
            errorText: "",
            invalidMobile: false,
            mobileErrorText: "",
            disabled: true,
            data: {},
            customer: this.$root.customerService,
            redirectAfterLogin: false,
            guestPassword: "P@ssguest123",
            firstName: '',
            phone: '',
            status: null,
            otp: "",
            counter: 60,
            enableResend: false,
            isSendingOtp: false,
            auth: this.$root.AuthService,
        };
    },
    watch: {
      getConcept(concept) {
        if (this.getConcept.hasOwnProperty('attributes')) {
          this.setupDialingCode();
        }
      },
    },
    mixins: [TranslationMixin],
    validations: {
        firstName: {
            required,
            minLength: minLength(2)
        }
    },
    computed: {
        showCounter() {
            if(!this.enableResend) {
                return this.translateNumber(this.counter)
            }
        },
        isLoggedIn() {
            return this.$store.getters["account/isLoggedIn"];
        },
        user() {
            return this.$store.getters["account/getUser"];
        },
        ...mapGetters({
            getSettings: 'app/getSettings',
            getConcept: 'concept/getConcept',
            getCart: 'cart/getCart',
            getUser: 'account/getUser',
            getLastLocation: 'cart/getLastLocation',
          getLocale: 'app/getLocale',
        }),
        h5Style()
        {
            return {
                'color': this.$root.mainColor,
                'font-size': '16px',
                'line-height': '1.5'
            }
        },
        btnStyle()
        {
            return {
                'background': this.$root.mainColor,
                'font-size': '12px',
                'color': '#fff'
            }
        },
        dialingCode() {
          return (this.getConcept
              && this.getConcept.attributes
              && this.getConcept.attributes['dialing-code']) || '966';
        },
    },
    mounted()
    {
        this.setupDialingCode();
        this.startCounter()
    },
    methods: {
        ...mapMutations({
            setUserAddresses: "account/setUserAddresses",
            setTemporaryDelivery: "cart/setTemporaryDelivery",
            setQueue: "cart/setQueue",
            setOrderType: "cart/setOrderType",
            setLocation: "cart/setLocation",
            setTapCredentials: 'app/setTapCredentials'
        }),
        setupDialingCode() {
          this.phone = `+${this.dialingCode}`;
        },
        startCounter() {
            setInterval(() => {
                if (this.counter) {
                    this.counter--
                } else {
                    this.enableResend = true
                }
            }, 1000)
        },
        show() {
            this.$bvModal.show("modalGuestPhoneNumber");
        },
        ChangePhone(e){
            this.status = null;
            this.setupDialingCode();
            this.otp = null;
        },
        close() {
            this.firstName = '';
            this.status = null;
            this.setupDialingCode();
            this.otp = null;
            this.$bvModal.hide("modalGuestPhoneNumber");
        },
         displayError(error) {
            let errMsg = error.response.data.error.map(error => { return error.detail; }).join(", ");
            this.$toasted.global.error({
                message: errMsg
            });
        },
        onSubmit(e) {
            let defaultText = e.target.innerHTML
            e.target.innerHTML = '<i class="fa fa-circle-o-notch fa-spin"></i> ' + this.translation('loading_please_wait', 'Loading. Please wait...')
            e.target.disabled = true
            this.$v.$touch();
            if(this.$v.$anyError)
            {
                e.target.innerHTML = defaultText
                e.target.disabled = false
                return
            }
            const data = {
                "first-name": this.firstName,
                username: Number(this.phone),
                mobile: this.phone,
                status: "unverified",
                "last-name": "",
                "account-type": "guest"
            };

            this.$refs["base-input"].validateInput(this.phone);

            if (this.$refs["base-input"].hasError()) {
                e.target.innerHTML = defaultText
                e.target.disabled = false

                return;
            }

            Customer.store(data).then(response => {
                console.log(response)
                this.status = "success";
                this.data = response.data.data;
                e.target.innerHTML = defaultText
                e.target.disabled = false
            }).catch((error) => {
                e.target.innerHTML = defaultText
                e.target.disabled = false
                let errMsg = error.response.data.error.map(error => { return error.detail; }).join(", ");
                this.$toasted.global.error({
                    message: errMsg
                });
            });
        },
        saveAddress()
        {
            if(this.getCart.temporaryDelivery.hasOwnProperty('line1'))
            {
                Customer.createAddress(this.getUser.id, this.getCart.temporaryDelivery)
                .then(response => {
                    this.setTemporaryDelivery(null)
                    this.setUserAddresses(response.data.data);
                    this.setDefault(response.data.data[0])
                })
                .catch(err => {
                   console.log(err.response)
                });
            }
        },
        setDefault(address) {
            this.setOrderType('deliver');
            this.setLocation(address);
            this.setQueue(false)
        },
        login(e) {
            let defaultText = e.target.innerHTML
            e.target.innerHTML = '<i class="fa fa-circle-o-notch fa-spin"></i> ' + this.translation('loading_please_wait', 'Loading. Please wait...')
            e.target.disabled = true
            let params = {
                username: Number(this.data.attributes.mobile),
                password: this.otp
            };
            Customer.authenticateOTP(params).then(({ data }) => {
                this.$store.commit("account/setUser", data.data);
                this.getTapPublicKey()
                this.saveAddress()
                this.close();
            }).catch((error) => {
                if (error.response !== undefined) {
                    e.target.innerHTML = defaultText
                    e.target.disabled = false
                    console.log(error.response.data.error)
                    let errMsg = error.response.data.error.map(error => {
                        return error.code === 1001 ? this.translation('please_enter_valid_pincode', 'Please enter the correct pin code') : error.detail;
                    }).join(", ");
                    this.$toasted.global.error({
                        message: errMsg
                    });
                } else {
                    this.close();
                }
            });
        },
        getTapPublicKey()
        {
            Concept.getTapPublicKey().then(response => {
                this.setTapCredentials(response.data.data)
            })
        },
        resendCode(e) {
            if(!this.enableResend) {
                return this.$toasted.global.error({
                    message:  `Sorry resending OTP is disabled for ${this.counter}`
                });
            }

            if(this.isSendingOtp) {
                return this.$toasted.global.error({
                    message:  `Sorry OTP is still sending.`
                });
            }
            e.preventDefault();
            this.isSendingOtp = true

            Customer.resendCode({
                id: this.data.id,
                mobile: this.data.attributes.mobile
            }).then(response => {
                this.isSendingOtp = false
                this.counter = 60
                this.enableResend = false
                this.startCounter()
                this.$toasted.global.info({
                    message: "We have sent you a new OTP code."
                });
            }).catch((error) => {
                this.isSendingOtp = false
                let errMsg = error.response.data.error.map(error => { return error.detail; }).join(", ");
                this.$toasted.global.error({
                    message: errMsg
                });
            });
        },
        isNumber: function (evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            }
        },
    }
};
</script>
<style lang="scss">
#modalGuestPhoneNumber___BV_modal_outer_ {
    z-index: 9999 !important;
    #modalGuestPhoneNumber___BV_modal_content_ {
        width: 320px;
        margin: 0 auto;
    }
}
</style>
