<template>
    <b-row class="mt-4">
        <b-col md="11" class="offset-md-1 size-modifier" v-show="numOfSizes > 1">
            <div class="modifier-wrapper text-center">
                <button class="btn btn-sm" v-show="false" :style="isActiveKey === index ? ActiveBtnSizeStyle : RegularBtnSizeStyle" :class="{ 'active' : isActiveKey === index }" v-for="(size, index) in sizeModifier[0].relationships.modifiers.data" :key="index" @click="setSize(size.id,index)">
                    {{filterModifier(size.id)[0].attributes.name}}
                </button>
                <b-button-group size="sm">
                    <b-button
                        variant="white"
                        :style="isActiveKey === index ? ActiveBtnSizeStyle : RegularBtnSizeStyle" :class="{ 'active' : isActiveKey === index }"
                        v-for="(size, index) in sizeModifier[0].relationships.modifiers.data" :key="index" @click="setSize(size.id,index, size)"
                    >
                        {{filterModifier(size.id)[0].attributes.name}}
                    </b-button>
                </b-button-group>
            </div>
        </b-col>
    </b-row>
</template>
<script>
export default {
    props: ['sizeModifier'],
    data()
    {
        return {
            isActiveKey: 0,
            ActiveBtnSizeStyle: {
                background: this.$root.mainColor
            },
            RegularBtnSizeStyle: {
                background: this.$root.secondaryColor,
                color: '#fff'
            },
        }
    },
    mounted()
    {
        this.setDefaultSize(this.sizeModifier);
    },
    watch: {
        sizeModifier(data)
        {
            if(data.length)
            {
                this.setDefaultSize(data)
            }
        }
    },
    mounted()
    {
        this.setDefaultSize(this.sizeModifier);
    },
    computed: {
        numOfSizes()
        {
            if(this.sizeModifier.hasOwnProperty(0))
            {
                return this.sizeModifier[0].relationships.modifiers.data.length
            }
            return 0
        }
    },
    methods: {
        setDefaultSize(sizeModifier)
        {
            this.setSize(sizeModifier[0].relationships.modifiers.data[this.isActiveKey].id)
        },
        filterModifier(id)
        {

            return this.$parent.modifiers.included.filter(data => {
                return data.id === id
            })
        },
        setSize(id, index = 0, size = null)
        {
            this.isActiveKey = index
            this.$parent.$parent.item.attributes.price = this.filterModifier(id)[0].attributes.price

            if (this.filterModifier(id).length > 0) {
                const modifier = this.filterModifier(id)[0];
                modifier.quantity = 1
                this.$parent.$parent.$refs.Addtocart.sizeModifier = modifier;
                this.$parent.$parent.$refs.Addtocart.modifierPrice = 0
                this.$emit('size-modifier', id)
            }
        }
    }
}
</script>
