let helperMixin = {
    methods: {
        strtoslug(text: string, id: string) {
            return text && text.toLowerCase().split(' ').join('-') + '-' + id;
        },
        convertDecimal(num: number, decimalPlaces: number, locale: string = 'en') {
            if(locale === 'en') {
                return num.toFixed(decimalPlaces)
            }
            return num
        }
    }
};

export default helperMixin
