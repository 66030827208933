<template>
	<b-col md="12" id="homepage" :class="{ 'pb-3' : getItems.length < 1, 'pb-225' : isMoble && currentOrders.length > 0, 'pb-130' :  isMoble && currentOrders.length > 0 && getItems.length < 1 }">
		<template v-if="$root.appReady">
			<carousel class="carousel-wrapper" ref="carousel" :autoplay="true" :nav="false" :dots="false" :items="1" v-if="slides.length">
				<img  v-for="(slide, i) in slides.filter(slide => { return typeof slide.attributes['image-uri'] == 'string' })" :key='i' :src="getImageSrc(slide.attributes['image-uri'])" @click="linkTo(slide)"/>
			</carousel>
			<Header class="pl-2 pr-2"></Header>
			<Jumbotron v-show="false"></Jumbotron>
			<ChangeLocation class="pl-2 pr-2" :home="true" :slides="slides"></ChangeLocation>
			<Coupon class="pl-2 pr-2 clearfix" :coupons="coupons" v-if="coupons && coupons.length"></Coupon>
			<Menu class="pl-2 pr-2 clearfix" v-if="categories.length" ref="menu" :categories="categories"></Menu>
			<Categories class="pl-2 pr-2" :items="categoryItems" v-if="categoryItems.length" ref="items"></Categories>
		</template>
	</b-col>
</template>
<style lang="scss" scoped>
	.carousel-wrapper{
		height: auto;
	}
	body {
		direction: ltr;
		&.rtl {
			.owl-carousel .owl-stage-outer {
				direction: ltr !important;
			}
		}
		.owl-carousel {
			-ms-touch-action: pan-y;
			touch-action: pan-y;
		}
	}
</style>
<script>
import Header from './components/Header'
import Jumbotron from './components/Jumbotron'
import Menu from './components/Menu'
import Coupon from './components/Coupon'
import ChangeLocation from '@/layouts/components/cart-components/ChangeLocation'
import Categories from './pages/Categories'
import { UserMixin, TranslationMixin, HelperMixin } from '../../mixins'
import { Menu as Menus, Category, Item, Slider, Cms } from '@/services/SOLO'
import { mapGetters, mapMutations } from 'vuex'
import { eventHub } from '@/assets/plugins/events';
import carousel from 'vue-owl-carousel';
import { Customer } from '@/services/SOLO'
import { Order } from "../../services/SOLO";
import moment from 'moment';

export default {
	name: "Home",
	data() {
		return {
			category: {},
			categories: [],
			categoryItems: [],
			activeIndex: null,
			isLoading: false,
			sliders: [],
			slides: [],
        	timer: null,
			coupons: [],
			currentOrders: [],
			selectedCatId: null,
		}
	},
	mixins: [UserMixin, TranslationMixin, HelperMixin],
	components: { Header, Jumbotron, Menu, Categories, ChangeLocation, carousel, Coupon },
	computed: {
		...mapGetters({
			getConcept: 'concept/getConcept',
			getSettings: 'app/getSettings',
			getCategoryItems: 'concept/getCategoryItems',
			getInactiveCategories: 'concept/getInactiveCategories',
			getItems: "cart/getItems",
			getUser: 'account/getUser',
			getLocation: 'cart/getLocation',
			isLoggedIn: "account/isLoggedIn",
			getLocale: 'app/getLocale',
			couponResult: 'cart/couponResult',
			couponCode: 'cart/couponCode',
		}),
		isMoble()
		{
			return screen.width < 768 ? true : false
		},
		currentImage: function() {
      	return this.slides[Math.abs(this.currentNumber) % this.slides.length];
      }

	},
	watch: {
		couponResult (newValue) {
			this.coupons = [];
			if (newValue && newValue.hasOwnProperty('discount-value')) {
				this.initCoupon(newValue);
			}
		},
		getInactiveCategories (newValue) {
			if (newValue) {
				const idx = 0;
				if (!!this.categories.length) {
					this.categories = this.categories.filter(c => !this.getInactiveCategories.filter(i => i.id === c.id).length);
					this.getCatagoryItems(this.categories[idx], idx);
					this.$forceUpdate();
				}
			}
		},
	},
	created() {
	  	window.addEventListener('scroll', this.handleScroll);
	},
	mounted() {
		const { coupon } = this.$route.query;
		if (coupon) {
			Order.coupon(coupon)
				.then(({data}) => {
					this.coupons = [...data.data];
				})
		} else if (this.couponResult && this.couponResult.hasOwnProperty('discount-value')) {
			this.initCoupon(this.couponResult);
		}
		this.$root.cacheItems();
		this.$root.sidebarOpen = true;
		if(this.getSettings.hasOwnProperty('attributes') && this.getConcept.hasOwnProperty('attributes'))
		{
			this.getCategories();
			this.$root.appReady = true
			this.getSlider();
		}else
		{
			eventHub.$on('app-ready', ()=> {
				this.$root.appReady = true
				this.getCategories()
				this.getSlider()
			})
		}
		this.startRotation();
		if (this.isLoggedIn) {
    		this.getOrders();
			}
	},
	methods: {
		...mapMutations({
			setCategoryItems: 'concept/setCategoryItems',
			disableForceLocation: 'app/disableForceLocation',
			setCategories: 'app/setCategories',
			setModifiers: 'app/setModifiers',
			setIngredients: 'app/setIngredients',
      setAllergens: 'app/setAllergens',
		}),
		getImageSrc(url) {
			if(url.includes('https://cdn.getsolo.io/')) {
				return  `https://cdn.getsolo.io/cdn-cgi/image/width=600/${url.replace('https://cdn.getsolo.io/','')}`
			}
			return url
		},
		initCoupon(coupon) {
			this.coupons.push({
				attributes: {
					description: coupon['discount-type'] === 'percentage' ?
							coupon['discount-value'] + '%' :
							coupon['discount-value'],
				},
			});
		},
		startRotation: function() {
            this.timer = setInterval(this.next, 3000);
        },

        stopRotation: function() {
            clearTimeout(this.timer);
            this.timer = null;
        },

        next: function() {
            this.currentNumber += 1
        },
        prev: function() {
            this.currentNumber -= 1
		},

		getCategories()
		{
			Category.cdn(this.getSettings.attributes['menu-ref'])
				.then(response => {
					console.log('Category.cdn: ', response.data);
					this.categories = response.data.data.sort((a,b) => {
						return a.attributes.category['display-order'] - b.attributes.category['display-order']
					});

					this.setCategories(this.categories)
					this.setModifiers(response.data.included.modifiers)
					this.setIngredients(response.data.included.ingredients)
          this.setAllergens(response.data.included.allergens)

					if (localStorage.getItem('offsetTop')) {
						setTimeout(() => {
							window.scroll({ top: localStorage.getItem('offsetTop'), behavior: 'smooth' });
						}, 500);
					}
					if (this.getLocation) {
						this.categories = this.categories.filter(c => !this.getInactiveCategories.filter(i => i.id === c.id).length);
					}
				})
		},
		setProductImgDisplay(displayValue) {
			if (document.getElementsByClassName('product-img').length) {
				document.getElementsByClassName('product-img').forEach((e) => {
					e.style.display = displayValue;
				})
			}
		},
		getCatagoryItems(category, index)
		{
			this.category = category.attributes.category
			this.$refs.menu.activeCategory = this.category
			this.activeIndex = index
			localStorage.setItem('activeCatIndex', index);
			this.categoryItems = category.attributes.items.filter(item => { return item.enabled })
			console.log(category)
		},
		additionalItem(params, page, oldIndex, category) {
			console.log(page)
			Item.all({...params}, page).then(response => {
				response.data.data.map(item => {
					if(item.attributes.enabled) {
						this.categoryItems.push(item)
					}
				})

			}).catch(err => {
				this.activeIndex = oldIndex;
				this.isLoading = false;
			})
		},
		getSlider()
		{
			if (this.getConcept.attributes['slider-id'] != undefined || this.getConcept.attributes['slider-id'] != null) {
				Slider.getSlides(this.getConcept.attributes['slider-id']).then(r => {
					this.slides = r.data.data
					if(this.getLocale === 'ar')
					{
						this.sliders.reverse()
					}
				});
			}
		},
		getOrders() {
            Customer.getOrders(this.getUser.id).then(response => {
                this.orders = response.data.data;
                this.currentOrders = this.orders.filter((order, i) => {
					if(order.attributes['current-status'])
					{
						const orderDate = moment(order.attributes['created-at']).add(6, 'hours').format('YYYY-MM-DDTHH:mm:ss');

						const orderTime = new Date(this.UTC(orderDate)).getTime();
						const now		= new Date().getTime();

						return order.attributes['current-status'].type == "open" && (orderTime > now);
					}
					return false
				}).filter((order, i) => {
					return i <= 1
				})
            });
        },
        handleScroll() {
        	const element = document.getElementById("homepage");

  			if (element != null) {
  				var rec = element.getBoundingClientRect();
		   		localStorage.setItem('offsetTop', Math.abs(rec.top));
  			}
		},
		UTC(time)
		{
				return moment.utc(time,'YYYY-MM-DD h:mm:ss').locale(this.getLocale).local().format('YYYY-MM-DDTHH:mm:ss')
		},
		linkTo(slide) {
			console.log(slide)
				let attributes = slide.attributes;

				if (attributes["link-value"] != '') {
					if (attributes["link-type"] == "url") {
						location.href = attributes["link-value"];
					} else if (attributes["link-type"] == "category") {
						this.categories.filter((category, i) => {
							if (category.id == attributes["link-value"]) {
								this.getCatagoryItems(category, i);
							}
						});
					} else if (attributes["link-type"] == "item") {
						const params = {
							menuId: this.getConcept.attributes["default-menu-id"],
							itemId: attributes["link-value"]
						};

						Item.find(params, "category").then(response => {
							let item = response.data.data;
							let catId = response.data.data.relationships.category.data.id;
							Category.find(params.menuId, catId).then(
								response => {
										this.reRouteItem(item, response.data.data);
								}
							).catch((error) => {
								console.log(error.response.data);
							});
						}).catch((error) => {
							console.log(error.response.data);
						});
					} else if (attributes["link-type"] == "cms-page") {
							Cms.getPage(attributes["link-value"]).then(response => {
									location.href = response.data.data.attributes.url;
							}).catch((error) => {
								console.log(error.response.data);
							});
					}
				}
		},
		reRouteItem(item, cat) {
				this.$router.push({ name: 'item', params: { categoryId: this.strtoslug(cat.attributes.name, cat.id),id: this.strtoslug(item.attributes.name, item.id) } });
		},
	}
}
</script>
