<template>
    <div>
        <!-- <h5 class="font-weight-bold">{{translation('profile_caps', 'Profile')}}</h5> -->
        <h5 class="header">
            <span class="btn-back" @click="setCartComponent('Cart')"> 
                <i class="fa" :class="{ 'fa-long-arrow-left': getLocale === 'en', 'fa-long-arrow-right': getLocale === 'ar' }"></i> 
            </span>
            <span class="nav-title text-center">
                {{translation('options')}}
            </span>
        </h5>
        <b-card>
            <h5 class="name">{{getFirstName}}</h5>
            <p class="mobile">{{getMobile}}</p>
            <button :style="btnEditProfileStyle" class="btn btn-sm btn-edit-profile" @click="setProfileComponent('Profile')">
                <i class="fa fa-pencil" aria-hidden="true"></i>
                {{translation('edit_profile')}}
            </button>
        </b-card>

        <ul v-show="false">
            <li @click="setProfileComponent('PersonalInfo')">
                <div>
                    <img src="/images/icons/personal-info.png" alt />
                </div>
                {{translation('personal_info')}}
                <div class="pointer">
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                </div>
            </li>
            <li @click="setProfileComponent('OrderHistory')">
                <div>
                    <img src="/images/icons/order-history.png" alt />
                </div>
                {{translation('order_history')}}
                <div class="pointer">
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                </div>
            </li>
            <li @click="setProfileComponent('Settings')">
                <div>
                    <img src="/images/icons/settings.png" alt />
                </div>
                {{translation('settings')}}
                <div class="pointer">
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { TranslationMixin } from "@/mixins";
export default {
    data()
    {
        return {
            btnEditProfileStyle: {
                '--main-color': this.$root.mainColor                
            }
        }
    },
    mixins: [TranslationMixin],
    computed: {
        ...mapGetters({
            getFirstName: "account/getFirstName",
            getLastName: "account/getLastName",
            getMobile: "account/getMobile",
            getLocale: 'app/getLocale'
        })
    },
    mounted() {
        
    },
    methods: {
        setCartComponent(component) {
            this.$parent.$parent.$parent.current = component
        },
        setProfileComponent(component)
        {
            this.$parent.current = component
        }
    }
}
</script>
