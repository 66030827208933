<template>
    <div class="profile-content">
        <div class="main" :is="current"></div>
        <div class="main" is="OrderHistory"></div>
    </div>
</template>

<script>
import Navigator from './Navigator'
import PersonalInfo from './PersonalInfo'
import OrderHistory from './OrderHistory'
import Profile from '../profile/Profile'
import Settings from './Settings'
export default {
    data()
    {
        return {
            current: 'Navigator'
        }
    },
    components: { Navigator, PersonalInfo, OrderHistory, Profile, Settings },
};
</script>
