<template>
	<section class="header-wrapper">
		<section class="header-thumbnail">
			<b-row>
				<b-col md="8" offset-md="2" class="card-item-description">
					<b-card>
						<Divider></Divider>
						<Tags></Tags>
						<DeliveryDescription></DeliveryDescription>									
					</b-card>					
				</b-col>
			</b-row>			
		</section>
	</section>
</template>

<script>
import Divider from './Divider'
import Tags from './Tags'
import DeliveryDescription from './DeliveryDescription'
export default {
	name: "Header",
	components: { Divider, Tags, DeliveryDescription }
}
</script>