<template>
    <b-row>        
        <gmap-map v-if="$route.name === 'default-location'"
            ref="mapRef"
            :center="{lat: lat, lng: lng}"
            :zoom="zoom"
            style="width: 100%; height: 100%"
            :options="{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: false
            }"
        >
            <GmapMarker
                :key="index"
                v-for="(m, index) in branches"
                :position="m.position"
                :clickable="true"
                :icon="'/images/icons/blue-pin-small.png'"
                @click="markerClicked(m)"
                :z-index="1"
                class="info-window"
            >
                <gmap-info-window
                    v-if="shouldOpenInfoWindow(m.id)"                    
                    :options="infoWindowStyle"
                    fontFamily="Poppins"
                >
                    <small class="d-block info-window-title">Location</small>
                    <small class="font-weight-bold info-window-sub-title">{{ m.attributes.name }}</small>
                </gmap-info-window>
            </GmapMarker>
        </gmap-map>
        <gmap-map v-else
            ref="mapRef"
            :center="{lat: lat, lng: lng}"
            :zoom="zoom"
            style="width: 100%; height: 100%"
            :options="{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: false
            }"
        ></gmap-map>
    </b-row>
</template>

<script>
import moment from "moment";
import { gmapApi } from "vue2-google-maps";
import { Location } from "../../../services/SOLO";
import { mapGetters, mapMutations } from 'vuex';
import accountGetters from '../../../store/account/account.getters';
import { eventHub } from '@/assets/plugins/events'
export default {
    data() {
        return {
            lat: 24.774265,
            lng: 46.738586,
            zoom: 14,
            branches: [],
            selectedLocation: null,
            directionsService: null,
            directionsRenderer: null,
            infoWindowStyle: {
                minWidth: "150px"
            }
        };
    },
    computed: {
        google: gmapApi,
        ...mapGetters({
            getUser: 'account/getUser',
            isLoggedIn: 'account/isLoggedIn',
            getFirstName: 'account/getFirstName',
            getLastName: 'account/getLastName',
            getEmail: 'account/getEmail',
            getMobile: 'account/getMobile',
            getBranches: 'app/getBranches',
            getConcept: 'concept/getConcept',
            getSettings: 'app/getSettings',            
        })      
    },
    mounted() {                
        // if(this.getSettings.hasOwnProperty('attributes') && this.getConcept.hasOwnProperty('attributes'))
		// {			            
		// 	this.displayBranches(this.getBranches);
        //     this.initMap();            
        //     this.getLocation();
		// }else
		// {
		// 	eventHub.$on('app-ready', ()=> {	                	                
		// 		this.displayBranches(this.getBranches);
        //         this.initMap();            
        //         this.getLocation();
		// 	})
		// }    
        
        // eventHub.$on('change-lang', ()=> {
        //     this.getLocation();
        // })
    },
    methods: {
        ...mapMutations({            
            setBranches: 'app/setBranches'
        }),
        initMap() {
            let branches = this.branches;
            this.$refs.mapRef.$mapPromise.then(map => {
                let google = this.google;
                this.zoom = 13
                map.panTo({
                    lat: 24.774178,
                    lng: 46.710181                    
                });

                this.directionsService = new google.maps.DirectionsService();
                this.directionsRenderer = new google.maps.DirectionsRenderer();
                this.directionsRenderer.setMap(this.$refs.mapRef.$mapObject);
            });
        },
        getLocation(lat, lng) {
            console.log(lat, lng)
            Location.fetchNearestLocations(lat, lng)
                .then(response => {                      
                    this.setBranches(response.data.data)                                         
                    eventHub.$emit('store-list')
                    eventHub.$emit('set-branch', true) //ChangeLocation.vue
                })
                .catch(err => {
                    console.error(err);
                });
        },
        displayBranches(data) {            
            if (data.length) {
                this.lat = data[0].attributes.lat;
                this.lng = data[0].attributes.long;
                data.forEach(row => {
                    this.addMarker(row);
                });
            }
        },
        addMarker(branch) {
            this.branches.push(
                Object.assign(branch, {
                    position: {
                        lat: branch.attributes.lat,
                        lng: branch.attributes.long
                    },
                    opacity: 1,
                    draggable: true,
                    enabled: true,
                    clicked: 0,
                    rightClicked: 0,
                    dragended: 0,
                    ifw: false
                })
            );
        },
        centerMarker(branch)
        {
            this.$refs.mapRef.$mapPromise.then(map => {                
                this.zoom = 13
                map.panTo({
                    lat: branch.attributes.lat,
                    lng: branch.attributes.long                    
                });
            });
        },
        markerClicked(branch) {              
            this.selectedLocation = branch;     
            this.centerMarker(branch)                   
        },
        shouldOpenInfoWindow(branchId) {
            if (this.selectedLocation) {
                return branchId === this.selectedLocation.id;
            }

            return false;
        },
        displayBranchTime(branch) {
            let branchDay = branch.attributes["opening-hours"][moment().day()];
            return branchDay.open + " - " + branchDay.closed;
        },
        setDirections(attributes) {            
            let google = this.google;
            this.directionsRenderer.setMap(this.$refs.mapRef.$mapObject);
            let self = this;
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function(position) {
                    var start = new google.maps.LatLng(
                        position.coords.latitude,
                        position.coords.longitude
                    );
                    var end = new google.maps.LatLng(
                        attributes.lat,
                        attributes.long
                    );
                    let request = {
                        origin: start,
                        destination: end,
                        travelMode: "DRIVING"
                    };
                    self.directionsService.route(request, (result, status) => {
                        if (status == "OK") {
                            self.directionsRenderer.setDirections({
                                routes: []
                            });
                            self.directionsRenderer.setDirections(result);
                        } else {
                            self.$toasted.global.info({
                                message: "CAN'T LOCATE STREET DIRECTION"
                            });
                        }
                    });
                });
            } else {
                this.$toasted.global.info({
                    message: "Browser doesn't support Geolocation"
                });
            }
        }
    }
};
</script>