<template>
    <b-col md="12" id="location-page">
        <div class="toogle-back" @click="gotoMenu">
            <img src="/images/illustrations/x button for pages@3x.png" alt="">
        </div>
        <img class="concept-logo" :src="getConcept.attributes['logo-uri']" alt="">        
        <h3 class="font-weight-bold">{{translation('how_to_get_food')}}</h3>        
        <Tab></Tab>
        <router-view></router-view>        
    </b-col>
</template>
<style lang="scss" scoped>
    .concept-logo {
        display: block;
        margin: 0 auto;
        height: 50px;
        margin-top: -40px;
        margin-bottom: 20px;
    }
    html, body {
        height: 100%;
        direction: ltr;
    }
</style>
<script>
import Tab from "./components/Tab";
import { mapMutations, mapGetters } from 'vuex';
import { TranslationMixin } from '../../mixins'
import { eventHub } from '@/assets/plugins/events'
export default {
    data() {
        return {
            lat: 24.774265,
            lng: 46.738586,
            zoom: 14,
            types: {
                'deliver': 'delivery-location',
                'pickup': 'pickup-location',
                'eat-in': 'dinein-location',
                'to-go': 'to-go-location'
            },

        };
    },
    components: {
        Tab
    },
    mixins: [TranslationMixin],
    computed: {
        ...mapGetters({
            getCart: 'cart/getCart',
            isLoggedIn: 'account/isLoggedIn',
            getSettings: 'app/getSettings',
            getConcept: 'concept/getConcept'
        }),
        closeBtnStyle()
        {
            return {
                background: this.$root.mainColor,
                color: '#fff'
            }
        },
        allowedOrderTypes()
        {
            if(this.getSettings.hasOwnProperty('attributes'))
            {
                return this.getSettings.attributes
            }
            return {}
        }
    },    
    mounted()
    {        
        if(this.getSettings.hasOwnProperty('attributes') && this.getConcept.hasOwnProperty('attributes'))
        {           
            this.disableForceLocation(0)        
        }else
        {
            eventHub.$on('app-ready', ()=> {                        
                this.disableForceLocation(0)                
            })
        }
        if (!this.isAllowedType(this.$route.meta.type)) {
            this.$router.push({name: this.types[this.getConcept.attributes['allowed-order-types'].split(',')[0]]})
        }
    },     
    methods: {
        ...mapMutations({
            setOrderType: 'cart/setOrderType',
            disableForceLocation: 'app/disableForceLocation'
        }),
        isMobile()
        {
            return screen.width < 768 ? true : false
        },
        gotoMenu()
        {
            this.$router.push({ name: 'home' })
        },
        isAllowedType(type)
        {                      
            return this.getConcept.attributes['allowed-order-types'].split(',').includes(type)        
        }
    }
};
</script>