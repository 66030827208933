<template>
    <b-row>
        <b-col md="12">
            <location-box v-show="false" ref="searchBox"></location-box>
            <div class="locate-me">
                <button @click="myLocation">
                    <i class="material-icons" aria-hidden="true">&#xe55c;</i>
                </button>
            </div>
            <!-- @click="mapClicked" -->
            <gmap-map
                ref="mapRef"
                :center="{lat: lat, lng: lng}"
                :zoom="zoom"
                style="width: 100%; height: 300px;"
                :draggable="true"
                @drag="updateCoordinates"
                @dragstart="start($event)"
                @dragend="stop"
                :options="{
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    disableDefaultUi: false
                }"
                v-if="isAllowedType('deliver')"
            >
                <GmapMarker
                    v-if="myPosition.lat && myPosition.lng"
                    :position="myPosition"
                    :clickable="true"
                    :icon="'/images/illustrations/Delivery location pin.png'"
                >
                </GmapMarker>
            </gmap-map>
            <address-form :lat="myPosition.lat" :lng="myPosition.lng" ref="AddressForm" class="address-form"></address-form>
        </b-col>
    </b-row>
</template>
<style lang="scss" scoped>
    .locate-me {
        position: absolute;
        top: 190px;
        right: 25px;
        user-select: none;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
        border-radius: 2px;
        cursor: pointer;
        background-color: rgb(255, 255, 255);
        width: 40px;
        height: 40px;
        z-index: 100;
        button {
            background: none;
            display: block;
            border: 0px;
            margin: 0px;
            padding: 0px;
            position: relative;
            cursor: pointer;
            user-select: none;
            overflow: hidden;
            width: 40px;
            height: 40px;
            top: 0px;
            left: 0px;
            color: #666;
            &:hover {
                color: #000;
            }
            i {
                vertical-align: middle;
                font-size: 27px !important;
            }
        }
    }
    .btn-select {
        position: absolute;
        top: 80px;
        left: calc(50% - 106px);
        z-index: 99;
        padding: 10px 20px;
        border-radius: 30px;
        min-width: 212px;
        font-size: 13px;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>
<script>
import { gmapApi } from "vue2-google-maps";
import LocationBox from "./components/LocationBox";
import AddressForm from "./components/AddressForm";
import { mapMutations, mapGetters } from "vuex";
import { Customer, Location } from "../../../../services/SOLO";
import { TranslationMixin } from "../../../../mixins";
import { validationMixin } from "vuelidate";
import { eventHub } from '@/assets/plugins/events'
import {
    required,
    minLength,
    maxLength,
    between
} from "vuelidate/lib/validators";

export default {
    data() {
        return {
            lat: 24.774265,
            lng: 46.738586,
            zoom: 11,
            markers: [],
            myPosition: {
                lat: 24.774265,
                lng: 46.738586
            },
            address: {
                line1: "",
                label: null,
                telephone: "",
                instructions: ""
            },
            position: null,
            validLocation: false,
            infoWindowStyle: {
                minWidth: "150px"
            },
            drag: false,
            tempLocation: null
        };
    },
    validations: {
        address: {
            line1: {

            },
            instructions: {},
            label: {

            }
        }
    },
    mixins: [TranslationMixin],
    components: {
        LocationBox,
        AddressForm
    },
    computed: {
        google: gmapApi,
        ...mapGetters({
            getUser: "account/getUser",
            isLoggedIn: "account/isLoggedIn",
            getFirstName: "account/getFirstName",
            getLastName: "account/getLastName",
            getEmail: "account/getEmail",
            getMobile: "account/getMobile",
            getBranches: "app/getBranches",
            getLocation: "cart/getLocation",
            getUserAddresses: "account/getUserAddresses",
            getDefaultLocations: "cart/getDefaultLocations",
            getConcept: 'concept/getConcept',
            getSettings: 'app/getSettings',
            getLastLocation: 'cart/getLastLocation'
        }),
        btnSelectStyle() {
            return {
                display: "block",
                background: this.$root.mainColor,
                color: "#fff",
                fontSize: "12px",
                marginTop: "5px"
            };
        },
    },
    watch: {
        getLocation: function(data) {
            this.moveLocation(data);
        },
        drag(data)
        {
            if(!data)
            {
                this.updateCoordinates()
            }
        }
    },
    mounted() {
        this.setupDefaultCoordinates();
        if (this.isAllowedType('deliver')) {
            if(this.getSettings.hasOwnProperty('attributes') && this.getConcept.hasOwnProperty('attributes'))
            {
                this.initMap()
            }else
            {
                eventHub.$on('app-ready', ()=> {
                    this.initMap()
                })
            }
        }
    },
    methods: {
        ...mapMutations({
            setBranches: "app/setBranches",
            setUserAddresses: "account/setUserAddresses",
            setQueue: "cart/setQueue",
            setTemporaryDelivery: "cart/setTemporaryDelivery",
            setOrderType: "cart/setOrderType",
            setLocation: "cart/setLocation",
            setRedirectTo: 'cart/setRedirectTo',
            disableForceLocation: 'app/disableForceLocation',
            setLastLocation: 'cart/setLastLocation'
        }),
        setupDefaultCoordinates() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    this.myPosition.lat = position.coords.latitude;
                    this.myPosition.lng = position.coords.longitude;
                });
            }
        },
        initMap()
        {
            this.$root.sidebarOpen = true;
            this.$root.sidebarContainerRow = 4;
            this.$root.rootRowHeight = "100%";

            this.$refs.mapRef.$mapPromise.then(map => {
                this.createControl();
                let self = this
                let lat = Number(map.center.lat());
                let lng = Number(map.center.lng());
                let pos = { lat: lat,lng: lng };

                this.myLocation()
                // if(this.getLastLocation.hasOwnProperty('id'))
                // {
                //     this.myPosition.lat = this.lat = Number(this.getLastLocation.attributes.lat)
                //     this.myPosition.lng = this.lng =  Number(this.getLastLocation.attributes.long)
                // }else
                // {
                //     this.myLocation()
                // }

                this.zoom = 13;
            });

            if (this.contains("col-md-6")) {
                this.removeClass("col-md-6");
                this.addClass("col-md-8");
            }
            if (this.getDefaultLocations[this.$route.meta.type]) {
                // this.moveLocation(this.getDefaultLocations[this.$route.meta.type]);
            }
            if (this.isLoggedIn) {
                this.getCustomerAddresses();
            }
        },
        start(e) {
            this.drag = true
        },
        stop()
        {
            this.drag = false
        },
        handleBoundsChanged() {
            const mapCenter = this.$refs.mapRef.center;
            return
        },
        updateCoordinates(location) {
            this.validLocation = false
            this.$refs.mapRef.$mapPromise.then((map) => {
                this.myPosition.lat = map.center.lat()
                this.myPosition.lng = map.center.lng()
            });

        },
        myLocation()
        {
            let infoWindow = new google.maps.InfoWindow;
            let self = this;
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    function(position) {
                        self.$refs.mapRef.$mapPromise.then(map => {
                            let lat = Number(position.coords.latitude);
                            let lng = Number(position.coords.longitude);
                            let pos = { lat: lat,lng: lng };
                            self.clearMarkers();
                            self.addMarker(lat, lng)
                        });
                        self.zoom = 16;
                    },
                    function() {
                        self.$refs.mapRef.$mapPromise.then((map) => {
                            self.handleLocationError(true, infoWindow, self.myPosition, map);
                        });
                    }
                );
            } else {
                self.$refs.mapRef.$mapPromise.then((map) => {
                    self.handleLocationError(true, infoWindow, self.myPosition, map);
                });
            }
        },
        moveLocation(data) {
            let self = this
            this.$refs.mapRef.$mapPromise.then(map => {

                map.panTo({
                    lat: lat,
                    lng: lng
                });

                let lat = Number(data.attributes.lat);
                let lng = Number(data.attributes.long);
                this.validateLocation(lat, lng)

            });
            this.zoom = 16;
        },
        validateLocation(lat, lng)
        {
            let pos = { lat: lat,lng: lng };
            let self = this
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode(
                {
                    latLng: pos
                },
                function(results, status) {
                    if (status == google.maps.GeocoderStatus.OK) {
                        if (results[0]) {
                            this.validLocation = true
                            self.address.line1 = results[0].formatted_address;
                            self.clearMarkers();
                            self.addMarker(
                                results[0].geometry.location.lat(),
                                results[0].geometry.location.lng()
                            );

                        }
                    } else {
                        self.$toasted.global.error({
                            message: status
                        });
                    }
                }
            );
            Location.validateLocation(lat, lng)
            .then(response => {
                if(response.data.data.hasOwnProperty('id'))
                {
                    this.tempLocation = response.data.data
                    this.$toasted.global.info({
                        message: this.tempLocation.attributes.name + ' ' + this.translation('has_set_location')
                    })
                     window.scroll({
                        top: window.innerHeight,
                        left: 0,
                        behavior: 'smooth'
                    })

                    self.validLocation = true
                }else
                {
                    self.validLocation = false
                    this.$toasted.global.error({
                        message: this.translation('select_another_location')
                    })
                }

            })
            .catch(err => {
                console.log(err)
            })
        },
        contains(className) {
            return this.$parent.$el.classList.contains(className)
                ? true
                : false;
        },
        removeClass(className) {
            this.$parent.$el.classList.remove(className);
        },
        addClass(className) {
            this.$parent.$el.classList.add(className);
        },
        createControl() {
            this.$refs.searchBox.createSearchControl(
                this.$refs.mapRef,
                this.google
            );
        },
        mapClicked(mapClickArgs) {
            this.validateLocation(this.myPosition.lat, this.myPosition.lng)
        },
        getCustomerAddresses() {
            Customer.getAddresses(this.getUser.id)
                .then(response => {
                    this.setUserAddresses(response.data.data);
                })
                .catch(err => {
                    console.error(err);
                });
        },
        saveAddress(e, address) {
            this.$v.$touch();
            if (!this.$v.$anyError) {
                let defaultText = e.target.innerHTML;
                e.target.innerHTML =
                    '<i class="fa fa-circle-o-notch fa-spin"></i> Saving...';
                e.target.disabled = true;

                let params = {
                    lat: this.myPosition.lat,
                    long: this.myPosition.lng,
                    ...address,
                    city: "",
                    country: "",
                    "postal-code": "",
                    line2: "",
                    state: "",
                    "photo-uri": null,
                    "delivery-area-id": null,
                    status: null
                };

                if(!this.getUser){
                    this.setTemporaryDelivery(params)
                    this.address.label = null
                    this.address.instructions = ""
                    let line1 = address.line1;
                    this.disableForceLocation(0)
                    this.setLastLocation(params)
                    this.setOrderType(this.$route.meta.type);
                    this.$v.$reset()
                    this.$toasted.global.info({
                        message: "Address " + line1 + " has been saved"
                    });
                    this.validLocation = false
                    this.$router.push({name: 'home'})
                }
                else{
                    Customer.createAddress(this.getUser.id, params)
                    .then(response => {
                        e.target.innerHTML = defaultText;
                        e.target.disabled = false;
                        let data = response.data.data
                        this.setUserAddresses(response.data.data);
                        this.setDefault(data[0])
                        let line1 = this.address.line1;
                        this.address.label = null
                        this.address.instructions = ""
                        this.$v.$reset()
                        this.$toasted.global.info({
                            message: this.translation('save_delivery_address', 'Address _LINE1_ has been saved').replace('_LINE1_',line1)
                        });
                        this.validLocation = false
                        this.$router.push({name: 'home'})
                    })
                    .catch(err => {
                        let errMsg = err.response.data.error.map(error => { return error.detail; }).join(", ");
                        this.$toasted.global.error({
                            message: errMsg
                        });
                    });
                }
                e.target.innerHTML = defaultText;
                e.target.disabled = false;
            }
        },
        setDefault(address) {
            this.setOrderType(this.$route.meta.type);
            this.setLocation(address);
            this.setQueue(false)
        },
        addMarker(lat, lng) {
            this.setPosition(lat, lng);
        },
        clearMarkers() {
            this.setMapOnAll(null);
            this.markers = [];
        },
        setMapOnAll(map) {
            for (let i = 0; i < this.markers.length; i++) {
                this.markers[i].setMap(map);
            }
        },
        setPosition(lat, lng) {
            this.myPosition.lat = lat;
            this.myPosition.lng = lng;
            this.$refs.mapRef.$mapPromise.then(map => {
                map.setCenter(this.myPosition);
            });
        },
        handleLocationError(browserHasGeolocation, infoWindow, pos, map) {
            infoWindow.setPosition(pos);
            infoWindow.setContent(browserHasGeolocation ?
                                  'Error: The Geolocation service failed.' :
                                  'Error: Your browser doesn\'t support geolocation.');
            infoWindow.open(map);
        },
        isAllowedType(type)
        {
            return this.getConcept.attributes['allowed-order-types'].split(',').includes(type)
        }
    }
};
</script>
