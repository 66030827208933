var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab-parent"},[_c('ul',{staticClass:"tab",class:{ 'two-sections': this.getConcept.attributes['allowed-order-types'].split(',').filter(function (type) { return type != 'curbside' }).length <= 2 }},[(_vm.isAllowedType('deliver'))?_c('router-link',{attrs:{"to":{name: 'delivery-location'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:{ 'active': isActive },style:(isActive ? _vm.activeTabStyle : {}),on:{"click":navigate}},[_c('i',{staticClass:"fa fa-motorcycle",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$parent.translation('delivery_tab_label', 'Delivery'))+" ")])]}}],null,false,2613592756)}):_vm._e(),(_vm.isAllowedType('pickup'))?_c('router-link',{attrs:{"to":{name: 'pickup-location'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:{ 'active': isActive },style:(isActive ? _vm.activeTabStyle : {}),on:{"click":navigate}},[_c('i',{staticClass:"fa fa-shopping-bag",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$parent.translation('pickup_tab_label', 'Pickup'))+" ")])]}}],null,false,1994064242)}):_vm._e(),(_vm.isAllowedType('eat-in'))?_c('router-link',{attrs:{"to":{name: 'dinein-location'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:{ 'active': isActive },style:(isActive ? _vm.activeTabStyle : {}),on:{"click":navigate}},[_c('i',{staticClass:"fa fa-cutlery",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$parent.translation('dine_in_tab_label', 'Dine in'))+" ")])]}}],null,false,1474482448)}):_vm._e(),(_vm.isAllowedType('to-go'))?_c('router-link',{attrs:{"to":{name: 'to-go-location'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:{ 'active': isActive },style:(isActive ? _vm.activeTabStyle : {}),on:{"click":navigate}},[_c('i',{staticClass:"fa fa-shopping-bag",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.translation('to_go_tab_label', 'To-go'))+" ")])]}}],null,false,545326454)}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }