export default {
    total: 0,
    subtotal: 0,
    type: 'deliver',
    temporaryDelivery: null,
    defaultLocation: {
        pickup: null,
        deliver: null,
        "eat-in": null,
        "to-go": null
    },
    lastLocationSelected: {

    },
    location: null,
    address: null,
    payment: [],
    scheduledTime: null,
    vatAmount: 0,
    deliveryCharge: 0,
    discount: 0,
    discounted: 0,
    notes: '',
    couponCode: '',
    couponData: {},
    totalPoints: 0,
    items: [],
    user: {},
    hyperPay: {},
    branches: [],
    addresses: [],
    table: null,
    timestamp: null,
    queue: false,
    /*
    |-----------------------------------------------------------
    |
    |-----------------------------------------------------------
    |
    |
    |
    */
    temporaryLocation: null,
    tempItemOrder: {},
    selectedDrink: null,
    selectedSide: null,
    selectedExtras: [],
    selectedFries: null,
    selectedSize: 'regular',
    itemOrderType: 'meal', //meal or single
    tempSubTotal: 0,
    tempTotal: 0,
    redirectToMenu: false,
    redirectTo: null,
    'items-updated-at': null,
    'location-update-at': null
}
