const meta = {
    title: 'Online Ordering'
};


export default [
    {
        path: '/',
        redirect: '/home',
        meta: {
            title: meta.title,
            guest: true,
            auth: true,
        }
    },
    {
        path: '/login',
        name: 'login',
        component: require('../pages/authentication/Login').default,
        meta: {
            title: meta.title,
            guest: true,
        }
    },
    {
        path: '/register',
        name: 'register',
        component: require('../pages/registration/register').default,
        meta: {
            title: meta.title,
            guest: true,
        }
    },
    {
        path: '/googlemap',
        name: 'googlemap',
        component: require('../pages/googlemap/googlemap').default,
        meta: {
            title: meta.title,
            guest: true,
            auth: false,
            metaTags: [
                {
                    name: 'google',
                    content: 'notranslate'
                }
            ]
        },
        children: [
            {
                path: 'gmap/:lat/:lng',
                name: 'g-map',
                component: require('../pages/googlemap/page/gmap').default,
                meta: {
                    title: meta.title,
                    guest: true,
                    auth: true,
                }
            },
        ]
    },

    {
        path: '/location',
        name: 'location',
        component: require('../pages/location/Location').default,
        meta: {
            title: meta.title,
            guest: true,
            auth: false,
            metaTags: [
                {
                    name: 'google',
                    content: 'notranslate'
                }
            ]
        },
        children: [
            {
                path: 'default',
                name: 'default-location',
                component: require('../pages/location/pages/default/Default').default
            },
            {
                path: 'delivery',
                name: 'delivery-location',
                component: require('../pages/location/pages/deliver/Deliver').default,
                meta: {
                    type: 'deliver'
                }
            },
            {
                path: 'pickup',
                name: 'pickup-location',
                component: require('../pages/location/pages/pickup/Pickup').default,
                meta: {
                    type: 'pickup'
                }
            },
            {
                path: 'dinein',
                name: 'dinein-location',
                component: require('../pages/location/pages/pickup/Pickup').default,
                meta: {
                    type: 'eat-in'
                }
            },
            {
                path: 'to-go',
                name: 'to-go-location',
                component: require('../pages/location/pages/pickup/Pickup').default,
                meta: {
                    type: 'to-go'
                }
            },
        ]
    },
    {
        path: '/location/options',
        name: 'location-options',
        component: require('../pages/location/Location').default,
        meta: {
            title: meta.title,
            guest: true,
            auth: true,
            metaTags: [
                {
                    name: 'google',
                    content: 'notranslate'
                }
            ]
        }
    },
    {
        path: '/home/:catName?',
        name: 'home',
        component: require('../pages/home/Home').default,
        meta: {
            title: meta.title,
            guest: true,
            auth: true,
            metaTags: [
                {
                    name: 'google',
                    content: 'notranslate'
                }
            ]
        }
    },
    {
        path: '/restaurants',
        name: 'restaurants',
        component: require('../pages/restaurants/Restaurants').default,
        meta: {
            title: meta.title,
            guest: true,
            auth: true,
            metaTags: [
                {
                    name: 'google',
                    content: 'notranslate'
                }
            ]
        }
    },
    {
        path: '/:categoryId/item/:id',
        name: 'item',
        component: require('../pages/item/ItemPage').default,
        meta: {
            title: meta.title,
            guest: true,
            auth: true,
            metaTags: [
                {
                    name: 'google',
                    content: 'notranslate'
                }
            ]
        }
    },
    {
        path: '/checkout/:datetime?',
        name: 'checkout',
        component: require('../pages/payments/Checkout').default,
        meta: {
            title: meta.title,
            guest: false,
            auth: true,
            metaTags: [
                {
                    name: 'google',
                    content: 'notranslate'
                }
            ]
        }
    },
    {
        path: '/hyperpay/:id/:orderId/:method?',
        name: 'hyperpay',
        component: require('../pages/hyperpay/Hyperpay').default,
        meta: {
            title: meta.title,
            guest: false,
            auth: true,
            metaTags: [
                {
                    name: 'google',
                    content: 'notranslate'
                }
            ]
        }
    },
    {
        path: '/hyperpay/:id/:orderId/status',
        name: 'hyperpay',
        component: require('../pages/hyperpay/HyperpayStatus').default,
        meta: {
            title: meta.title,
            guest: false,
            auth: true,
            metaTags: [
                {
                    name: 'google',
                    content: 'notranslate'
                }
            ]
        }
    },
    {
        path: '/order/:id/status/:orderId?',
        name: 'status',
        component: require('../pages/order/status/Status').default,
        meta: {
            title: meta.title,
            guest: false,
            auth: true,
            metaTags: [
                {
                    name: 'google',
                    content: 'notranslate'
                }
            ]
        }
    },
    {
        path: '/queuebuster',
        name: 'queuebuster',
        component: require('../pages/queuebuster/QueueBuster').default,
        meta: {
            title: meta.title,
            guest: false,
            auth: true,
            metaTags: [
                {
                    name: 'google',
                    content: 'notranslate'
                }
            ]
        }
    },
    {
        path: "*",
        redirect: '/home',
        meta: {
            title: meta.title,
            guest: true,
            auth: true,
        }
    },
    {
        path: '/payment/tap/order/:orderId/',
        name: 'tap-payment',
        component: require('../pages/payment-gateway/tap/Tap').default,
    },
    {
        path: '/payment/tap/redirect',
        name: 'redirect-tap-payment',
        component: require('../pages/payment-gateway/tap/redirect/Redirect').default,
    },
    {
        path: '/payment/checkout/order/:orderId/:amount',
        name: 'checkout-payment',
        component: require('../pages/payment-gateway/checkout/Checkout').default,
    },
    {
        path: '/pay',
        name: 'kiosk-pay',
        component: require('../pages/kiosk-pay/Pay').default,   
    },
    {
        path: '/pay/redirect',
        name: 'kiosk-pay-redirect',
        component: require('../pages/kiosk-pay/Redirect').default,   
    }
]
