import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import ErrorModal from '@/layouts/components/global/ErrorModal.vue'

@Component({
  components: {
    ErrorModal
  }
})
export default class Error extends Vue {  
  public error: Boolean = false
  public errorMessage: String = ''
  public okTitle: String = ''

  public showErrorMessage(message: String, okTitle: String) {
    this.errorMessage = message
    this.okTitle = okTitle
    this.error = true
    console.log(this)
  }
}
