import BaseService from '../base.service'

export default class OrderService extends BaseService{

    constructor(){
        super();
        this.baseURL = this.baseURL + '/'
    }

    all(page = 1){
        let url = this.baseURL + '';

        return super.get(url, {
            page: page
        });
    }

    validate(params: object = {}) {
        let url = this.baseURL + 'orders/validate'
        
        return super.post(url, params);
    }

    store(params: {}) {

        let url = this.baseURL + 'orders';

        return super.post(url, params);
    }

    coupon(coupon: string){
        let url = this.baseURL + 'coupons?coupon-code=' + coupon + '&include=promotion' ;
        return super.get(url);
    }

    applycoupon(params: {}){
        let url = this.baseURL + 'orders?include=promotion';

        return super.post(url, params);
    }

    hyperpay(params: {})
    {
        let url = this.baseURL + 'hyperpay/checkout';

        return super.post(url, params);
    }

    hyperpayStatus(params: {})
    {
        let url = this.baseURL + 'hyperpay/get-payment-status';

        return super.post(url, params);
    }

    find(orderId: string){
        let url = this.baseURL + 'orders/' + orderId + '?include=items,location';

        return super.get(url)
    }    

    statuses(orderId: string){
        let url = this.baseURL + 'orders/' + orderId + '/statuses?filter[visible]=1';

        return super.get(url)
    }

    feedback(params: {})
    {
        let url = this.baseURL + 'feedback'
        let headers = {                        
            "Content-Type": "form-data"
        }
        return super.post(url, params, headers)
    }

    postedFeedback(orderId: string)
    {
        let url = this.baseURL + 'orders/'+ orderId +'/ratings/topics'

        return super.get(url)
    }

    topics()
    {        
        let url = this.baseURL + '/topics'

        return super.get(url)
    }

    findItems(orderId: string){
        let url = this.baseURL + 'orders/' + orderId + '/items';

        return super.get(url)
    }

    findResource(orderId: string,itemOrderId: string){
        let url = this.baseURL + 'orders/' + orderId + '/items/' + itemOrderId + '?include=items';

        return super.get(url)   
    }

    findStatuses(orderId: string){
        let url = this.baseURL + 'orders/' + orderId + '/statuses';

        return super.get(url)
    }

    findDriver(orderId: string){
        let url = this.baseURL + 'orders/' + orderId + '/employees?filter[function]=driver';

        return super.get(url)
    }

    cancel(orderId: string, params: any) {

        let url = this.baseURL + 'orders/' + orderId + '/cancellation';

        return super.patch(url, params);
    }
}
