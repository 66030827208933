<template>
    <b-col
        :md="$root.sidebarContainerRow"
        class="sidebar bg-light-gray right"
        :class="{ 'show' : showSideArea }"
        ref="sideBar"
    >
        <div class="toogle-menu-close" @click="hideSideBar">
            <img src="/images/illustrations/x button for pages@3x.png" alt="">
        </div>

        <SidebarContainer :show="showSideArea" ref="cart"></SidebarContainer>
        <GuestPhoneNumber ref="GuestPhoneNumberModal" />
    </b-col>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import SidebarContainer from "./SidebarContainer";
import GuestPhoneNumber from '@/layouts/components/GuestPhoneNumber'
import { eventHub } from '../../assets/plugins/events'
export default {
    name: "SideBar",
    components: { SidebarContainer, GuestPhoneNumber },
    computed: {
        ...mapGetters({
            getRedirectTo: 'cart/getRedirectTo',
        }),
    },
    data() {
        return {
            showSideArea: false
        };
    },
    mounted() {
        
        eventHub.$on("checkout-m-back", isTrue => {
            setTimeout(() => {
                this.showSideBar();
            }, 500);

        });
    },
    watch: {
        $route(to, from) {
            this.showSideArea = false;
        }
    },
    methods: {
        ...mapMutations({
            setRedirectTo: 'cart/setRedirectTo',
        }),
        updateSidebar() {
            if (this.$route.name !== "login" && this.$route.name !== "register") {
                this.$root.sidebarOpen = true;
            } else {
                this.$root.sidebarOpen = false;
            }
        },
        hideSideBar() {
            this.showSideArea = false;
        },
        showSideBar() {
            this.showSideArea = true;            
            if (this.getRedirectTo === 'cart') {
              this.setRedirectTo(null);
              this.$refs.GuestPhoneNumberModal.show();
              // this.$router.push({ name: 'login' });
            }
        }
    }
};
</script>
