<template>
  <b-container fluid class="order-type">
    <b-row>
      <b-col md="10" class="offset-md-2">
        <label>{{translation('how_to_get_food', 'How would you like to get the food?')}}</label>
        <b-row class="mt-4">
          <b-col md="3">
            <b-card class="text-muted">
              <b-form-checkbox
                id="order-type-1"                
                name="order-type-1"
                value="accepted"
                unchecked-value="not_accepted"
              >{{translation('dine_in', 'Dine-in')}}</b-form-checkbox>
            </b-card>
          </b-col>
          <b-col md="3">
            <b-card class="text-muted">
              <b-form-checkbox
                id="order-type-2"                
                name="order-type-2"
                value="accepted"
                unchecked-value="not_accepted"
              >{{translation('takeout', 'Takeout')}}</b-form-checkbox>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { TranslationMixin } from '@/mixins'
export default {   
    mixins: [TranslationMixin]
}
</script>