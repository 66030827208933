<template>
    <div class="select-payment-page pt-4">
        <error-modal :show="showErrorModal" :ok-title="okTitle" :error-message="errorMessage" @close="closeErrorModal"></error-modal>
        <div class="row mt-4">
            <div id="wrap-content"></div>
            <div class="col-md-11 offset-md-1">
                <h5 class="sub-title">{{translation('select_payment_method')}}</h5>
                <div class="row mt-4">
                    <div class="col-md-6" v-if="totalPrice">
                        <b-card
                            class="mt-4"
                            v-show="(isSafari() && type.toLowerCase() === 'apple-pay') || type.toLowerCase() != 'apple-pay'"
                            :class="{ 'active' : paymentType === type }"
                            v-for="(type, index) in getAllowedPaymentMethods"
                            :key="index"
                            @click="setMethod(type, index)"
                            @mouseleave="hovered = null"
                            :style="hovered === index || paymentType === type ? hoveredCardStyle : {}"
                        >
                            <div class="card-method">
                                <img :src="cdnURL + '/images/payment_methods/'+type.toLowerCase()+'.png'" height="27" alt />
                                {{ucwords(cardMethod(type)).replace('_',' ')}}
                            </div>
                        </b-card>
                        <div class="form-group">
                            <button
                                @click="submitOrder($event)"
                                class="btn bg-blue form-control btn-continue"
                                :style="submitOrderStyle"
                            >{{translation('submit_order')}}</button>
                        </div>
                    </div>
                    <div class="col-md-6" v-else>
                        <b-card
                            class="mt-4 active"
                            :style="hoveredCardStyle"
                        >
                            <div class="card-method">
                                <img :src="cdnURL + '/images/payment_methods/cash.png'" height="27" alt />
                                {{ucwords(cardMethod('cash')).replace('_',' ')}}
                            </div>
                        </b-card>
                        <div class="form-group">
                            <button
                                @click="submitOrder($event)"
                                class="btn bg-blue form-control btn-continue"
                                :style="submitOrderStyle"
                            >{{translation('submit_order')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .select-payment-page {
        .sub-title {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #1E1E1E;
            text-transform: uppercase;
        }
    }
</style>
<script>
import { mapGetters, mapMutations } from "vuex";
import { TranslationMixin, HelperMixin } from "@/mixins";
import { Order, Concept, Coupon } from "@/services/SOLO";
import ErrorModal from '@/layouts/components/global/ErrorModal.vue'
import moment from 'moment'
export default {
    data() {
        return {
            paymentType: null,
            hovered: null,
            hoveredCardStyle: {
                border: "1px solid " + this.$root.mainColor
            },
            submitOrderStyle: {
                background: this.$root.mainColor
            },
            coupons: [],
            isConfiguring: true,
            redirectURL: window.location.hostname + `payment/tap/redirect`,
            goSellCdn: null,
            showErrorModal: false,
            errorMessage: '',
            okTitle: this.translation('okay'),
            promotionsReponse: null,
            event: null,
            total: null,
            cdnURL: process.env.VUE_APP_CDN_URL ?? 'https://cdn.ordernosh.com',
            cardOptionEnabled: true,
            totalPrice: 0
        };
    },
    components: {
        ErrorModal
    },
    mixins: [TranslationMixin, HelperMixin],
    computed: {
        ...mapGetters({
            getConcept: "concept/getConcept",
            getUser: "account/getUser",
            getCart: "cart/getCart",
            getLocale: "app/getLocale",
            getAllowedOrderTypes: "app/getAllowedOrderTypes",
            getAllowedDeliveryPayments: "app/getAllowedDeliveryPayments",
            getAllowedPickupPayments: "app/getAllowedPickupPayments",
            hasDefault: "cart/hasDefault",
            couponResult: "cart/couponResult",
            getTapOrderID: "app/getTapOrderID",
            getTapOrderAmount: "app/getTapOrderAmount",
            getTapPaymentStatus: 'app/getTapPaymentStatus',
            getTapCredentials: 'app/getTapCredentials',
            getLocation: 'cart/getLocation',
            getPaymentType: 'app/getPaymentType',
            currency: 'concept/currency',
        }),
        customer() {
            return {
                customer: {
                    id: null,
                    first_name: this.getUser.attributes['first-name'],
                    last_name: this.getUser.attributes['last-name'],
                    phone: {
                        country_code: this.getConcept.attributes["dialing-code"],
                        number: this.removeMobilePrefix(
                            this.getUser.attributes.mobile
                        )
                    }
                }
            };
        },
        gatewayDetails() {
            let details = {
                publicKey: this.getTapCredentials.option_value,
                language: "en",
                contactInfo: true,
                supportedCurrencies: "all",
                saveCardOption: false,
                customerCards: true,
                notifications: "standard",
                supportedPaymentMethods:  "all"
            };

            if (this.getLocation.hasOwnProperty('custom-fields')) {
                this.getLocation.attributes['custom-fields'].forEach(row => {
                    if (row['custom-field'] === 'tap_merchant_id' && row.value !== '') {
                        details.tap_merchant_id = row.value;
                    }
                });
            }

            return details;
        },
        styles() {
            return {
                backgroundImg: {
                    url: this.getConcept.attributes["logo-uri"],
                    opacity: "1.0"
                },
                labels: {
                    cardNumber: "Card Number",
                    expirationDate: "MM/YY",
                    cvv: "CVV",
                    cardHolder: "Name on Card",
                    actionButton: "Pay"
                },
                style: {
                    base: {
                        color: "#535353",
                        lineHeight: "18px",
                        fontFamily: "sans-serif",
                        fontSmoothing: "antialiased",
                        fontSize: "16px",
                        "::placeholder": {
                            color: "rgba(0, 0, 0, 0.26)",
                            fontSize: "15px"
                        }
                    },
                    invalid: {
                        color: "red",
                        iconColor: "#fa755a "
                    }
                }
            };
        },
        getAllowedPaymentMethods() {
            if (this.getCart.type === "deliver") {
                return this.getConcept.attributes['allowed-payments-deliver'].split(',');
            }

            return this.getConcept.attributes['allowed-payments-pickup'].split(',');
        },
        freeDelivery() {
            if(Number(this.getConcept.attributes['minimum-order-amount-free-delivery'])) {
                if(this.getSubTotal() >= Number(this.getConcept.attributes['minimum-order-amount-free-delivery']))  {
                    return true
                }
                return false
            }
            return false;
        }
    },
    mounted() {
        window.scrollTo(0,0)
        this.totalPrice = this.$parent.$parent.$refs.sidebar.$refs.cart.$refs.wrapper.$refs.payment.totalPrice
        if(!this.totalPrice)  {
            this.paymentType = 'cash'
        }
    },
    methods: {
        ...mapMutations({
            setType: "cart/setType",
            setPaymentType: 'app/setPaymentType',
            emptyCart: "cart/emptyCart",
            setTapOrderID: "app/setTapOrderID",
            setTapOrderAmount: "app/setTapOrderAmount",
            storeTapOrderData: "app/storeTapOrderData",
            setTapPaymentStatus: "app/setTapPaymentStatus",
            setTapCredentials: "app/setTapCredentials",
            setCheckoutCredentials: "app/setCheckoutCredentials",
            setCoupon: "cart/setCoupon",
            setCouponResult: "cart/setCouponResult",
            resetCoupon: "cart/resetCoupon"
        }),
        ...mapMutations({
            setTapPaymentStatus: 'app/setTapPaymentStatus'
        }),
        closeErrorModal(show = false) {
            this.showErrorModal = show
            if(this.event) {
                this.validateOrder(this.promotionsReponse, this.event, this.total);
            }
        },
        setConfig(id) {
            return {
                containerID: "wrap-content",
                gateway: {
                    ...this.gatewayDetails,
                    callback: response => {
                        this.isConfiguring = false
                    },
                    onClose: () => {
                        console.log("onClose Event");
                    },
                    ...this.styles
                },
                ...this.customer,
                order: {
                    amount: this.getTapOrderAmount,
                    currency: this.getConcept.attributes["currency-code"],
                    items: [],
                    shipping: null,
                    taxes: null
                },
                transaction: {
                    mode: "charge",
                    charge: {
                        saveCard: false,
                        threeDSecure: true,
                        description: "Test Description",
                        statement_descriptor: "Sample",
                        reference: {
                            transaction: this.setTransaction(),
                            order: id
                        },
                        metadata: {},
                        receipt: {
                            email: false,
                            sms: true
                        },
                        redirect: this.redirectURL,
                        post: process.env.VUE_APP_API_URL + `/webhook/tap/feedback`
                    }
                },
            };
        },
        setTransaction() {
            var d = new Date();
            return d.getTime() + this.getTapOrderID;
        },
        removeMobilePrefix(mobile) {
            let formattedMobile = mobile.toString();
            return formattedMobile.substring(3, formattedMobile.length);
        },
        isSafari() {
            if (window.ApplePaySession) {
                return true
            }
            return false
        },
        getSubTotal() {
            if (this.getCart.items.length) {
                return this.getCart.items
                    .map(item => (item.price + item.modifierPrice) * item.quantity)
                    .reduce((a, b) => a + b);
            }
        },
        cardMethod(type)
        {
            const text = type.replace(/-/g, "_")
            const formattedText = text.toLowerCase() === 'card' ? 'card_caps' : text
            return this.translation(formattedText.toLowerCase(),formattedText.toLowerCase());
            // translation(type.replace(/-/g, "_"), type.replace(/-/g, " "))
        },
        selectMethod() {
            this.$parent.method = true;
        },
        setMethod(type, index) {
            if(this.cardOptionEnabled) {
                this.paymentType = type;
            }
        },
        submitOrder(e) {
            if (!this.paymentType) {
                // return this.$toasted.global.error({
                //     message: this.translation("please_select_payment_type")
                // });
                let msg = this.translation("please_select_payment_type")
                this.showErrorModal = true
                this.errorMessage = msg
                return false
            }

            let subtotal = 0;

            if(this.getCart.items.length) {
                subtotal = this.getCart.items
                    .map(item => (item.price + item.modifierPrice) * item.quantity)
                    .reduce((a, b) => a + b);
            }

            let total = subtotal;
            let location = null;
            let address = null;
            let delivery = 0;

            if(subtotal < this.getConcept.attributes['minimum-order-amount-delivery'] && this.getCart.type === 'deliver') {
                // return this.$toasted.global.error({
                //     message: this.translation('minimum_delivery_order_amount', 'Minimum order for delivery is _AMOUNT_  _CURRENCY_ excluding delivery charge').replace('_AMOUNT_',this.getConcept.attributes['minimum-order-amount-delivery']).replace('_CURRENCY_', this.translation('sr'))
                // });
                let msg = this.translation('minimum_delivery_order_amount', 'Minimum order for delivery is _AMOUNT_  _CURRENCY_ excluding delivery charge').replace('_AMOUNT_',this.getConcept.attributes['minimum-order-amount-delivery']).replace('_CURRENCY_', this.translation(this.currency.key, this.currency.value))
                this.showErrorModal = true
                this.errorMessage = msg
                return false
            }

            // if (this.getCart.discount > 0) {
            //     if (this.getCart.discount < 1)
            //         total = total - total * this.getCart.discount;
            //     else total = total - this.getCart.discount;
            // }

            if ( this.getCart.type === 'deliver' && this.hasDefault && !this.freeDelivery ) {
                total += this.getConcept.attributes["default-delivery-charge"];
                delivery = this.getConcept.attributes["default-delivery-charge"];
            }

            let vatAmount = 0;

            if (this.getConcept.attributes["vat-type"] === "exclusive") {
                vatAmount = total * (this.getConcept.attributes["vat-rate"] / 100);
            }

            total += vatAmount;

            if ( this.getCart.type === "pickup" || this.getCart.type === "eat-in" || this.getCart.type === "to-go" ) {
                if (this.getCart.defaultLocation[this.getCart.type]) {
                    location = this.getCart.defaultLocation[this.getCart.type].id;
                } else {
                    if (!this.hasDefault) {
                        return this.$router.push({ name: "pickup-location" });
                    }
                }
            } else {
                if (this.getCart.defaultLocation[this.getCart.type]) {
                    address = this.getCart.defaultLocation[this.getCart.type].id;
                } else {
                    if (!this.hasDefault) {
                        return this.$router.push({ name: "pickup-location" });
                    }
                }
            }

            if (this.getCart.couponCode) {
                this.coupons.push(this.getCart.couponCode);
            }

            let schedule = {}
            let isValidSchedule = moment(this.$route.params.datetime).isValid()
            if(isValidSchedule) {
                schedule = {
                    "scheduled-time": this.$route.params.datetime,
                }
            }


            let params = {
                customer: this.getUser.id,
                source: "Web",
                subtotal: subtotal,
                discount: this.translateNumber(this.getDiscount()),
                ...schedule,
                location: location,
                type: this.getCart.type,
                "table-label": this.getCart.table,
                "vat-amount": vatAmount,
                total: total,
                "coupon-code": this.getCart.couponCode,
                items: this.getCart.items.map(item => {
                    let comboItems = [];

                    if (
                        item.attributes.hasOwnProperty("is-combo") &&
                        item.hasOwnProperty("combo-items")
                    ) {
                        if (item["combo-items"].length) {
                            item["combo-items"].map(combo => {
                                comboItems.push({
                                    id: combo.id,
                                    price: combo.price,
                                    quantity: combo.quantity,
                                    ingredients: combo.ingredients,
                                    modifiers: combo.modifiers
                                        .filter(option => {
                                            return option.quantity;
                                        })
                                        .map(option => {
                                            return {
                                                id: option.id,
                                                price: option.price,
                                                quantity: option.quantity
                                            };
                                        })
                                });
                            });
                        }
                    }

                    let comboItemObj = comboItems.length
                        ? { "combo-items": comboItems }
                        : {};
                    return {
                        id: item.id,
                        price: item.price,
                        quantity: item.quantity,
                        notes: '',
                        discount: item.discount,
                      ingredients: item.hasOwnProperty("removed") ? item.removed.filter(ingredient => { return ingredient.checked })
                          .map(ingredient => {
                            return {
                              id: ingredient.id,
                              quantity: ingredient.quantity
                            };
                          }) : [],
                        modifiers: item.modifiers.map(modifier => {
                            return {
                                id: modifier.id,
                                price: modifier.attributes.price,
                                quantity: modifier.quantity
                            };
                        }),
                        ...comboItemObj
                    };
                }),
                "delivery-charge": delivery,
                address: address,
                payment: {
                    method: this.paymentType,
                    ...this.cashPresented(),
                    amount: total
                }
            };
            let defaultText = e.target.innerText;
            e.target.innerHTML = `<i class="fa fa-spinner fa-spin" aria-hidden="true"></i> ${this.translation('loading')} ...`;
            e.target.disabled = true;
            this.cardOptionEnabled = false
            Coupon.promotions(params).then(response => {
                if(response.data.hasOwnProperty('error')) {
                    this.showErrorModal = true
                    this.errorMessage = response.data.error
                    delete response.data['error']
                    this.promotionsReponse = response.data
                    this.event = e
                    this.total = total
                    this.cardOptionEnabled = true
                } else {
                  this.checkoutOrder(response.data, e, total, defaultText);
                }

            }).catch(err => {
                e.target.innerHTML = defaultText;
                e.target.disabled = false;
                this.$toasted.global.error({
                    message: err.response.data.error[0].detail
                });
            })
        },
        validateOrder(params, e, total, defaultText) {

            Order.validate(params)
            .then(response => {
                this.checkoutOrder(response.data, e, total, defaultText);
            })
            .catch(err => {
                this.cardOptionEnabled = true
                e.target.innerHTML = defaultText;
                e.target.disabled = false;
                this.$toasted.global.error({
                    message: err.response.data.error[0].detail
                });
            });
        },
        checkoutOrder(params, e, total, defaultText) {
            Order.store(params)
            .then(response => {

                this.setPaymentType(this.paymentType.toLowerCase())
                this.event = null
                if (
                    (this.paymentType.toLowerCase() === "card" || this.paymentType.toLowerCase() === "apple-pay") && this.getConcept.attributes["payment-gateway"].toLowerCase() === "tap"
                ) {
                    this.setTapOrderID(response.data.data.id);
                    this.setTapOrderAmount(params.total);
                    params.payment.method = "cash";
                    this.storeTapOrderData(params);
                    this.redirectURL = (window.location.hostname.includes('localhost') ? 'http://localhost:8080' : 'https://' + window.location.hostname )+ "/payment/tap/redirect"
                    goSell.config(this.setConfig(response.data.data.id))
                    setTimeout(()=> {
                        goSell.openPaymentPage()
                    },2000)
                    // here
                } else if (
                    this.paymentType.toLowerCase() === "card" &&
                    (
                        this.getConcept.attributes["payment-gateway"].toLowerCase() === "checkout"
                        || this.getConcept.attributes["payment-gateway"].toLowerCase() === "foodics-online"
                    )
                ) {
                    this.getCheckoutPublicKey({
                        name: "checkout-payment",
                        params: {
                            orderId: response.data.data.id,
                            amount: params.total
                        }
                    }, this.getConcept.attributes["payment-gateway"].toLowerCase());
                } else if (
                    (this.paymentType.toLowerCase() === "card" || this.paymentType.toLowerCase() === "mada" || this.paymentType.toLowerCase() === "apple-pay") &&
                    (this.getConcept.attributes["payment-gateway"].toLowerCase() === "hyperpay" || this.getConcept.attributes["payment-gateway"].toLowerCase() === "hyperpay-test")
                ) {

                    let cardType = {}

                    if(this.paymentType.toLowerCase() === "mada")
                    {
                        cardType = {
                            method: "mada"
                        }
                    } else if(this.paymentType.toLowerCase() === "apple-pay") {
                        cardType = {
                            method: "apple-pay"
                        }
                    }
                    let payload = {
                        'order-id': response.data.data.id,
                        amount: params.total,
                        source: 'Web',
                        ...cardType
                    }
                    this.hyperPay(payload, e, defaultText, response.data.data.id, cardType)
                } else {
                    this.$router.push({
                        name: "status",
                        params: { id: response.data.data.id }
                    });
                    this.$toasted.global.info({
                        message: this.translation('order_placed_message')
                    });
                    this.emptyCart();
                    this.resetCoupon()
                }
            })
            .catch(err => {
                e.target.innerHTML = defaultText;
                e.target.disabled = false;

                this.event = null
                this.cardOptionEnabled = true
                return this.$toasted.global.error({
                    message: err.response.data.error[0].detail

                });
            });
        },
        hyperPay(params,e, defaultText, orderId, cardType) {
            Order.hyperpay(params).then(response => {
                this.$router.push({
                    name: 'hyperpay',
                    params: {
                        id: response.data.checkoutId,
                        orderId: orderId,
                        ...cardType
                    }
                })
            }).catch(error => {
                console.log(error)
                e.target.innerHTML = defaultText;
                e.target.disabled = false;
                this.cardOptionEnabled = true
                return this.$toasted.global.error({
                    message: err.response.data.error[0].detail
                });
            })
        },
        getDiscount() {
            if (this.couponResult.hasOwnProperty("discount-value")) {
                if (this.couponResult["discount-type"] == "percentage") {
                    if (this.getCart.items.length) {
                        let finalTotal = this.getCart.items
                            .map(item => (item.price + item.modifierPrice) * item.quantity)
                            .reduce((a, b) => a + b);
                        this.getCart.discount = this.couponResult["discount-value"] / 100;
                        finalTotal =(finalTotal * this.couponResult["discount-value"]) / 100;
                        if(this.couponResult['discount-max'] <= 0 || (this.couponResult['discount-max'] > 0 && Number(finalTotal) < Number(this.couponResult['discount-max'])))
                        {
                            return finalTotal
                        }
                        return Number(this.couponResult['discount-max'])
                    }
                } else if (this.couponResult["discount-type"] == "fixed") {
                    this.getCart.discount = this.couponResult["discount-value"];
                    return this.couponResult["discount-value"];
                }
            }
            return 0;
        },
        getTapPublicKey(params) {
            Concept.getTapPublicKey().then(response => {
                this.setTapCredentials(response.data.data);
                this.$router.push(params);
            });
        },
        getCheckoutPublicKey(params, gateway) {
            Concept.getCheckoutPublicKey(gateway).then(response => {
                this.setCheckoutCredentials(response.data.data)
                this.$router.push(params);
            }).catch(err => {
                this.cardOptionEnabled = true
            });
        },
        cashPresented() {
            let cashPresented = {};

            if (this.paymentType.toLowerCase() === "cash") {
                cashPresented = {
                    "cash-presented": 0
                };
            }

            return cashPresented;
        },
        ucwords(str) {
            return str.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                return letter.toUpperCase();
            });
        }
    }
};
</script>
