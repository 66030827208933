<template>
  <section id="restaurants">
    <b-row class="restaurant">
      <Category
        :item="item"
        v-for="(item, index) in items.filter((item) => {
          return item.enabled;
        })"
        :key="index"
      ></Category>
    </b-row>
    <b-row
      class="view-order"
      :style="orderDetailBtnStyle"
      :class="{ 'btm-100': getCart.items.length > 0 }"
      v-if="currentOrders.length > 0"
      @click="orderStatus(currentOrders[0].id)"
    >
      <b-col md="12" class="px-0">
        <h5>
          {{ translation("order_prepared", "Your order is being prepared:") }}
          {{ currentOrders[0].attributes['display-id'] }}
        </h5>
        <p class="mb-2">
          <small>{{
            currentOrders[0].attributes["current-status"]["description"]
          }}</small>
        </p>
        <p class="mb-0" style="text-decoration: underline">
          {{ translation("view_details", "View Details") }}
        </p>
      </b-col>
    </b-row>
    <div
      class="row cart-info"
      v-if="getCart.items.length > 0"
      :style="{ '--main-color': $root.mainColor }"
    >
      <div class="price-info">
        <span class="item-num">{{ translateNumber(totalQty, 0) }}</span>
        <i aria-hidden="true" class="fa fa-shopping-bag"></i>
        {{ translateNumber(totalPrice) }}
        {{translation(currency.key, currency.value)}}
      </div>
      <div class="checkout">
        <b-form-group class="mb-0">
          <button
            :style="checkoutBgStyle"
            class="btn form-control"
            @click="checkOutOrder"
          >
            {{ translation("view_cart", "View Cart") }}
          </button>
        </b-form-group>
      </div>
    </div>
  </section>
</template>
<style lang="scss" scoped>
  .cart-info {
    font-family: 'Almarai' !important;
  }
</style>
<script>
import Category from "./components/Category";
import { TranslationMixin, HelperMixin } from "@/mixins";
import { mapGetters, mapMutations } from "vuex";
import { Customer } from "@/services/SOLO";
import moment from "moment";
const _ = require("lodash");
export default {
  data() {
    return {
      cartItemHolderStyle: {
        "border-top": "2px solid " + this.$root.mainColor,
      },
      checkoutBgStyle: {
        background: this.$root.mainColor,
      },
      borderLeftStyle: {
        "border-left": "2px solid " + this.$root.mainColor,
      },
      types: {
        deliver: "delivery-location",
        pickup: "pickup-location",
        "eat-in": "dinein-location",
      },
      orderDetailBtnStyle: {
        position: "fixed",
        bottom: "8px",
        margin: "0px 8px",
        left: 0,
        background: this.$root.secondaryColor,
        border: "1px solid " + this.$root.mainColor,
        color: this.$root.mainColor,
        "box-shadow": "5px 15px 35px -24px" + this.$root.mainColor,
      },
      currentOrders: [],
    };
  },
  name: "Categories",
  props: ["items"],
  components: { Category },
  mixins: [TranslationMixin, HelperMixin],
  computed: {
    ...mapGetters({
      getItems: "cart/getItems",
      getCart: "cart/getCart",
      isLoggedIn: "account/isLoggedIn",
      getOrderType: "cart/getOrderType",
      hasDefault: "cart/hasDefault",
      getUser: "account/getUser",
      getLocale: "app/getLocale",
      getTemporaryLocation: "cart/getTemporaryLocation",
      getConcept: "concept/getConcept",
      currency: 'concept/currency',
    }),
    preTotalPrice() {
      if (this.getCart.items.length) {
        return this.getCart.items
          .map((item) => {
            return (item.price + item.modifierPrice) * item.quantity;
          })
          .reduce((a, b) => {
            return a + b;
          });
      }

      return 0;
    },
    totalQty() {
      if (this.getCart.items.length) {
        return this.getCart.items
          .map((item) => {
            return item.quantity;
          })
          .reduce((a, b) => {
            return a + b;
          });
      }

      return 0;
    },
    isMobile() {
      return screen.width < 768 ? true : false;
    },
    totalPrice() {
      let delivery = 0;
      let total = this.preTotalPrice;

      return total;      
    },
  },
  mounted() {
    if (this.isLoggedIn) {
      this.getOrders();
    }
  },
  methods: {
    ...mapMutations({
      setRedirectTo: "cart/setRedirectTo",
    }),
    showSideBar() {
      this.$root.$children[0].$refs.sidebar.$refs.cart.switchComponent("Cart");
      this.$root.$children[0].$refs.sidebar.showSideBar();
    },
    checkOutOrder() {
			console.log(!this.hasDefault, this.getCart.type, _.isEmpty(this.getCart.temporaryDelivery))
			console.log(this.getCart.type === "deliver" ? true : false, _.isEmpty(this.getCart.temporaryDelivery))

      if (!this.getItems.length) {
        this.$toasted.global.error({
          message: this.translation("no_item_selected", "No item selected"),
        });
      } else if (
        !this.hasDefault &&
        this.getCart.type === "deliver" &&
        _.isEmpty(this.getCart.temporaryDelivery)
      ) {
				console.log(this.types[this.getOrderType])
        this.$router.push({ name: this.types[this.getOrderType] });
      } else if (!this.isLoggedIn) {
        this.setRedirectTo("cart");
        this.$root.$children[0].$refs.sidebar.showSideBar();
      } else if (this.$route.name !== "checkout") {
        //this.$router.push({ name: "checkout" });
        this.$root.$children[0].$refs.sidebar.showSideBar();
      } else {
        this.$toasted.global.error({
          message: "Already in checkout page!",
        });
      }
    },
    getOrders() {
      Customer.getOrders(this.getUser.id).then((response) => {
        this.orders = response.data.data;
        this.currentOrders = this.orders
          .filter((order, i) => {
            if (order.attributes["current-status"]) {
              const orderDate = moment(order.attributes["created-at"])
                .add(6, "hours")
                .format("YYYY-MM-DDTHH:mm:ss");

              const orderTime = new Date(this.UTCEN(orderDate.toLocaleString('en-us'))).getTime();
              const now = new Date().getTime();

              return (
                order.attributes["current-status"].type == "open" &&
                orderTime > now
              );
            }
            return false;
          })
          .filter((order, i) => {
            return i <= 1;
          });
        console.log(this.currentOrders)
      });
    },
    orderStatus(id) {
      this.$router.push({ name: "status", params: { id: id } });
    },
    UTC(time) {
      return moment
        .utc(time, "YYYY-MM-DD h:mm:ss")
        .locale(this.getLocale)
        .local()
        .format("YYYY-MM-DDTHH:mm:ss");
    },
    UTCEN(time) {
      return moment
        .utc(time, "YYYY-MM-DD h:mm:ss")
        .locale('en-us')
        .local()
        .format("YYYY-MM-DDTHH:mm:ss");
    }
  },
};
</script>
