<template>
    <div>
    <input inputmode="numeric" pattern="[0-9]*" :maxlength="maxlength" type="text" :placeholder="placeholder" class="form-control" :class="{'is-invalid' : error}" :value="value" @keyup="onKeyUp" @keypress="isNumber($event)" @input="onInput">
    <div class="invalid-feedback" :class="{ 'text-right' : getLocale === 'ar', 'd-block' : error, }">
        {{ message }}
    </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: "BaseInput",
    data(){
        return {
            error: false
        }
    },
    props: {
        message: String,
        maxlength: Number,
        value: String,
        placeholder: String,
    },
    computed: {
        ...mapGetters({
            getLocale: 'app/getLocale',
            getConcept: 'concept/getConcept',
        }),
      dialingCode() {
        return (this.getConcept
            && this.getConcept.attributes
            && this.getConcept.attributes['dialing-code']) || '966';
      },
    },
    methods: {
        onKeyUp(e) {
            if(e.target.value.length == 13)
            {
                this.error = false;
            } else {
                this.error = true;
            }
        },
        isNumber: function (evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            }
        },
        onInput(e) {
          const dialCode = `+${this.dialingCode}`;
          const dialCodeLength = dialCode.length;
          const { value } = e.target;
          let compareLength = 0;

          for (let i = 0; i <= dialCodeLength; i++) {
            if (
                dialCode[i] !== undefined &&
                value[i] !== undefined &&
                dialCode[i] === value[i]
            ) {
              compareLength = compareLength + 1;
            }
          }
          if (compareLength === dialCodeLength) {
            this.$emit('input', value);
          } else {
              this.$forceUpdate();
          }
          // if(e.target.value.length > 3 && e.target.value[0] == '+' && e.target.value[1] == '9' && e.target.value[2] == '6' && e.target.value[3] == '6') {
          //     this.$emit("input", e.target.value);
          // }
        },
        validateInput(value) {
            if(value.length == 13)
            {
                this.error = false;
            } else {
                this.error = true;
            }
        },
        hasError() {
            return this.error;
        }
    }
};
</script>
