import BaseService from '../base.service'
import store from '@/store'

let conceptService = class ConceptService extends BaseService {

    constructor() {
        super();        
    }

    all(page: 1) {
        let url = this.baseURL + '';

        return super.get(url, {
            page: page
        });
    }

    find() {
        let url = this.baseURL + '/concepts/' + this.conceptId;

        return super.get(url)
    }

    default() {
        let url = this.baseURL + '/concepts/' + this.conceptId;

        return super.get(url)
    }

    settings() {
        let url = this.baseURL + '/concepts/' + this.conceptId + '/settings';

        return super.get(url)
    }

    getTapPublicKey()
    {
        let url = this.baseURL + '/integration-options/tap/web_public_key'
        return super.get(url)
    }

    getCheckoutPublicKey(gateway: string)
    {
        let url = this.baseURL + `/integration-options/${gateway}/public_key`
        return super.get(url)
    }

};

export default conceptService