<template>
    <b-row class="mt-1" v-if="modifierGroupData.length && !hidden">
        <b-col md="11" class="offset-md-1 add-ons-container" :style="addOnsWrapperStyle">
            <div class="add-ons-wrapper">
                <label>{{modifierGroup.attributes.name}}</label>                
                <small class="text-danger d-block" v-if="modifierGroup.attributes.minimum > 0">
                    <span v-html="eyeIcon" class="font-weight-bold d-none"></span>{{ modifierGroup.attributes.minimum === 1 ? errorMsg.replace('items', 'item') : errorMsg }}
                </small>
                <modifier
                    ref="modifier"
                    :modifier="filterModifier(modifier.id)[0]"
                    v-for="(modifier, index) in modifierGroupData"
                    :key="index"
                ></modifier>
            </div>
        </b-col>
    </b-row>
</template>
<script>
import Modifier from "./Modifier";
import SizeModifier from "./SizeModifier";
import { TranslationMixin } from "@/mixins"
export default {
    props: ["modifierGroup", "activeSizeId"],
    data() {
        return {
            //modifierGroupData: this.modifierGroup.relationships.modifiers.data,
            modifierGroupData: [
                ...this.getModifiers()
            ],
            freeModifierCount: 0,
            showError: 0,
            eyeIcon: '<i class="fa fa-exclamation" aria-hidden="true"></i> ',
            errorMsg: this.translation('select_modifier_msg', 'Please select up to MINIMUM_QTY items').replace('MINIMUM_QTY', this.modifierGroup.attributes.minimum),
            hidden: false
        };
    },
    components: { Modifier, SizeModifier },
    computed: {
        addOnsWrapperStyle() {
            return {
                "--main-color": this.hexToRgbA(this.$root.mainColor)
            };
        }
    },
    mixins: [TranslationMixin],
    watch: {
        activeSizeId: {
            handler(val) {                
                if(this.modifierGroup.attributes?.['visibility-config']?.rules?.[0]?.['target-id']) {
                    if(this.modifierGroup.attributes['visibility-config'].rules[0]['target-id'] == this.activeSizeId) {
                        this.hidden = false
                    } else {
                        this.hidden = true
                    }
                }                
            },
            immediate: true            
        }
    },
    mounted() {        
        
    },
    methods: {
        hexToRgbA(hex) {
            var c;
            if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
                c = hex.substring(1).split("");
                if (c.length == 3) {
                    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
                }
                c = "0x" + c.join("");
                return (
                    "rgba(" +
                    [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
                    ",0.23)"
                );
            }            
        },
        filterModifier(id) {
            return this.$parent.modifiers.included.filter(data => {
                return data.id === id;
            });
        },
        getModifiers()
        {
            if(this.modifierGroup.relationships.hasOwnProperty('modifiers'))
            {
                return this.modifierGroup.relationships.modifiers.data.sort((a, b) => {                
                    return this.filterModifier(a.id)[0].attributes['display-order'] - this.filterModifier(b.id)[0].attributes['display-order']
                })
            }
            return []            
        },
    }
};
</script>