<template>
    <b-col md="12" id="item-page" class="p-0">
        <Header></Header>
        <div class="details" v-if="item.hasOwnProperty('attributes')">
            <h5 class="item-name">{{item.attributes.name}}</h5>
            <div class="item-price-hd">
                <!-- <small>{{item.attributes['calorie-count']}} {{translation('kcal', 'Kcal')}}</small> -->
                <small v-if="item.attributes['calories'] && item.attributes['calories'] != ''">{{item.attributes['calories']}} {{translation('kcal', 'Kcal')}}</small>
                <small v-if="item.attributes.price" :style="priceStyle" class="item-price text-primary font-weight-bold">{{ translateNumber(item.attributes.price) }} {{translation(currency.key, currency.value)}}</small>
            </div>
            <p class="mb-0 item-desc"><small>{{item.attributes.description}}</small></p>
            <div class="allergen-container" v-if="item.relationships">
                <b-row class="mt-1">
                    <b-col md="12" v-if="allergens.length > 0">
                        <div class="alergen-holder px-0">
                            <label>{{translation('contains', 'Contains')}}</label>
                            <ul>
                                <li
                                    class="allergen"
                                    v-for="(allergen, index) in allergens"
                                    :key="index"
                                >
                                    <img
                                        width="30"
                                        :src="displayAllergen(allergen, 'icon')"
                                        :key="index"
                                        alt
                                    />
                                    <small>{{ displayAllergen(allergen, 'name') }}</small>
                                </li>
                            </ul>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </div>
        <div class="wrapper">
            <SpiceLevel v-show="false" ref="spice"></SpiceLevel>
            <Ingredients ref="ingredients" :ingredients="ingredients.filter(ingredient => { return ingredient.attributes.enabled })"></Ingredients>
            <Modifiers ref="modifiers" :modifiers="modifiers"></Modifiers>
        </div>
        <AddToCart ref="Addtocart"></AddToCart>
    </b-col>
</template>
<style lang="scss" scoped>
    .details{
        padding: 1.5rem;
        position: relative;
        .item-name {
            font-size: 20px;
            font-weight: 900;
            padding-right: 80px;
            padding-left: 0;
        }
        .item-price-hd{
            text-align: left;
            .item-price{
                position: absolute;
                top: 20px;
                right: 20px;
                left: unset;
            }
        }
    }
    body.rtl{
        .item-name {
            padding-left: 80px;
            padding-right: 0;
        }
        .item-price-hd{
            text-align: right;
            .item-price{
                top: 20px;
                right: unset;
                left: 20px;
            }
        }
    }
</style>
<script>
import Header from "./components/Header";
import SpiceLevel from "./components/SpiceLevel";
import Modifiers from "./components/Modifiers";
import OrderType from "./components/OrderType";
import AddToCart from "./components/AddToCart";
import { Item } from '@/services/SOLO';
import { mapGetters } from 'vuex';
import { eventHub } from '@/assets/plugins/events';
import { TranslationMixin } from '@/mixins'
import Ingredients from './components/Ingredients'

export default {
    data() {
        return {
            item: {},
            spicyLevel: this.spiceNum,
            modifiers: [],
            included: [],
            ingredients: [],
          allergens: [],
        };
    },
    computed: {
        spiceNum() {
            return this.$refs.spice.value;
        },
        priceStyle() {
            return {
                color: this.$root.mainColor + '!important',
                fontSize: '20px'
            }
        },
        ...mapGetters({
            getConcept: 'concept/getConcept',
            getSettings: 'app/getSettings',
            getCategories: 'app/getCategories',
            currency: 'concept/currency',
          getAllergens: 'app/getAllergens',
          getLocale: 'app/getLocale',
		    }),
    },
    mixins: [TranslationMixin],
    components: { Header, SpiceLevel, Ingredients, Modifiers, OrderType, AddToCart },
    mounted() {
        if(this.getSettings.hasOwnProperty('attributes'))
        {
            this.$parent.$refs.sidebar.$refs.cart.switchComponent("Cart");
            this.findItem()
            eventHub.$once('change-lang', () => {
                this.$parent.$refs.sidebar.$refs.cart.switchComponent("Cart");
                this.findItem()
            })
        }else
        {
            eventHub.$on('app-ready', () => {
                this.$parent.$refs.sidebar.$refs.cart.switchComponent("Cart");
                this.findItem()
            })
        }

    },
    methods: {
        getItem(categoryId, itemId) {
            let category = this.getCategories.filter(category => {
                return Number(category.id) === Number(categoryId)
            })

            if(category.length) {
                let item = category[0].attributes.items.filter(item => {
                    return Number(item.id) === Number(itemId)
                })

                if(item.length) {
                    return {'excluded-locations': item[0]['excluded-locations']}
                }

                return {'excluded-locations': []}
            }

            return {'excluded-locations': []}

        },
      displayAllergen(allergen, key) {
          if (this.getLocale === 'en') {
            return allergen.attributes[key]['en-us'];
          } else if (this.getLocale === 'ar') {
            return allergen.attributes[key]['ar-sa'];
          }
      },
        findItem() {
          const categoryId = Number(this.$route.params.categoryId.split("-").pop());
          const itemId = Number(this.$route.params.id.split("-").pop());
          const category = this.getCategories.find((category) => category.id === categoryId);
          const item = category.attributes.items.find((item) => item.id === itemId);
          // TODO: remove this one and use CDN
          const params = {
            menuId: this.getSettings.attributes['default-menu-id'],
            categoryId,
            itemId,
          };

          this.allergens = this.getAllergens.filter((allergen) => item.allergens.find((al) => al === allergen.id));

          // TODO: remove this one and use CDN
          Item.find(params, "category,allergen,ingredients")
              .then(response => {
                const data = response.data.data
                data.quantity = 1
                this.included = response.data
                this.item = data
                this.getModifiers(params)
                this.setIngredients()
              })
              .catch(err => {
                console.error(err)
              })
        },
        setIngredients()
        {
            this.ingredients = this.included.included.filter(item => { return item.type == 'ingredient' && item.attributes['minimum-quantity'] != 1})
        },
        getModifiers(params)
        {
            if(!this.item.attributes['is-combo'])
            {
                Item.fetchModifiers(params)
                .then(response => {
                    this.modifiers = response.data
                })
                .catch(err => {
                    console.error(err)
                })
            }else
            {
                Item.fetchComboMealsModifiers(params)
                .then(response => {
                    this.modifiers = response.data
                })
                .catch(err => {
                    console.error(err)
                })
            }
        },
        getComponents()
        {

        },
        addToCart()
        {

        },
        setAllergen(included, icon) {
            return included.included.filter(data => {
                return Number(data.id) === Number(icon.id);
            })[0].attributes;
        },
    }
};
</script>
