let dataLayer = {
  methods: {
    setDataLayer(routeName: string, lang: string, name: string, qty: any){
      let ifLayerExist = document.getElementById('data-layer')
      let prodQty = ''
      if(qty) {
        prodQty = `quantity: ${qty}`
      } 

      if(!ifLayerExist) {
          let dataLayer = document.createElement('script')
          dataLayer.setAttribute("id", "data-layer");            
          dataLayer.innerHTML = `
          dataLayer[{ 
              pageName: ${routeName},
              language: ${lang},
              ${name},
              ${prodQty}
          }]`
          document.head.appendChild(dataLayer)                                                    
      } else {
          ifLayerExist.innerHTML = `
          dataLayer[{ 
              pageName: "${routeName}",
              language: "${lang}",
              ${name},
              ${prodQty}
          }]`
      }            
    },
  }
};

export default dataLayer
