<template>
    <b-container :style="rowHeight">
        <noscript v-if="getSettings.hasOwnProperty('attributes')">
            <iframe :src="`https://www.googletagmanager.com/ns.html?id=${gtmId}`"
                height="0"
                width="0"
                style="display:none;visibility:hidden">            
            </iframe>
            <img height="1" width="1" v-if="getSettings.attributes['fb-pixel-id']" style="display:none" 
                :src="`https://www.facebook.com/tr?id=${getSettings.attributes['fb-pixel-id']}&ev=PageView&noscript=1`"/>
        </noscript>        
        <b-row :style="rowHeight">
            <router-view :key="$route.fullPath"></router-view>
            <side-bar ref="sidebar" v-show="$root.sidebarOpen"></side-bar>
        </b-row>
        <ToogleMenu v-if="getConcept.attributes && $route.name != 'tap-payment' && $route.name != 'checkout-payment' && $route.name != 'redirect-tap-payment' && $route.name != 'checkout-payment' && $route.name != 'item'"></ToogleMenu>
        <main-otp ref="modalOtp"></main-otp>
    </b-container>
</template>
<style lang="scss">
@import "./assets/sass/app.scss";
</style>
<script>

import { mapGetters } from 'vuex';
export default {
    name: 'main-container',
    computed: {
        ...mapGetters({
            getLocale: 'app/getLocale',
            getSettings: 'app/getSettings',
            getConcept: 'concept/getConcept',
        }),
        gtmId() {
            if(this.getSettings.hasOwnProperty('attributes'))
            {
                return this.getSettings.attributes['gtm-id']   
            }
            return null
        },
        rowHeight() {
            return {
                height: this.$root.rootRowHeight ? "100%" : "auto"
            };
        },
    },
    watch: {
        getSettings(data) {
            if(data.attributes['fb-pixel-id']) {
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', data.attributes['fb-pixel-id']);
                fbq('track', 'PageView');
            }
        }
    },
    mounted() {        
    }   
};
</script>