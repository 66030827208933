<template>    
    <b-col md="12" id="profile-page" class="p-0">        
        <div class="wrapper">
            <Header></Header>            
            <Form></Form>
        </div>                
    </b-col>
</template>

<script>
import Header from './components/Header'
import Form from './components/Form'
export default {
    components: { Header, Form }
}
</script>