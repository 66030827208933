import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import GmapCluster from 'vue2-google-maps/dist/components/cluster'

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCEZ1drHXB2GebXzd_K6pwezvsLlooO4ac',
        libraries: 'places'
    },    
})

Vue.component('GmapCluster', GmapCluster)