<template>
    <div>
        <b-card
            class="text-muted modifier-content"
            :style="checkedStyle"
            :class="{ 'selected' : checked && $parent.modifierGroup.attributes.maximum === 1, 'selected' : checked && modifier.attributes.maximum === 1 }"
            @click="toogleModifier"
        >
            <img
                :src="modifier.attributes['image-uri']"
                v-if="checkIfImageIsAttributes()"
                height="40"
                width="40"
                alt
            />
            <span v-if="checkIfImageIsAttributes" class="space"></span>
            <div class="text" ref="name" :style="{ color: quantity ? '#fff' : '#6c757d' }" v-html="modifier.attributes.name"></div>
            <div class="side-content">
                <small v-if="price">{{translateNumber(price)}} {{translation(currency.key, currency.value)}}</small>
            </div>
        </b-card>
    </div>
</template>
<style lang="scss" scoped>
    #item-page .wrapper .add-ons .add-ons-wrapper .modifier-content .text {
        width: calc(100% - 55px);
    }
    #item-page .wrapper .add-ons .add-ons-wrapper .card .card-body {
        padding-right: 50px !important;
    }
</style>
<script>
import { TranslationMixin } from "@/mixins";
import { Item } from '@/services/SOLO'
import { mapGetters } from 'vuex';
import { eventHub } from '../../../assets/plugins/events';

export default {
    props: ["modifier", "index"],
    data() {
        return {
            quantity: 0,
            checked: false,
            selected: 0,
            childs: [],
            included: [],
            numOfFree: 0
        };
    },
    mixins: [TranslationMixin],
    watch: {
        quantity(data)
        {
            this.$parent.$parent.$parent.$refs.Addtocart.modifierPrice = this.$parent.$parent.$parent.$refs.Addtocart.setTotalComboMealsModifierPrice();
        },
        checked(data, old)
        {
            if(this.modifier.type === 'combo-items')
            {
                if(!old)
                {
                    let defaultText = this.$refs.name.innerHTML
                    this.$refs.name.innerHTML =  '<i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Loading ...'
                    let params = {
                        menuId: this.getConcept.attributes['default-menu-id'],
                        categoryId: this.$route.params.categoryId.split("-").pop(),
                        itemId: this.modifier.attributes['item-id']
                    };

                    Item.fetchModifiers(params).then(response => {
                        this.$refs.name.innerHTML = defaultText

                        this.childs = response.data.data
                        this.included = response.data.included
                        this.quantity = 1;
                        this.$parent.$refs.modifier.map(ref =>
                        {
                            if (ref.modifier.id != this.modifier.id)
                            {
                                ref.checked = false;
                                ref.quantity = 0;
                            }
                        });
                        if(response.data.data.length)
                        {
                            eventHub.$emit('additionals')
                        }
                    })


                }else
                {
                    this.childs = []
                    this.included = []
                }
            }else
            {
                if(!old)
                {
                    if(this.$parent.modifierGroup.attributes.maximum === 1)
                    {
                        this.quantity = 1;
                        this.$parent.$refs.modifier.map(ref =>
                        {
                            if (ref.modifier.id != this.modifier.id)
                            {
                                ref.checked = false;
                                ref.quantity = 0;
                            }
                        });
                    }else
                    if(this.getSelected < this.$parent.modifierGroup.attributes.maximum){
                        this.quantity = 1;
                    }
                }else
                {
                    this.checked = false
                    this.quantity = 0
                }
            }

        }
    },
    computed: {
        ...mapGetters({
            getConcept: 'concept/getConcept',
            getSettings: 'app/getSettings',
            currency: 'concept/currency',
        }),
        getSelected()
        {
            return this.$parent.$refs.modifier.map(ref => {
                return ref.quantity
            }).reduce((a,b) => { return a + b });
        },
        price()
        {
            if(this.modifier.type != 'combo-items')
            {
                return this.modifier.attributes.price
            }else
            {
                if(this.$parent.$parent.$parent.$refs.modifiers.sizeModifier.length > 1)
                {
                    return this.modifier.attributes.sizes.filter(size => { return size['meal-size-id'] === this.$parent.$parent.$parent.$refs.modifiers.$refs.sizeModifier.getActive()['meal-size-id'] })[0].price
                }
                return this.modifier.attributes.sizes[0].price
            }
            return 0
        },
        checkedStyle()
        {
            if (this.quantity)
            {
                return {
                    background: this.$root.mainColor,
                    "--main-color": this.$root.mainColor,
                    color: '#fff'
                };
            }

            return {};
        },
        buttonTextStyle()
        {
            return {
                color: this.$root.mainColor
            }
        }
    },
    mounted()
    {

        if(!this.index && this.modifier.type === 'combo-items')
        {
            this.toogleModifier()
        }
    },
    methods: {
        checkIfImageIsAttributes()
        {
            if(this.modifier.attributes['image-uri'])
            {
                return !this.modifier.attributes['image-uri'].includes('default')
            }
            return false
        },
        toogleModifier()
        {
            if(this.modifier.type === 'combo-items')
            {
                if(!this.checked)
                {
                    this.checked = true
                }
            }else
            {
                this.checked = this.checked ? false : true;
            }
        }
    }

}
</script>
