<template>
    <b-container class="text-center transaction-details">        
        <div v-if="getTapPaymentStatus == 'processing'">
            <div class="content" v-if="getTapPaymentResponse == null">
                <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>  {{translation('verify_payment_transaction', 'Verifying Payment Transaction ...')}} 
            </div>
            <div class="content" v-if="getTapPaymentResponse != null">
                <template v-if="getTapPaymentResponse.callback.status === 'DECLINED'">
                    <h3 class="title" :style="titleStyle"> {{translation('transaction_failed', 'Transaction Failed')}} </h3>
                    <button class="btn btn-lg" :style="btnStyle" @click="proceedToCheckout">
                        {{translation('proceed_with_cash', 'Proceed with Cash payment')}}
                    </button>
                    <button class="btn btn-lg" :style="btnStyle" @click="cancelTransaction">
                        {{translation('cancel_transaction', 'Cancel Transaction')}}
                    </button>
                </template>
            </div>
        </div>
        <div v-else>
            <div class="content">
                <h3 class="title">No active transaction</h3>
                <button class="btn btn-lg" :style="btnStyle" @click="toHome">
                    {{translation('go_to_home', 'Go back to homepage')}}
                </button>
            </div>
        </div>        
    </b-container>
</template>
<style lang="scss" scoped>
    .transaction-details{
        height: 100%;
        vertical-align: middle;
        margin-top: 200px; 
        .content {
            .title{
                color: var(--main-color);
                margin-bottom: 20px;
            }
            .btn {
                border: 1px solid var(--main-color);
                color: var(--main-color);
                width: 90%;
                margin-bottom: 20px;
            }
        }       
    }
</style>
<script>
import { mapMutations, mapGetters } from 'vuex';
import { Customer, Order } from '@/services/SOLO'
import { TranslationMixin } from '@/mixins'
export default {
    data()
    {
        return {
            response: null,
            isLoading: true,
            status: true,
            btnStyle: {
				'--main-color': this.$root.mainColor
            },
            titleStyle: {
                '--main-color': this.$root.mainColor
            }
        }
    },
    mixins: [TranslationMixin],
    computed: {
        ...mapGetters({
            getTapPaymentResponse: 'app/getTapPaymentResponse',
            getTapOrderID: 'app/getTapOrderID',
            getTapOrderData: 'app/getTapOrderData',
            getTapPaymentStatus: 'app/getTapPaymentStatus'
        })        
    },    
    mounted()
    {                        
        goSell.showResult({
            callback: response => {
                console.log(response)
                this.response = response
                this.setTapPaymentResponse(response)
                this.verifyCharges(this.getTapPaymentResponse.callback.status)     
            }
        });

    },
    methods: {
        ...mapMutations({
            setTapPaymentResponse: 'app/setTapPaymentResponse',
            setTapPaymentStatus: 'app/setTapPaymentStatus',
            emptyCart: 'cart/emptyCart',
            setCoupon: "cart/setCoupon",
            setCouponResult: "cart/setCouponResult",
            resetCoupon: "cart/resetCoupon"
        }),
        toHome()
        {
            this.setTapPaymentResponse(null)
            this.setTapPaymentStatus(null)
            this.$router.push({name: 'home'})            
        },
        verifyCharges(status)
        {
            let params = {
                "order-id": this.getTapOrderID,
                "charge-id": this.getTapPaymentResponse.callback.id
            }            

            Customer.tapVerification(params).then(response => {                
                this.isLoading = false
                if(status != 'DECLINED')
                {                    
                    this.setTapPaymentResponse(null)
                    this.setTapPaymentStatus(null)
                    this.emptyCart()
                    this.setCoupon('')
                    this.setCouponResult({})
                    this.resetCoupon()
                    this.status = true                  
                    this.$router.push({ name: 'status', params: { id: response.data.data.id } })  
                }else
                {
                    this.emptyCart()
                    this.setCoupon('')
                    this.setCouponResult({})
                    this.resetCoupon()
                    this.status = false
                }
            }).catch((err) => {
                // this.emptyCart()
                // this.setCoupon('')
                // this.setCouponResult({})
            })
        },
        proceedToCheckout()
        {            
            let params = this.getTapOrderData            
            Order.store(params).then(response => {
                this.setTapPaymentResponse(null) 
                this.setTapPaymentStatus(null)
                this.emptyCart()
                this.$toasted.global.info({
                    message: 'Checkout order successful!'
                })
                this.$router.push({name: 'status', params: {id: response.data.data.id}})                                                
                
            }).catch(err => {                
                this.$toasted.global.error({
                    message: err.response.data.error[0].detail
                })
            })
        },
        cancelTransaction()
        {
            this.$router.push({name: 'home'})
            this.setTapPaymentResponse(null)
            this.setTapPaymentStatus(null)            
        }
    }
}
</script>