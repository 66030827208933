<template>
	<section class="cart" v-if="getConcept">
		<div v-if="getConcept.attributes">
			<LangSwitch v-if="getConcept.attributes.languages.length > 1"></LangSwitch>
		</div>
		<b-row class="mt-2">
			<b-col md="12">
				<ul class="top-nav nav">					
					<li v-if="isLoggedIn" class="settings-icon active" @click="switchComponent('Profile')" :style="activeTab">												
						<i class="fa fas fa-user-circle"></i>					
					</li>
				</ul>
			</b-col>
		</b-row>
		<b-row v-show="showCart">
			<b-col md="12">
				<b-card class="bg-none border-0" id="main" v-if="getConcept.attributes">
					<div ref="wrapper" :show="show" :is="current" class="cart-wrapper"></div>
				</b-card>
			</b-col>			
		</b-row>

		<template>
			
			<Map v-show="showStoreLocation" ref="map" class="store-location-map"></Map>

			<store-location-list v-show="showStoreList"></store-location-list>

			<addresses v-if="$route.name === 'delivery-location'"></addresses>

		</template>

	</section>
</template>
<style lang="scss" scoped>
	.nav.top-nav {
		min-height: 50px;
		.settings-icon i{
			font-size: 45px;
		}
	}	
</style>
<script>
import Notification from './cart-components/Notification'
import Cart from './cart-components/Cart'
import Profile from './profile-components/Profile'
import Map from './locations/Map'
import StoreLocationList from './cart-components/StoreLocationList'
import Addresses from './cart-components/Addresses'
import { mapGetters } from 'vuex'
import { TranslationMixin } from '@/mixins'
import LangSwitch from '@/layouts/components/selector/LangSwitch'
import OrderHistory from './profile-components/OrderHistory'
export default {
	data()
	{
		return {
			current: 'Cart',
			showAddresses: false
		}
	},
	props: ['show'],
	mixins: [TranslationMixin],
	components: {
		Notification,
		Cart,
		Map,
		StoreLocationList,
		Addresses,
		Profile,
		LangSwitch
	},
	computed: {
		...mapGetters({
			getCart: 'cart/getCart',
			getPagesRules: 'app/getPagesRules',
			isLoggedIn: 'account/isLoggedIn',
			getConcept: 'concept/getConcept',
			getUserAddresses: 'account/getUserAddresses'
		}),
		activeTab()
		{
			return {
				color: this.$root.mainColor
			}
		},
		showCart()
		{
			return !this.getPagesRules.notification.unallowedRouteNames.includes(this.$route.name)
		},
		showStoreList()
		{
			return this.getPagesRules.pickupLocation.allowedRouteNames.includes(this.$route.name)
		},
		showStoreLocation()
		{
			return (this.getCart.type === 'pickup' || this.getCart.type === 'eat-in') && this.getPagesRules.location.allowedRouteNames.includes(this.$route.name)
		}
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			// access to component instance via `vm`
			if (vm.$store.getters['concept/getConcept'].attributes['menu-only']) {
				next('/');
			} else {
				next();
			}
		});
	},
	watch: {
		show(data) {
			if(data) {
				this.switchComponent('Cart')
			}
		}
	},
	mounted()
	{

	},
	methods: {
		switchComponent(component)
		{
			this.current = component
		},

	}
}
</script>
