<template>
    <b-button-group size="sm" class="switch-btn">
        <b-button            
            variant="white"
            :style="{...ActiveStyle(index)}"
            :class="{ 'active': $parent.activeIdx === index }"
            v-for="(btn, index) in buttons"
                        
            :key="index"
            @click="setActive(index)"
        >
            {{btn.text}}
        </b-button>
    </b-button-group>
</template>
<style lang="scss" scoped>
    .btn-group {
        margin: 40px auto 0;
        display: block;
        text-align: center;
        direction: ltr !important;
        &.switch-btn {
            height: 40px;
            width: 190px;
            background: #FFFFFF;
            box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.1);
            border-radius: 100px;
            padding: 5px;
            margin-bottom: 20px;
            .btn {
                height: 30px;
                width: 90px;
                color: #B5B5B5;
                &.active {
                    background: #FFFFFF;
                    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
                    border-radius: 100px;
                }
            }
        }
    }
</style>
<script>
import { TranslationMixin } from '@/mixins'
import { mapGetters, mapMutations } from 'vuex';
export default {
    data()
    {
        return {            
            buttons: [
                { text: this.translation('map_view_label', 'Map view'), state: false },
                { text: this.translation('list_view_label', 'List view'), state: false }
            ]
        }
    },
    mixins: [TranslationMixin],
    computed: {
        ...mapGetters({
            getBranches: 'app/getBranches',
        }),
        btnBackground()
        {
            return {
                background: '#fff',
                backgroundColor: '#fff',
                border: '1px solid ' + this.$root.mainColor
            }
        },
        activeTab()
        {
            return localStorage.getItem('activeTab') ? localStorage.getItem('activeTab') : 0;
        }     
    },
    mounted()
    {
        this.setActive(1)
    },
    methods: {
        setActive(index)
        {   
            localStorage.setItem('activeTab', index);  
            this.$parent.activeIdx = index          
        },
        ActiveStyle(index)
        {

            if(this.$parent.activeIdx == index)
            {
                return {                    
                    color: this.$root.mainColor
                } 
            }

            return {

            }
        },       
    }
}
</script>