import BaseService from '../base.service'
import store from '@/store'

let locationService = class LocationService extends BaseService {

    constructor() {
        super();
    }

    fetchLocations()
    {
        let url = this.baseURL + '/locations?limit=999';

        return super.get(url)
    }

    validateLocation(lat: string | number, lng: string | number)
    {

        let url = this.baseURL + '/locations?filter[delivery-area.lat]='+ lat +'&filter[delivery-area.long]='+ lng

        return super.get(url)
    }

    fetchNearestLocations(lat: string | number, lng: string | number)
    {
        let url = this.baseURL + '/locations?_lat='+ lat +'&_long='+ lng;

        return super.get(url)
    }

    fetchInactiveCategories(id: string | number) {
        let url = this.baseURL + `/locations/${id}/inactive-categories`;

        return super.get(url)
    }
};

export default locationService
