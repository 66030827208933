<template>
	<section class="delivery-description">
		<ul>
			<li>
				<img src="/images/icons/map-pin@2x-6.png" class="mr-4" alt="" width="15"> 3.5 km away
			</li>
			<li>
				<img src="/images/icons/clock@2x.png" class="ml-4 mr-4" alt="" width="15"> 35 minutes delivery time
			</li>
		</ul>
	</section>
</template>
<script>
export default {
	name: "DeliveryDescription"
}
</script>