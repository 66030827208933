<template>
    <b-row>
        <b-col md="12">
            <b-card class="bg-none border-0" id="main">
                <div class="store-list-wrapper pr-0">
                    <div class="list-content">
                        <b-card v-for="(branch, i) in getBranches" :key="i">
                            <div class="logo-holder">
                                <img v-if="getConcept.hasOwnProperty('attributes')" :src="getConcept.attributes['logo-uri']" height="30" alt="">
                            </div>
                            <div class="content-holder">
                                <div class="title">
                                <span>{{branch.attributes.name}}</span>
                                <div v-if="branch.attributes.distance"><i class="fa fa-map-marker"></i> {{ branch.attributes.distance / 1000}} {{ translation('distance_in_km') }}</div> 
                                <div class="mobile" v-if="branch.attributes.telephone !== null">
                                    <img src="/images/icons/call.png" alt="" v-if="getLocale === 'en'"> {{branch.attributes.telephone}} <img src="/images/icons/call.png" alt="" v-if="getLocale === 'ar'">
                                </div>
                                <div class="hours">
                                    <img src="/images/icons/query_builder.png" alt=""> {{openTime(branch)}} - {{closeTime(branch)}}
                                </div>
                            </div>
                            </div>
                            <template v-slot:footer>
                                <button :style="branchBtnStyle" class="text-center" @click="setDefault(branch)">{{translation('select_this_branch')}}</button>
                            </template>
                        </b-card>
                    </div>
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>
<style scoped>
    .mobile {
        direction: ltr !important;
    }
</style>
<script>
import { TranslationMixin } from "../../../mixins";
import { mapGetters, mapMutations } from 'vuex';
import moment from 'moment'
import { eventHub } from '@/assets/plugins/events'
import { Location } from '@/services/SOLO'
export default {
    data()
    {
        return {
            moment: moment(),
            types: {
                'dinein-location': 'eat-in',
                'pickup-location': 'pickup'
            }
        }
    },
    mixins: [TranslationMixin],
    computed: {
        ...mapGetters({
            getBranches: 'app/getBranches',
            getOrderType: 'cart/getOrderType',
            getConcept: 'concept/getConcept',
            getRedirectTo: 'cart/getRedirectTo',
            getCategoryItems: 'concept/getCategoryItems',
            getLastLocation: 'cart/getLastLocation',
            hasDefault: 'cart/hasDefault',
            getSettings: 'app/getSettings',
            getLocale: 'app/getLocale'
        }),
        branchBtnStyle()
        {
            return {
                background: this.$root.mainColor
            }
        }
    },
    mounted()
    {
        console.log(this.dayNumNow())
        if (this.getBranches.length == 1 && !this.hasDefault && !this.getSettings.attributes['force-location']) {
            // this.setOrderType(this.$route.meta.type)
            // this.setLocation(this.getBranches[0]);
        }
    },
    methods: {
        ...mapMutations({
            setOrderType: 'cart/setOrderType',
            setLocation: 'cart/setLocation',
            setRedirectTo: 'cart/setRedirectTo',
            disableForceLocation: 'app/disableForceLocation',
            setBranches: 'app/setBranches',
            setInactiveCategories: 'concept/setInactiveCategories',
        }),
        updateStoreLocations() {

        },
        setDefault(branch)
        {

            if(!branch.attributes['is-open'])
            {
                return this.$toasted.global.error({
                    message: this.translation('store_is_closed')
                })
            }
            // TODO: move to actions
            Location.fetchInactiveCategories(branch.id)
                .then((response) => {
                    this.setInactiveCategories(response.data.data);
                })
                .catch(console.log);
            this.setOrderType(this.$route.meta.type);
            this.setLocation(branch);
            this.disableForceLocation(0)
            if (this.getRedirectTo === 'checkout') {
                this.setRedirectTo(null);
                this.$router.push({name: 'checkout'})
            } else  {
                if (this.getRedirectTo === 'cart') {
                    setTimeout(() => {
                        this.$root.$children[0].$refs.sidebar.showSideBar();
                    }, 100)
                }
                this.$router.push({name: 'home'})
            }
            this.$toasted.global.info({
                message: branch.attributes.name + ' ' + this.translation('has_set_location', 'has been set as location')
            })
        },
        dayNumNow()
        {
            return moment().locale(this.getLocale).day()
        },
        openTime(branch)
        {                                    
            if(branch.attributes['opening-hours-utc']) {                
                return moment.utc(branch.attributes['opening-hours-utc'][this.dayNumNow()].open, 'hh:mm').local().format('hh:mm A')
            } else if(branch.attributes['opening-hours-pickup']) {                
                return moment.utc(branch.attributes['opening-hours-pickup'][this.dayNumNow()].open, 'hh:mm').local().format('hh:mm A')
            }                        
        },
        closeTime(branch)
        {                        
            if(branch.attributes['opening-hours-utc']) {                
                return moment.utc(branch.attributes['opening-hours-utc'][this.dayNumNow()].closed, 'hh:mm').local().format('hh:mm A')
            } else if (branch.attributes['opening-hours-pickup']) {                
                return moment.utc(branch.attributes['opening-hours-pickup'][this.dayNumNow()].closed, 'hh:mm').local().format('hh:mm A')
            }            
        }
    }
};
</script>
