<template>
  <section class="header-wrapper" v-if="getConcept.hasOwnProperty('attributes')">
		<section class="header-thumbnail pr-4" :class="{'row': !$parent.isMoble}">		
			<div v-show="false" :class="{'col-12': !$parent.isMoble}" v-if="!$parent.isMoble"> 
				<b-row>
					<div class="col-11 col-md-11 offset-1 offset-md-1 card-item-description">
						<b-card class="special-box">
							<img :src="getConcept.attributes['logo-uri']" height="60" class="mb-2" alt="menu-icon">
							<span class="concept-name">{{ getConcept.attributes['label'] }}</span>
						</b-card>
					</div>
				</b-row>
			</div>
			<ul>
				<li v-show="false">
					<input type="text" class="form-control" placeholder="Search restaurants">
				</li>
			</ul>
		</section>
	</section>	
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'Header',
	computed: {
		...mapGetters({
			getConcept: 'concept/getConcept'
		})
	},
	mounted() {
		console.log(this.getConcept.hasOwnProperty('attributes'))
	}
}

</script>

<style lang="scss" scoped>
	.concept-name {
		margin-left: 16px;
	}	
	.special-box{
		margin-top: -40px;
		z-index: 999;
		border: 1px solid #f5f5f5;
		.card-body {
			padding: 0.50rem 0.25rem 0.25rem 2rem;
		}
	}
</style>
