<template>
    <section class="header-wrapper">
        <h3>
            <span class="header-back-content" @click="backToItem">
                <img src="/images/icons/Back.svg" alt />
            </span>{{translation('order_status', 'Order Status')}}
        </h3>
    </section>
</template>
<script>
import { TranslationMixin } from "@/mixins";

export default {
    mixins: [TranslationMixin],
    methods: {
        backToItem() {
            this.$router.push({name: 'home'});
            this.$root.sidebarOpen = true
            this.$root.sidebarContainerRow = 4
            this.$root.rootRowHeight = '100%'
        }
    }
};
</script>