<template>
    <div class="container">        
        <h5 class="font-weight-bold">{{translation('my_cart', 'My Cart')}}</h5>

        <small class="text-muted" v-if="!getItems.length">{{translation('you_havent_add_anything', "You haven't add anything")}}</small>
        <CartItems @changeqty="applyPromo()" :qtyIsDisabled="qtyIsDisabled" />
        <div v-if="isLoggedIn">
            <p v-if="getStatus === 'verified'" style="color: green;margin-bottom: 0;font-size: 12px;"><i class="fa fa-check"></i> {{translation('mobile_number_is_verified', 'Mobile number is verified')}}.</p>
            <p v-else style="color: rgb(233, 35, 35);margin-bottom: 0;font-size: 12px;"><i class="fa fa-times"></i> {{translation('mobile_number_not_verified', 'Mobile number not verified')}}.</p>
        </div>
        <change-location :home="false"></change-location>
        <CartPayment ref="payment" @promoApplied="enableQtyBtn"/>
    </div>
</template>
<script>
import CartItems from "./CartItems";
import CartPayment from "./CartPayment";
import ChangeLocation from './ChangeLocation'
import { mapGetters } from 'vuex';
import { TranslationMixin } from "@/mixins";
export default {
    data() {
        return {
            qtyIsDisabled: false
        };
    },
    props: {
        show: {
            default: false,
            type: Boolean
        }
    },
    watch: {
        show: {
            handler(data) {
                if(data) {
                    this.applyPromo()
                }
            },
            immediate: true
        }
    },
    computed: {
        ...mapGetters({
            getItems: 'cart/getItems',
            getConcept: 'concept/getConcept',
            getSettings: 'app/getSettings',
            isLoggedIn: "account/isLoggedIn",
            getStatus: "account/getStatus",
        }),
        isMoble()
		{
			return screen.width < 768 ? true : false
		}
    },    
    components: {
        CartItems,
        CartPayment,
        ChangeLocation      
    },
    mixins: [TranslationMixin],
    methods: {
        applyPromo() {
            this.qtyIsDisabled = true
            this.$refs.payment.applyPromo()
        },
        enableQtyBtn() {
            this.qtyIsDisabled = false            
        }
    }
};
</script>
