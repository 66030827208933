export default {
    setConcept(state: { concept: any; }, data: any)
    {
        state.concept = data;
    },

    setSetings(state: { concept: { settings: any; }; }, data: any)
    {
        state.concept.settings = data
    },

    setCategoryItems(state: any, data: any) {
        if (data && data.hasOwnProperty('id')) {
            state.categoryItems[data.id] = [...data.items]
        } else {
            state.categoryItems = data || {};
        }
    },

    resetCategoryItems(state: any, data: any) {
        state.categoryItems = data || {};
    },

    setInactiveCategories(state: any, data: any) {
        state.inactiveCategories = data || [];
    }
};
