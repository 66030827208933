import BaseService from '../base.service'
import store from '@/store'
export default class ItemService extends BaseService {

    appendURI: string = '/menus/:menuId/categories/:categoryId/items'

    constructor() {
        super();                
    }

    all(params: any, page: number = 1) {           
        let url = super.prepareUrl(this.baseURL + this.appendURI, params);

        return super.get(url, {
            page: page,
            include: 'allergen,itemModifierGroups,locations',
            limit: 999,
        });
    }

    find(params: any, includes: string = 'category,ingredients') {        
        let url = super.prepareUrl(this.baseURL + this.appendURI + '/:itemId?include=' + includes, params);        

        return super.get(url)
    }

    fetchModifiers(params: any) {        
        let url = super.prepareUrl(this.baseURL + this.appendURI + '/:itemId/modifier-groups?include=', params);

        return super.get(url)
    }
    
    fetchComboMealsModifiers(params: any)
    {           
        let URL = super.prepareUrl(this.baseURL + '/menus/:menuId/combo-meals/:itemId?include=components.comboItems,modifierGroups,ingredients,locations', params)
         
        return super.get(URL)
    }
}
