<template>
    <a v-if="!checkLocation()" @click="$parent.$parent.getCatagoryItems(category, index)" class="category" :class="{ 'active' : $parent.$parent.activeIndex === index }" :style="$parent.$parent.activeIndex === index ? activeCardStyle : {}">
        <span id="item-name">{{ getName(category) }}</span>
    </a>
</template>
<style lang="scss" scoped>

</style>
<script>
import { eventHub } from '@/assets/plugins/events';
import { HelperMixin, TranslationMixin } from "@/mixins";
import { mapGetters, mapMutations } from 'vuex';
import { Item } from '@/services/SOLO'
export default {
    data()
    {
        return {
            activeCardStyle: {
                '--main-color': this.$root.mainColor,
            },
            locales: {
                en: 'en-us',
                ar: 'ar-sa'
            }
        }
    },
    name: "Card",
    props: ["category", "index"],
    mixins: [HelperMixin, TranslationMixin],
    mounted()
    {
        this.activateFirstCategory()
        if(this.checkLocation())
        {
            this.setDisabledCategories(this.category.id)
        } else {
            this.removeDisabledCategory(this.category.id)
        }
    },
    computed: {
        ...mapGetters({
            getConcept: 'concept/getConcept',
            getSettings: 'app/getSettings',
            getLastLocation: 'cart/getLastLocation',
            getDisabledCategories: 'app/getDisabledCategories',
            getLocale: 'app/getLocale'
        }),
        isMobile(){
            return screen.width < 768 ? true : false
        }
    },
    methods: {
        ...mapMutations({
            setCategoryItems: 'concept/setCategoryItems',
            setDisabledCategories: 'app/setDisabledCategories',
            removeDisabledCategory: 'app/removeDisabledCategory'
        }),
        getName(category) {
            return category.attributes.category.name[this.locales[this.getLocale.toLowerCase()]] || category.attributes.category.name['en-us'];
        },
        checkLocation()
        {
            if(this.category.attributes.category['excluded-locations'])
            {
                if(this.category.attributes.category['excluded-locations'].length)
                {
                    let arrayOfBol = this.category.attributes.category['excluded-locations'].map(location => {
                        return Number(location) === Number(this.getLastLocation.id)
                    })
                    return arrayOfBol.includes(true)
                }
                return false
            }

            return false
        },
        activateFirstCategory()
        {
            let index = localStorage.getItem('activeCatIndex') ? localStorage.getItem('activeCatIndex') : 0;

            if(this.index == index)
            {
                this.$parent.$parent.getCatagoryItems(this.category, this.index);
                localStorage.setItem('activeCatIndex', 0);
            }
        },
        cacheCategory(category, index)
        {
            if(this.getConcept.attributes) {
                const params = {
                    menuId: this.getConcept.attributes['default-menu-id'],
                    categoryId: category.attributes.category.id,
                };

                Item.all({...params}).then(response => {
                    this.setCategoryItems({ id: category.id, items: response.data.data })
                }).catch(err => {
                })
            }
        }
    }
};
</script>
