<template>
  <b-modal ref="modal-error"      
      modal-header="false"
      @mousedown.self="closeModal"
      @close="closeModal"
      @hide="closeModal"
      :hide-header="true"
      :hide-footer="true"
      size="sm"
      centered
      content-class="modal-error"
      ok-only>
      <div class="error-content" :style="{ '--main-color': $root.mainColor }">
        <button class="error-close" @click="closeModal">
          <i class="fa fa-times"></i>
        </button>
        <h1 class="error-logo" :style="{ '--main-color': $root.mainColor }">
          <i class="fa fa-exclamation-triangle"></i>          
        </h1>
        <p class="error-text">
          {{errorMessage}}
        </p>
        <button class="btn error-confirm" @click="closeModal">{{okTitle}}</button>
      </div>      
  </b-modal>  
</template>
<style lang="scss">
  .modal-content {
    &.modal-error {
      max-width: 300px;
      margin: 0 auto;
      border-radius: 12px;
      .error-content {
        margin-top: 20px;
        .error-close {
          position: absolute;
          right: 16px;
          top: 16px;
          background: #F3F3F3;
          border-radius: 100%;
          border: none;
          color: #727272;
        }
        .error-logo {
          text-align: center;
          font-size: 140px;
          color: #F22424;
        }
        .error-text {
          font-family: Work Sans;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;          
          align-items: center;
          text-align: center;
          color: #1E1E1E;
        }
        .error-confirm {
          margin-top: 20px;
          height: 51.67px;          
          background: var(--main-color);
          border-radius: 8px;
          width: 100%;
          color: #fff;
        }
      }
    }    
  }
  body.rtl {
    .modal-content.modal-error {
      .error-close {
        right: unset;
        left: 16px;
      }
    }    
  }  
</style>
<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { TranslationMixin, HelperMixin } from '@/mixins'

export default {
  name: 'ErrorModal',
  props: {
    show: Boolean,
    errorMessage: String,
    okTitle: String
  },
  mixins: [TranslationMixin],
  data() {
    return {
    }
  },  
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale'
    })
  },
  watch: {
    show(val) {
      if(val) {
        this.$refs['modal-error'].show();
      } else {
        this.$refs['modal-error'].hide();
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    }    
  }
}
</script>