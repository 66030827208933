<template>
    <b-col md="12" id="location-page">
        <div class="toogle-back" @click="gotoMenu">
            <img src="/images/illustrations/x button for pages@3x.png" alt="">
        </div>
         <img class="concept-logo" :src="getConcept.attributes['logo-uri']" alt="">         
        <router-view></router-view>        
    </b-col>
</template>
<style lang="scss" scoped>
    .concept-logo {
        display: block;
        margin: 0 auto;
        height: 50px;
        margin-top: -40px;
        margin-bottom: 20px;
    }
</style>
<script>
import { mapMutations, mapGetters } from 'vuex';
export default {
    data() {
        return {

        };
    },
    components: {
    },
    computed: {
        ...mapGetters({
            getConcept: 'concept/getConcept'
        }),
    },    
    mounted()
    {        

    },     
    methods: {
        gotoMenu()
        {
            this.$router.push({ name: 'home' })
        },
    }
};
</script>