<template>
    <div>
        <div v-for="(child, index) in modifier.childs.filter(child => { return child.attributes.type != 'sizes' })" :key="index">                            
            <AdditionalModifier ref="AdditionalModifier" :modifier="modifier" :child="child" :included="modifier.included"></AdditionalModifier>
        </div>    
    </div>
</template>

<script>
import AdditionalModifier from './AdditionalModifier'
export default {
    props: ['modifier'],
    components: {
        AdditionalModifier
    }
}
</script>