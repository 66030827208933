<template>
    <div>
        <order-default-type></order-default-type>
        <order-navigation></order-navigation>
    </div>
</template>
<script>
import OrderDefaultType from './components/OrderDefaultType'
import OrderNavigation from './components/OrderNavigation'
import { TranslationMixin } from '../../../../mixins'
export default {
    components: {      
        OrderDefaultType,
        OrderNavigation
    },
    mixins: [TranslationMixin],
    mounted()
    {
        this.$root.sidebarOpen = true
        this.$root.sidebarContainerRow = 6
        this.$root.rootRowHeight = '100%'                    
    },
}
</script>