<template>
    <b-row class="mt-4">        
        <b-col md="11" class="offset-md-1 size-modifier">            
            <div class="modifier-wrapper text-center">                
                <b-button-group size="sm">
                    <b-button            
                        variant="white"
                        :style="isActiveKey === index ? ActiveBtnSizeStyle : RegularBtnSizeStyle" :class="{ 'active' : isActiveKey === index }"
                        v-for="(size, index) in sizeModifier" :key="index" @click="setSize(index)"                                                                        
                    >
                        {{size['meal-size-name']}}
                    </b-button>
                </b-button-group>
            </div>            
        </b-col>        
    </b-row>
</template>
<script>
export default {
    props: ['sizeModifier'],
    data()
    {
        return {
            isActiveKey: 0,
            ActiveBtnSizeStyle: {
                background: this.$root.mainColor
            },
            RegularBtnSizeStyle: {
                background: this.$root.secondaryColor,
                border: '1px solid '+ this.$root.mainColor,
                color: this.$root.mainColor
            },
        }
    },
    mounted()
    {
        
    },    
    methods: {
        setSize(index)
        {
            this.isActiveKey = index      
            this.$parent.$parent.$refs.Addtocart.modifierPrice = this.$parent.$parent.$refs.Addtocart.setTotalComboMealsModifierPrice();
        },
        getActive()
        {
            return this.sizeModifier[this.isActiveKey]
        },
        filterModifier(id)
        {            
            
            return this.$parent.modifiers.included.filter(data => {                
                return data.id === id
            })        
        },
    }    
}
</script>