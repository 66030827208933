<template>
    <b-container fluid class="add-ons" v-if="isLoaded">    
        <template v-if="!$parent.item.attributes['is-combo']">
            <SizeModifier @size-modifier="setSizeModifier" :sizeModifier="sizeModifier" v-if="getSizeModifier.length"></SizeModifier>       
            <modifier-group :activeSizeId="activeSizeId" ref="modifierGroup" :modifierGroup="modifier" v-for="(modifier, index) in modifiersData" :key="index"></modifier-group>
        </template>        
        <template v-if="$parent.item.attributes['is-combo']">
            <ComboMealSizeModifier v-if="sizeModifier.length > 1" ref="sizeModifier" :sizeModifier="sizeModifier"></ComboMealSizeModifier>
            <modifier-group-component ref="modifierGroup" :modifierGroup="modifier" v-for="(modifier, index) in modifiersData" :key="index"></modifier-group-component>                        
            <div v-for="(group, i) in addtionals" :key="i+'a'">
                <div class="" v-for="(modifier, index) in group.$refs.modifier" :key="index + 'b'">
                    <div v-if="modifier.childs.length">
                        <AdditionalModifierGroup ref="AdditionalModifierGroup" :modifier="modifier"></AdditionalModifierGroup>                        
                    </div>                    
                </div>
            </div>
        </template>
    </b-container>
</template>
<script>
import SizeModifier from './SizeModifier'
import ComboMealSizeModifier from './ComboMealSizeModifier'
import ModifierGroup from './ModifierGroup'
import ModifierGroupComponent from './ModifierGroupComponent'
import AdditionalModifierGroup from './AdditionalModifierGroup'
import { eventHub } from '../../../assets/plugins/events'
export default {
    props: ['modifiers'],
    data()
    {
        return {
            isLoaded: false,
            modifiersData: [],
            sizeModifier: [],
            activeSizeId: null,
            numOfSizes: 0,
            specialModifier: {
                sizes: { }
            },
            addtionals: []
        }
    },
    components: {ModifierGroup, SizeModifier, ModifierGroupComponent, ComboMealSizeModifier, AdditionalModifierGroup},
    watch: {        
        modifiers: function(data)
        {            
            this.modifiersData = [
                ...this.getModifiersData.filter(modifier => {
                    if(modifier.relationships.hasOwnProperty("comboItems"))
                    {
                        return modifier.relationships.comboItems.data.length
                    }
                    return false
                }),
                ...this.getModifiersData.filter(modifier => {
                    if(modifier.relationships.hasOwnProperty("modifiers"))
                    {
                        return modifier.relationships.modifiers.data.length
                    }
                    return false
                })
            ]        

            this.sizeModifier = this.getSizeModifier                                              
            this.isLoaded = true
            console.log(this.$parent.item)       
            console.log(this.modifiersData, this.sizeModifier)         
        }
    },
    mounted()
    {
        eventHub.$on('additionals', () => {
            this.addtionals = this.$refs.modifierGroup
            this.$forceUpdate()
        })        
    },
    methods: {
        setSizeModifier(activeSizeId) {
            this.activeSizeId = activeSizeId
        }
    },
    computed: {
        getModifiersData()
        {            
            if(!this.modifiers.data)
            {
                return []
            }else
            if(!this.$parent.item.attributes['is-combo'])
            {
                return this.modifiers.data.filter(modifier => {
                    return modifier.attributes.type != 'sizes'
                })
            }                    
            
            let components = this.modifiers.included.filter(modifier => {
                return modifier.type === 'components'
            })
            let modifiers = this.modifiers.data.relationships.modifierGroups.data.map(data => {                
                return this.modifiers.included.filter(modifier => {                                        
                    return data.id === modifier.id
                })[0]
            })            
            
            return [
                ...components,
                ...modifiers.filter(modifier => {
                    return modifier.attributes.code != 'sizes'
                })
            ]             
        },
        getSizeModifier()
        {
            if(!this.modifiers.data)
            {
                return []
            }

            if(!this.$parent.item.attributes['is-combo'])
            {
                return this.modifiers.data.filter(modifier => {
                    return modifier.attributes.type == 'sizes'
                })
            }
            
            if(this.$parent.item.attributes.hasOwnProperty('sizes'))
            {
                return this.$parent.item.attributes.sizes                    
            }

            return []
            
        }
    }
};
</script>