import BaseService from '../base.service'

let couponService = class CouponService extends BaseService {

    constructor() {
        super();                        
    }
    
    coupon(couponCode: string) {
        let url = this.baseURL + `/coupons?coupon-code=${couponCode}&include=promotion`
        return super.get(url)
    }

    promotions(payload: Object = {}) {
        let url = this.baseURL + '/promotions/validation'

        return super.post(url, payload)
    }
      

};

export default couponService