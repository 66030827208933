<template>
    <div v-if="slides" class="cart-content" :class="{ 'mt-5' : slides.length === 0}" ref="slides">
        <b-card class="change-location" :style="background" v-if="getConcept.hasOwnProperty('attributes')">
            <img v-if="home && getLocale && getConcept" :src="getConcept.attributes['logo-uri']" height="80" class="mb-2" alt="menu-icon">
            <div :class="{'wrap-to-right': home}" v-if="!getConcept.attributes['menu-only']">
                <button class="btn btn-sm btn-change" :style="btnChangeStyle" @click="changeLocation">
                    {{ translation('choose_delivery_or_pickup', 'Choose Delivery or Pickup') }}
                </button>
                <small class="title" :style="titleStyle" v-if="getOrderType === 'deliver' && hasDefault">
                    <i class="fa fa-motorcycle" aria-hidden="true"></i> {{translation('delivery')}}
                </small>
                <small class="title" :style="titleStyle" v-else-if="getOrderType === 'deliver' && !hasDefault && !isEmptyObj(getCart.temporaryDelivery) && !isLoggedIn">
                    <i class="fa fa-motorcycle" aria-hidden="true"></i> {{translation('delivery')}}
                </small>
                <small class="title" :style="titleStyle" v-if="getOrderType === 'pickup' && hasDefault">
                    <i class="fa fa-shopping-bag" aria-hidden="true"></i> {{translation('pickup')}}
                </small>
                <small class="title" :style="titleStyle" v-if="getOrderType === 'eat-in' && hasDefault">
                    <i class="fa fa-cutlery" aria-hidden="true"></i> {{translation('dine_in')}}
                </small>
                <small class="title" :style="titleStyle" v-if="getOrderType === 'to-go' && hasDefault">
                    <i class="fa fa-car" aria-hidden="true"></i> {{translation('to_go', 'To-go')}}
                </small>
                <p class="name" v-if="getOrderType === 'deliver' && hasDefault && isLoggedIn" :style="titleStyle">
                    <i class="fa fa-map-marker" :style="pinStyle" aria-hidden="true"></i> {{!isEmptyObj(getLastLocation) && getOrderType === 'deliver' ? getLine1(getLastLocation) : getLocation.attributes.line1}}
                </p>
                <p class="name" v-else-if="getOrderType === 'deliver' && !hasDefault && !isEmptyObj(getCart.temporaryDelivery) && !isLoggedIn" :style="titleStyle">
                    <i class="fa fa-map-marker" :style="pinStyle" aria-hidden="true"></i> {{getCart.temporaryDelivery.line1}}
                </p>
                <p class="name" v-if="getOrderType !== 'deliver' && hasDefault" :style="titleStyle">
                    <i class="fa fa-map-marker" :style="pinStyle" aria-hidden="true"></i>{{getCart.queue ? ' Table ' + getCart.table + ', ' : ''}} {{ !isLoggedIn ? getLocation.attributes.name :  getLocation.attributes.name }}
                </p>
            </div>
            <div class="lang-switcher mt-3 mb-4 mx-3"
                 :class="{ 'pos-left' : getLocale === 'ar', 'pos-right' : getLocale === 'en' }" v-if="getConcept.attributes['menu-only'] && getConcept.attributes.languages.length > 1">
                <b-button
                        v-for="(btn, idx) in buttons"
                        :key="idx"                        
                        @click="selected(btn)"
                        variant="white"
                        :id="'lang-id-' + idx"
                        :style="{...btnActiveColor(btn.caption)}"
                >{{ btn.text }}</b-button>
            </div>

            <div class="clearfix"></div>


        </b-card>
    </div>
</template>
<style lang="scss" scoped>
    .change-location {
        .wrap-to-right {
            float: right;
            width: 180px;
            .title {
                margin-top: 10px;
                display: block;
                width: calc(100% - 15px);
                margin-left: 15px;

            }
            .name{
                width: calc(100% - 15px);
                margin-left: 15px;
                padding-left: 10px;
                text-indent: -5px;
            }
            .btn-change{
                position: relative !important;
                display: block;
                width: 100%;
                right: unset;
            }
        }

        .lang-switcher{
            width: 95px;
            display: block;
            text-align: center;
            background: #FFFFFF;
            box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.1);
            border-radius: 100px;
            height: 32px;
            vertical-align: middle;
            position: absolute;
            &.pos-right {
                right: -12px;
                bottom: -12px;
            }

            &.pos-left {
                left: -12px;
                bottom: -30px;
            }

            .btn{
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 13px;
                text-align: center;
                color: #CFCFCF;
                border: 0;
                background: transparent;
                height: 24px;
                display: inline-block;
                padding: 6px;
                vertical-align: sub;
                &.active{
                    width: 45px;
                    height: 24px;
                    background: #FFFFFF;
                    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
                    border-radius: 100px;
                }
            }
        }
    }
    body.rtl {
        img {
            float: right;
        }
        .change-location {
            min-height: 120px;
            .wrap-to-right {
                float: left;
                width: 180px;
                text-align: right;
                .title {
                    margin-top: 10px;
                    display: block;
                    width: calc(100% - 15px);
                    margin-left: 15px;
                }
                .name{
                    width: calc(100% - 15px);
                    margin-left: 15px;
                    padding-left: 10px;
                    text-indent: 0px;
                }
                .btn-change{
                    position: relative !important;
                    display: block;
                    width: 100%;
                    right: unset;
                }
            }
        }
    }
</style>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { TranslationMixin } from '@/mixins'
import { eventHub } from '@/assets/plugins/events';
import { Location } from '@/services/SOLO'
export default {
    data()
    {
        return {
            types: {
                'deliver': 'delivery-location',
                'pickup': 'pickup-location',
                'eat-in': 'dinein-location',
                'to-go': 'to-go-location'
            },
            buttons: [
                { caption: "ar", text: 'ع' , state: true },
                { caption: "en", text: 'EN' ,state: false }
            ],
            location:{}
        }
    },
    props: ['home', 'slides'],
    mixins: [TranslationMixin],
    watch: {
        getConcept(concept) {
            if(this.getConcept.hasOwnProperty('attributes')) {                
                console.log('test')
                if (this.getConcept.attributes['menu-only']) {                    
                    setTimeout(() => {
                        if (this.getLocale === 'ar') {
                        this.selected(this.buttons[0]);
                        } else {
                        console.log('en: ');
                        this.selected(this.buttons[1]);
                        }
                    }, 200);
                }
            }            
        },
        getLocale: {
            handler(data) {
                this.getLocationFn()
            },
            deep: true,
            immediate: true
        },
        getBranches: {
            handler(data) {
                if(this.getLocation) {
                    let loc = data.filter(data => {                     
                        return Number(data.id) === Number(this.getLocation.id)
                    })
                    console.log(loc, this.getOrderType)
                    if(this.getOrderType != 'deliver' && loc.length) {
                        this.setLocation(loc[0])
                    }
                }                   
                
            },
            deep: true,
            immediate: true
        }
    },
    mounted()
    {                  
        eventHub.$on("set-branch", isTrue => {            
            if (this.getSettings.attributes['allowed-order-types'].split(',').length === 1 && this.getBranches.length == 1 && !this.hasDefault && !this.getSettings.attributes['force-location']) {                
                if(this.getSettings.attributes['allowed-order-types'].split(',')[0] != 'deliver') {                                        
                    this.setOrderType(this.getSettings.attributes['allowed-order-types'].split(',')[0]);
                    this.setLocation(this.getBranches[0]);
                }                
                
            }
        });
    },
    computed: {
        ...mapGetters({
            getOrderType: 'cart/getOrderType',
            getLocation: 'cart/getLocation',
            hasDefault: 'cart/hasDefault',
            getConcept: 'concept/getConcept',
            getCart: 'cart/getCart',
            isLoggedIn: 'account/isLoggedIn',
            getLocale: 'app/getLocale',
            getBranches: 'app/getBranches',
            getLastLocation: 'cart/getLastLocation',
            getSettings: 'app/getSettings',
            getUserAddresses: 'account/getUserAddresses'
        }),
        titleStyle()
        {
            return {
                color: this.$root.mainColor
            }
        },
        pinStyle()
        {
            return {
                fontSize: '15px'
            }
        },
        background()
        {
            return {
                backgroundColor: "white"
            }
        },
        btnChangeStyle()
        {
            return {
                color: this.$root.mainColor,
                border: '1px solid ' + this.$root.mainColor
            }
        }
    },
    methods: {
        ...mapMutations({
            setOrderType: 'cart/setOrderType',
            setLocation: 'cart/setLocation',
            setBranches: 'app/setBranches'
        }),
        getLocationFn() {
            Location.fetchLocations()
                .then(response => {                      
                    this.setBranches(response.data.data)                                                             
                })
                .catch(err => {
                    console.error(err);
                });
        },
        getLine1(location) 
        {
            if(this.getUserAddresses)
            {
                return this.getUserAddresses[0].attributes.line1
            } else if(location.hasOwnProperty('attributes'))
            {
                return location.attributes.line1
            } else {
                return location.line1
            }
        },
        changeLocation()
        {
            if(this.getConcept.attributes['allowed-order-types'].split(',').length > 1 && this.hasDefault)
            {
                this.$router.push({name: this.types[this.getOrderType]})
            }else
            {
                this.$router.push({name: this.types[this.getConcept.attributes['allowed-order-types'].split(',')[0]]})
            }
        },
        btnActiveColor(caption) {
            if(this.getLocale.toLowerCase() === caption) {
                return {
                    color: this.$root.mainColor
                }
            }

            return {
                color: '#CFCFCF'
            }
        },
        selected(btn) {
            const idx = this.buttons.findIndex(b => b.caption === btn.caption);
            const locale = btn.caption.toLowerCase()
            if (idx === 0) {
                this.buttons[idx].state = true;
                this.buttons[idx + 1].state = false;
                this.setLocale(locale);
                document.body.classList.add('rtl')
            } else if (idx === 1) {
                this.buttons[idx].state = true;
                this.buttons[idx - 1].state = false;
                this.setLocale(locale);
                if(document.body.classList.contains('rtl')) {
                    document.body.classList.remove('rtl')
                }
            }
        },
        isEmptyObj(obj) {
            for(var key in obj) {
                if(obj.hasOwnProperty(key))
                    return false;
            }
            return true;
        }
    }
}
</script>
