<template>
    <b-container fluid class="add-ons" v-if="ingredients.length">    
        <b-row class="mt-1">
            <b-col md="11" class="offset-md-1 add-ons-container" :style="addOnsWrapperStyle">
                <div class="add-ons-wrapper">
                    <label>{{translation('remove')}}</label>                
                    <small class="text-danger d-block">
                        <!-- <span v-html="eyeIcon" class="font-weight-bold d-none"></span>{{ this.modifierGroup.attributes.minimum === 1 ? errorMsg.replace('items', 'item') : errorMsg }} -->
                    </small>                    
                    <Ingredient ref="ingredient" :ingredient="ingredient" v-for="(ingredient, key) in ingredients" :key="key"></Ingredient>
                </div>                
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import Ingredient from './Ingredient'
import { TranslationMixin } from '@/mixins'
export default {
    props: ['ingredients'],    
    components: { Ingredient },
    mixins: [TranslationMixin],
    computed: {
        addOnsWrapperStyle() {
            return {
                "--main-color": this.hexToRgbA(this.$root.mainColor)
            };
        },        
    },
    methods: {
        hexToRgbA(hex) {
            var c;
            if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
                c = hex.substring(1).split("");
                if (c.length == 3) {
                    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
                }
                c = "0x" + c.join("");
                return (
                    "rgba(" +
                    [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
                    ",0.23)"
                );
            }            
        },
    },
    mounted()
    {
        console.log(this.ingredients)
    }  
}
</script>