import BaseService from '../base.service'
import store from '@/store'
export default class PaymentService extends BaseService {

    constructor() {
        super();                
    }    


    public kioskPaymentOrder(order_token: string, includes: string = '') {
        let url = this.baseURL + `/payments/orders/${order_token}?include=items`
        return super.get(url)
    }


}
