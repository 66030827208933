<template>
    <div class="payment-page pt-4">
        <div class="row mt-4">
            <div class="col-md-11 offset-md-1">
                <h5 class="text-muted font-weight-bold">Payment</h5>
                <div class="row">
                    <div class="col-md-6">
                        <CustomerLocation />
                        <PaymentMethod />
                    </div>
                    <div class="col-md-6"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CustomerLocation from "./payment-component/CustomerLocation";
import PaymentMethod from "./payment-component/PaymentMethod";
export default {
    components: {
        CustomerLocation,
        PaymentMethod
    }
};
</script>