export default {
    ready: false,
    branches: [],
    locale: 'ar',
    settings: {},
    disabledCategories: [],
    viewedItem: {
        id: null,
        attributes: {},
        modifiers: []
    },
    paymentType: null,
    addedToFavorites: false,
    orderTypes: [],
    'tap-order-id': null,
    'tap-order-amount': null,
    'tap-payment-response': null,
    'tap-payment-order-data': null,
    'tap-payment-status': null,
    'tap-payment-public-credentials': null,
    'checkout-payment-public-credentials': null,
    kioskOrder: null,
    kioskVerifyResponse: null,
    token: null,
    categories: [],
    modifiers: [],
    ingredients: [],
    allergens: [],
    pageRules: {
        location: {
            allowedRouteNames: [
                'default-location'
            ],
        },
        pickupLocation: {
            allowedRouteNames: [
                'pickup-location',
                'dinein-location'
            ],
        },
        deliverLocation: {
            allowedRouteNames: [
                'delivery-location'
            ],
        },
        notification: {
            unallowedRouteNames: [
                'location',
                'default-location',
                'pickup-location',
                'dinein-location',
                'delivery-location'
            ]
        },
        authenticatedRoutes: [

        ]
    }
}
