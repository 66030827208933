<template>
    <div>
        <b-card
            :style="checkedStyle"
            @click="toogleModifier"
        >
            <img
                :src="modifier.attributes['image-uri']"
                height="40"
                width="40"
                alt
            />
            <span class="space"></span>
            <div
                class="text"
                ref="name"
                v-html="modifier.attributes.name"
            ></div>
            <div class="side-content">
                <small v-if="modifier.attributes.price">{{translateNumber(modifier.attributes.price)}} {{translation(currency.key, currency.value)}}</small>
            </div>
        </b-card>
    </div>
</template>
<style lang="scss" scoped>
.card-body {
    padding-right: 50px !important;
    padding: 5px !important;
}
.text {
    position: absolute;
    top: 8px;
    left: 60px;
    width: calc(100% - 55px);
    line-height: 12px;
    font-size: 11px;
    padding: 2px 0 0 0;
    height: 25px;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
    line-height: 20px;
}
</style>
<script>
import { mapGetters } from 'vuex'
import { TranslationMixin } from '@/mixins'
import { eventHub } from '@/assets/plugins/events'
export default {
    props: ["modifier", "index"],
    data() {
        return {
            quantity: 0,
            checked: false
        };
    },
    mixins: [TranslationMixin],
    watch: {
        checked(newVal)
        {
            if(newVal)
            {
                this.quantity = 1
            }else
            {
                this.quantity = 0
            }
            this.$parent.$parent.$parent.$parent.$refs.Addtocart.modifierPrice = this.$parent.$parent.$parent.$parent.$refs.Addtocart.setTotalComboMealsModifierPrice();
        }
    },
    computed: {
        ...mapGetters({
            currency: 'concept/currency',
        }),
        checkedStyle() {
            if (this.quantity) {
                return {
                    background: this.$root.mainColor,
                    "--main-color": this.$root.mainColor,
                    color: "#fff",
                };
            }

            return {};
        }
    },
    mounted()
    {
        if(this.$parent.child.attributes.minimum === 1 && !this.index)
        {
            this.toogleModifier()
        }
    },
    methods: {
        toogleModifier()
        {
            if(!this.checked)
            {
                if(this.$parent.child.attributes.maximum > 1)
                {
                    if(this.$parent.$refs.modifier.map(modifier => { return modifier.quantity }).reduce((a,b) => { return a + b }) < this.$parent.child.attributes.maximum)
                    {
                        this.checked = true
                    }
                }else
                {
                    this.$parent.$refs.modifier.map(modifier => {
                        if(this.modifier.id != modifier.id)
                        {
                            modifier.checked = false
                        }
                    })

                    this.checked = true
                }
            }else
            {
                this.checked = false
            }

        }
    }
};
</script>
