<template>
    <b-row>
        <b-col md="6" class="order-navigation">
            <button class="btn btn-xlg btn-app-blue btn-continue" :disabled="!hasDefault">Continue</button>
            <button v-if="getOrderType === 'deliver'" class="btn btn-lg btn-app-white btn-saved-addresses">Saved Addresses</button>
            <button v-if="getOrderType === 'deliver'" class="btn btn-lg btn-app-white btn-add-address">Add Address</button>
        </b-col>
    </b-row>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            getLocation: 'cart/getLocation',
            hasDefault: 'cart/hasDefault',
            getOrderType: 'cart/getOrderType'
        })
    },
    mounted()
    {
        console.log(this.hasDefault)
    }
}
</script>