<template>    
    <b-container class="text-center transaction-details">                    
        <button class="btn btn-primary" id="go-sell">Proceed to Payment</button>
        <div id="wrap-content"></div>
    </b-container>    
</template>
<style lang="scss" scoped>

    .transaction-details{
        height: 100%;
        vertical-align: middle;
        margin-top: 200px; 
        #wrap {
            iframe {
                &#gosell-gateway{
                    display: block !important;
                }
            }
            .content {
                text-align: center;
                .title{
                    color: var(--main-color);
                    margin-bottom: 20px;
                }
                .btn {
                    border: 1px solid var(--main-color);
                    color: var(--main-color);
                    width: 90%;
                    margin-bottom: 20px;
                }
            }       
        }
    }
</style>
<script>
import { mapGetters, mapMutations } from "vuex";
import { TranslationMixin } from '@/mixins'
export default {
    data() {
        return {
            isConfiguring: true,
            redirectURL: window.location.hostname + `payment/tap/redirect`,
            goSellCdn: null
        };
    },
    mixins: [TranslationMixin],
    computed: {
        ...mapGetters({
            getTapOrderID: "app/getTapOrderID",
            getConcept: "concept/getConcept",
            getTapOrderAmount: "app/getTapOrderAmount",
            getUser: "account/getUser",
            getTapPaymentStatus: 'app/getTapPaymentStatus',
            getTapCredentials: 'app/getTapCredentials',
            getLocation: 'cart/getLocation'
        }),
        customer() {
            return {
                customer: {
                    id: null,
                    first_name: this.getUser.attributes['first-name'],
                    last_name: this.getUser.attributes['last-name'],
                    phone: {
                        country_code: this.getConcept.attributes["dialing-code"],
                        number: this.removeMobilePrefix(
                            this.getUser.attributes.mobile
                        )
                    }
                }
            };
        },
        gatewayDetails() {
            let details = {
                publicKey: this.getTapCredentials.option_value,
                language: "en",
                contactInfo: true,
                supportedCurrencies: "all",                
                saveCardOption: false,
                customerCards: true,
                notifications: "standard",
                supportedPaymentMethods:  "all"
            };

            if (this.getLocation.hasOwnProperty('custom-fields')) {
                this.getLocation.attributes['custom-fields'].forEach(row => {
                    if (row['custom-field'] === 'tap_merchant_id' && row.value !== '') {
                        details.tap_merchant_id = row.value;
                    }
                });
            }
            
            return details;
        },
        styles() {
            return {
                backgroundImg: {
                    url: this.getConcept.attributes["logo-uri"],
                    opacity: "1.0"
                },
                labels: {
                    cardNumber: "Card Number",
                    expirationDate: "MM/YY",
                    cvv: "CVV",
                    cardHolder: "Name on Card",
                    actionButton: "Pay"
                },
                style: {
                    base: {
                        color: "#535353",
                        lineHeight: "18px",
                        fontFamily: "sans-serif",
                        fontSmoothing: "antialiased",
                        fontSize: "16px",
                        "::placeholder": {
                            color: "rgba(0, 0, 0, 0.26)",
                            fontSize: "15px"
                        }
                    },
                    invalid: {
                        color: "red",
                        iconColor: "#fa755a "
                    }
                }
            };
        }
    },
    mounted() {                         
        console.log(this.setConfig()) 
        this.redirectURL = (window.location.hostname.includes('localhost') ? 'http://localhost:8080' : 'https://' + window.location.hostname )+ "/payment/tap/redirect"                
        this.setTapPaymentStatus('processing')        
        goSell.config(this.setConfig())         
        document.getElementById('go-sell').onclick = function () {            
            goSell.openPaymentPage()
        }
    },    
    methods: {
        ...mapMutations({
            setTapPaymentStatus: 'app/setTapPaymentStatus'
        }),        
        setConfig() {            
            return {
                containerID: "wrap-content",
                gateway: {
                    ...this.gatewayDetails,
                    callback: response => {
                        this.isConfiguring = false                        
                    },
                    onClose: () => {
                        console.log("onClose Event");
                    },
                    ...this.styles
                },
                ...this.customer,
                order: {
                    amount: this.getTapOrderAmount,
                    currency: this.getConcept.attributes["currency-code"],
                    items: [],
                    shipping: null,
                    taxes: null
                },
                transaction: {
                    mode: "charge",
                    charge: {
                        saveCard: false,
                        threeDSecure: true,
                        description: "Test Description",
                        statement_descriptor: "Sample",
                        reference: {
                            transaction: this.setTransaction(),
                            order: this.$route.params.orderId
                        },
                        metadata: {},
                        receipt: {
                            email: false,
                            sms: true
                        },
                        redirect: this.redirectURL,
                        post: process.env.VUE_APP_API_URL + `/webhook/tap/feedback`
                    }
                },
            };
        },
        setTransaction() {
            var d = new Date();
            return d.getTime() + this.getTapOrderID;
        },
        removeMobilePrefix(mobile) {
            let formattedMobile = mobile.toString();
            return formattedMobile.substring(3, formattedMobile.length);
        }
    }
};
</script>