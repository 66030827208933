<template>
    <div class="tab-parent">
        <ul class="tab" :class="{ 'two-sections': this.getConcept.attributes['allowed-order-types'].split(',').filter(type => { return type != 'curbside' }).length <= 2 }">
            <router-link :to="{name: 'delivery-location'}" v-slot="{ href, route, navigate, isActive }" v-if="isAllowedType('deliver')">
                <li :class="{ 'active': isActive }" @click="navigate" :style="isActive ? activeTabStyle : {}">
                    <i class="fa fa-motorcycle" aria-hidden="true"></i> {{$parent.translation('delivery_tab_label', 'Delivery')}}
                </li>
            </router-link>
            <router-link :to="{name: 'pickup-location'}" v-slot="{ href, route, navigate, isActive }" v-if="isAllowedType('pickup')">
                <li :class="{ 'active': isActive }" @click="navigate" :style="isActive ? activeTabStyle : {}">
                    <i class="fa fa-shopping-bag" aria-hidden="true"></i> {{$parent.translation('pickup_tab_label', 'Pickup')}}
                </li>
            </router-link>
            <router-link :to="{name: 'dinein-location'}" v-slot="{ href, route, navigate, isActive }" v-if="isAllowedType('eat-in')">
                <li :class="{ 'active': isActive }" @click="navigate" :style="isActive ? activeTabStyle : {}">
                    <i class="fa fa-cutlery" aria-hidden="true"></i> {{$parent.translation('dine_in_tab_label', 'Dine in')}}
                </li>
            </router-link>
            <router-link :to="{name: 'to-go-location'}" v-slot="{ href, route, navigate, isActive }" v-if="isAllowedType('to-go')">
                <li :class="{ 'active': isActive }" @click="navigate" :style="isActive ? activeTabStyle : {}">
                    <i class="fa fa-shopping-bag" aria-hidden="true"></i> {{translation('to_go_tab_label', 'To-go')}}
                </li>
            </router-link>
        </ul>
    </div>
</template>
<style lang="scss" scoped>
#location-page .tab.two-sections {
    li {
        width: 35%;
    }
}
</style>
<script>
import { TranslationMixin } from "@/mixins";
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            activeTab: 1
        };
    },
    mixins: [TranslationMixin],
    computed: {
        ...mapGetters({
            getLocation: 'cart/getLocation',
            hasDefault: 'cart/hasDefault',
            getSettings: 'app/getSettings',
            getAllowedOrderTypes: 'app/getAllowedOrderTypes',
            getConcept: 'concept/getConcept'
        }),
        activeTabStyle()
        {
            return {
                background: this.$root.mainColor
            }
        },
        allowedOrderTypes()
        {
            if(this.getSettings.hasOwnProperty('attributes'))
            {
                return this.getSettings.attributes
            }
            return {}
        }
    },
    mounted() {
        
    },
    methods: {
        TabSelect(tab) {
            this.activeTab = tab;
        },
        isAllowedType(type)
        {                      
            return this.getConcept.attributes['allowed-order-types'].split(',').includes(type)        
        }
    }
};
</script>
