<template>
    <ul class="address-inputs-wrapper">
        <li v-if="false">
            <b-form-input
                v-model="$parent.$v.address.line1.$model"
                type="text"
                :placeholder="translation('google_address', 'Google address')"
                class="form-control"
                aria-describedby="input-1-live-feedback"
                :disabled="true"
                :state="$parent.$v.address.line1.$dirty ? !$parent.$v.address.line1.$error : null"
            ></b-form-input>
            <b-form-invalid-feedback id="input-1-live-feedback">
                <i class="fa fa-info-circle" aria-hidden="true"></i> Address is required
            </b-form-invalid-feedback>
        </li>
        <li>
            <b-form-textarea
                :placeholder="translation('additional_details', 'Additional Details / Note for the driver')"
                v-model="$parent.$v.address.instructions.$model"
                rows="3"
                class="form-control"
                :state="$parent.$v.address.instructions.$dirty ? !$parent.$v.address.instructions.$error : null"
            ></b-form-textarea>
        </li>
        <li v-if="false">
            <b-form-select
                v-model="$parent.$v.address.label.$model"
                :options="options"
                :placeholder="translation('address_name', 'Address Name')"
                class="form-control"
                aria-describedby="input-2-live-feedback"
                :state="$parent.$v.address.label.$dirty ? !$parent.$v.address.label.$error : null"
            ></b-form-select>
            <b-form-invalid-feedback id="input-2-live-feedback">
                <i class="fa fa-info-circle" aria-hidden="true"></i> This is a required field
            </b-form-invalid-feedback>
        </li>
        <li class="text-center" v-if="lat != 24.774265 && lng != 46.738586">
            <button
                type="button"
                :style="btnStyle"
                class="btn btn-new-address"
                @click="saveAddress($event)"
            >{{translation('save_address', 'Save address')}}</button>
        </li>
    </ul>
</template>
<script>
import Vue from "vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { TranslationMixin } from "@/mixins";
import { gmapApi } from "vue2-google-maps";
import { Location } from '@/services/SOLO';

export default {

    // lat: 24.774265,
    // lng: 46.738586,
    props: ['lat', 'lng'],
    data() {
        return {
            
        };
    },
    mixins: [TranslationMixin],
    computed: {
        google: gmapApi,
        btnStyle() {
            return {
                "--main-color": this.$root.mainColor
            };
        },
        options()
        {
            return [
                { text: this.translation('address_name'), value: null },
                { text: this.translation('home'), value: this.translation('home') },
                { text: this.translation('work'), value: this.translation('work') },
                { text: this.translation('other'), value: this.translation('other') }
            ]
        }
    },
    mounted() {
        
    }, 
    methods: {
        saveAddress(e) {
            let pos = { lat: this.$parent.myPosition.lat,lng: this.$parent.myPosition.lng };
            var self = this
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode(
                {
                    latLng: pos
                },
                function(results, status) {
                    if (status == google.maps.GeocoderStatus.OK) {
                        if (results[0]) {                                                            
                            Location.validateLocation(pos.lat, pos.lng)
                            .then(response => {
                                console.log(response.data.data)
                                if(response.data.data.hasOwnProperty('id'))
                                {
                                    self.$parent.address.line1 = results[0].formatted_address;                                                        
                                    self.$parent.saveAddress(e, self.$parent.address)
                                }else
                                {                                                                            
                                    self.$toasted.global.error({
                                        message: self.translation('select_another_location')
                                    })
                                }                                
                            })
                            .catch(err => {
                                console.log(err)
                            })
                        }
                    }
                }
            ); 
                        
        }
    }
};
</script>
