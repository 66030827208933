import data from '@/data';

export default {
    /*
    |-----------------------------------------------------------
    |
    |-----------------------------------------------------------
    |
    |
    |
    */

    getDiscounted(state: { discounted: any; }, getters: any, rootState: any, rootGetters: any) {
        return state.discounted
    },    
    /**
     * Get all the items
     *
     * @param state
     * @param getters
     * @param rootState
     * @param rootGetters
     * @returns {*}
     */

    getDiscount(state: { discount: any; }, getters: any, rootState: any, rootGetters: any) {
        return state.discount
    },    
    /**
     * Get all the items
     *
     * @param state
     * @param getters
     * @param rootState
     * @param rootGetters
     * @returns {*}
     */
    
    getItems(state: { items: any; }, getters: any, rootState: any, rootGetters: any) {
        return state.items
    },

    /**
     * Return all the states that build up the cart object that is needed to process the order
     *
     * @param state
     * @param getters
     * @returns {{address: *, scheduledTime: (*|null), notes: (*|string), deliveryCharge: (*|number), discount: (*|number), type: *, vatAmount: (*|number), total: *, subtotal: (*|number|default.computed.subtotal), totalPoints: (*|number), location: *, payment: (*|Array), couponCode: (*|string), items: *}}
     */
    getCart(state: any, getters: any){
        return { ...state };
    },

    getOrderType(state: {type: string})
    {
        return state.type
    },
    
    couponCode(state: { couponCode: any; })
    {
        return state.couponCode
    },

    couponResult(state: { couponData: any; })
    {
        return state.couponData 
    },

    hasDefault(state: { defaultLocation: { [x: string]: any; }, type: string; })
    {
        return state.defaultLocation[state.type] ? true : false
    },

    getLocation(state: { defaultLocation: { [x: string]: any; }, type: string; })
    {
        return state.defaultLocation[state.type]
    },

    getLastLocation(state: { lastLocationSelected: any; })
    {
        return state.lastLocationSelected
    },

    getItemUpdateTime(state: { [x: string]: any; })
    {
        return state['items-updated-at']
    },

    getDefaultLocations(state: { defaultLocation: { [x: string]: any; }; })
    {
        return state.defaultLocation
    },

    /**
     * Check if the order type is 'deliver'
     *
     * @param state
     * @param getters
     * @returns {boolean}
     */
    isForDelivery(state: { type: string; }, getters: any){
        return state.type === 'deliver'
    },

    /**
     *
     * @param state
     * @param getters
     * @returns {null}
     */
    getTemporaryLocation(state: { temporaryLocation: any; }, getters: any){
        return state.temporaryLocation
    },

    getTemporaryDelivery(state: { temporaryDelivery: any; }) {
        return state.temporaryDelivery
    },
    /**
     *
     * @param state
     * @param getters
     * @returns {null}
     */
    getRedirectToMenuValue(state: { redirectToMenu: any; }, getters: any){
        return state.redirectToMenu
    },

    /**
     *
     * @param state
     * @param getters
     * @returns {*}
     */
    getAddress(state: { address: any; }, getters: any){
        return state.address
    },

    /**
     *
     * @param state
     * @param getters
     * @returns {*}
     */
    getScheduledTime(state: { scheduledTime: any; }, getters: any){
        return state.scheduledTime
    },

    /*
    |-----------------------------------------------------------
    | Item Page Temporary Data Getters
    |-----------------------------------------------------------
    |
    | The following getters are for the states that are used for
    | the temporary item data in the ItemPage component
    |
    */

    /**
     *
     * @param state
     * @param getters
     * @param rootState
     * @param rootGetters
     * @returns {default.tempItemOrder|{}|*}
     */
    getTempItemOrder(state: { tempItemOrder: any; }, getters: any, rootState: any, rootGetters: any) {
        console.log('getTempItemOrder - state: ', state);
        return state.tempItemOrder
    },

    /**
     *
     * @param state
     * @returns {number|default.computed.quantity}
     */
    getTempItemOrderQuantity(state: { tempItemOrder: { quantity: any; }; }){
        console.log('state.tempItemOrder.quantity: ', state.tempItemOrder.quantity);
        return state.tempItemOrder.quantity
    },

    /**
     *
     * @param state
     * @returns {null}
     */
    getSelectedDrink(state: { selectedDrink: any; }) {
        return state.selectedDrink
    },

    /**
     *
     * @param state
     * @returns {null}
     */
    getSelectedSide(state: { selectedSide: any; }) {
        return state.selectedSide
    },

    /**
     *
     * @param state
     * @returns {null}
     */
    getSelectedFries(state: { selectedFries: any; }){
        return state.selectedFries
    },

    /**
     *
     * @param state
     * @returns {Array}
     */
    getSelectedExtras(state: { selectedExtras: any; }){
        return state.selectedExtras
    },

    /**
     *
     * @param state
     * @returns {number|*}
     */
    getDeliveryCharge(state: { deliveryCharge: any; }){
        return state.deliveryCharge
    },

    /**
     *
     * @param state
     * @returns {boolean}
     */
    isMeal(state: { itemOrderType: string; }) {
        return state.itemOrderType === 'meal'
    },

    /**
     *
     * @param state
     * @returns {boolean}
     */
    isSingle(state: { itemOrderType: string; }){
        return state.itemOrderType === 'single'
    },

    /**
     *
     * @param state
     * @returns {number|*}
     */
    getTempTotal(state: { tempTotal: any; }){
        return state.tempTotal
    },

    /**
     *
     * @param state
     * @param getters
     * @returns {*}
     */
    getPendingModifer(state: { pendingModifer: any; }, getters: any){
        console.log('state.pendingModifer: ', state.pendingModifer);
        return state.pendingModifer
    },

    /**
     *
     * @param state
     * @param getters
     * @returns {*}
     */
    getCheckoutId(state: { checkout_id: any; }, getters: any){
        return state.checkout_id
    },

    /**
     *
     * @param state
     * @param getters
     * @returns {*}
     */
    getHyperPay(state: { hyperPay: any; }, getters: any){
        return state.hyperPay
    },

    getRedirectTo(state: { redirectTo: boolean }){
        return state.redirectTo
    },

    getTableTimestamp(state: { timestamp: any; }, getters: any){
        return state.timestamp
    }
}
