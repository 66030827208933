<template>
        <gmap-map
            ref="mapRef"
            :center="{lat: lat, lng: lng}"
            :zoom="zoom"
            style="width: 100%; height: 650px;"
            :options="{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: false
            }"
        >
           <GmapMarker
                :position="myPosition"
                :clickable="true"
                :icon="'/images/illustrations/Delivery location pin.png'"
            >                    
            </GmapMarker>
        </gmap-map>
</template>

<script>
export default {
    data() {
        return {
            lat: Number(this.$route.params.lat),
            lng: Number(this.$route.params.lng),
            zoom: 11,
            myPosition: {
                lat: Number(this.$route.params.lat),
                lng: Number(this.$route.params.lng)
            },

        };
    },
    computed: {

    },
    watch: {

    },
    mounted() {
 
    },
    methods: {


    }
};
</script>
