import BaseService from '../base.service'
import store from '@/store'
export default class CategoryService extends BaseService {

    constructor() {
        super();        
        this.baseURL = this.baseURL + '/menus/';
    }

    all(menuId: string | number, page = 1) {        
        // https://cdn.getsolo.io/apps/production/DAIMCQUCAwQ-menu-25.json

        let url = super.prepareUrl(this.baseURL + menuId + '/categories', {});

        return super.get(url, {
            page: page,
            include: 'allergen,itemModifierGroups,locations',
            limit: 999,
        });

        return super.get(url, {
            page: page
        });
    }

    cdn(menuRef: string) {                        
        return super.getJson(menuRef + `?timestamp=${new Date().getTime()}`);        
    }

    items(menuId: string | number, page = 1) {        
        let url = this.baseURL + menuId + '/items';

        return super.get(url);
    }

    find(menuId: string | number, categoryId: string | number) {        
        let url = this.baseURL + menuId + '/categories/' + categoryId;

        return super.get(url)
    }
}
