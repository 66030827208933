<template>
    <b-col md="12" id="checkout-page">
        <Header />
        <SelectPayment />
    </b-col>
</template>
<script>
import Header from "./components/Header";
import Payment from "./components/Payment";
import SelectPayment from "./components/SelectPayment";
import { mapGetters } from "vuex";
import { DataLayerMixin } from '@/mixins'
export default {
    data() {
        return {
            method: false
        };
    },
    mixins: [ DataLayerMixin ],
    components: {
        Header,
        Payment,
        SelectPayment
    },
    computed: {
        ...mapGetters({
            getItems: "cart/getItems",
            getLocale: 'app/getLocale',
            couponCode: 'cart/couponCode',
        }),
        totalPrice() {
            return this.$parent.$refs.sidebar.$refs.cart.$refs.wrapper.$refs.payment.totalPrice
        }
    },
    beforeRouteEnter(to, from, next) {        
        if(from.name === 'tap-payment') {            
            return window.location.reload()
        }
        next(vm => {
          // access to component instance via `vm`
            if (vm.$store.getters['concept/getConcept'].attributes['menu-only']) {
                next('/');
            } else {
                next();
            }
        });
    },
    mounted() {                
        this.$parent.$refs.sidebar.$refs.cart.switchComponent("Cart");
        
        if (!this.getItems.length && this.couponCode === '') {
            this.$router.push({ name: "home" });
        } else {
            let cartItems = JSON.stringify(this.getItems.map(item => {
                return {
                    name: item.attributes.name,
                    quantity: item.quantity,
                    price: item.total
                }
            }))
            this.setDataLayer(this.$route.name, this.getLocale, 'products: ' + cartItems, null)
        }
    }
};
</script>
