<template>
    <section class="header-wrapper personal-info">
        <h3 @click="setProfileComponent('Navigator')">
            <span class="header-back-content">
                <i v-if="getLocale === 'en'" class="fa fa-long-arrow-left"></i> 						
			    <i v-if="getLocale === 'ar'" class="fa fa-long-arrow-right"></i> 
            </span>		
        </h3>

        <b-card>
            <h5 class="text-center mb-4">{{translation('personal_info')}}</h5>
            <p class="name-caps">{{translation('full_name')}}:</p>
            <h5 class="name">{{getFirstName}}</h5>
            <p class="email-caps">{{translation('email_address')}}:</p>
            <p class="email">{{getEmail}}</p>
            <p class="mobile-caps">{{translation('mobile', 'Mobile')}}:</p>
            <p class="mobile">{{getMobile}}</p>
            <button :style="btnEditProfileStyle" class="btn btn-sm btn-edit-profile" @click="setProfileComponent('Profile')">
                <i class="fa fa-pencil" aria-hidden="true"></i>
                {{translation('edit_profile')}}
            </button>
        </b-card>
    </section>
</template>
<script>
import { TranslationMixin } from "@/mixins";
import { mapGetters } from "vuex";
export default {
    data()
    {
        return {
            btnEditProfileStyle: {
                border: '1px solid ' + this.$root.mainColor,
                color: this.$root.mainColor
            }
        }
    },
    mixins: [TranslationMixin],
    computed: {
        ...mapGetters({
            getFirstName: "account/getFirstName",
            getLastName: "account/getLastName",
            getMobile: "account/getMobile",
            getEmail: "account/getEmail"
        })
    },
    methods: {
        setProfileComponent(component)
        {
            this.$parent.current = component
        }
    }
};
</script>