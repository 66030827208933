<template>	
	<b-col md="8" id="restaurants-page">
		<Header></Header>
		<FastFood></FastFood>
		<Deserts></Deserts>		
	</b-col>
</template>
<script>
import Header from './components/Header'
import FastFood from './components/FastFood'
import Deserts from './components/Deserts'
export default {
	name: "Restaurants",
	components: { Header, FastFood, Deserts }
}
</script>