var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('location-box',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"searchBox"}),_c('div',{staticClass:"locate-me"},[_c('button',{on:{"click":_vm.myLocation}},[_c('i',{staticClass:"material-icons",attrs:{"aria-hidden":"true"}},[_vm._v("")])])]),(_vm.isAllowedType('deliver'))?_c('gmap-map',{ref:"mapRef",staticStyle:{"width":"100%","height":"300px"},attrs:{"center":{lat: _vm.lat, lng: _vm.lng},"zoom":_vm.zoom,"draggable":true,"options":{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: false
            }},on:{"drag":_vm.updateCoordinates,"dragstart":function($event){return _vm.start($event)},"dragend":_vm.stop}},[(_vm.myPosition.lat && _vm.myPosition.lng)?_c('GmapMarker',{attrs:{"position":_vm.myPosition,"clickable":true,"icon":'/images/illustrations/Delivery location pin.png'}}):_vm._e()],1):_vm._e(),_c('address-form',{ref:"AddressForm",staticClass:"address-form",attrs:{"lat":_vm.myPosition.lat,"lng":_vm.myPosition.lng}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }