<template>
    <div class="container login-page">
        <error-modal @close="showErrorModal = false" 
                    :show="showErrorModal" 
                    :errorMessage="errorMessage" 
                    :okTitle="okTitle"></error-modal>

        <div class="row">
            <div class="col-md-8"></div>
            <div class="col-md-4">
                <form class="login-form" @submit.prevent>
                    <div class="title">
                        <h3 class="font-weight-bold">{{translation('sign_in_message')}}</h3>
                    </div>
                    <!--<LangSwitch></LangSwitch>                    -->
                    <div class="form-group email">
                        <input
                            type="text"
                            v-model="customer.username"
                            class="form-control"
                            :placeholder="translation('enter_mobile')"
                        />
                    </div>
                    <div class="form-group password">
                        <input
                            type="password"
                            v-model="customer.password"
                            class="form-control"
                            :placeholder="translation('enter_password')"
                        />
                    </div>
                    <div class="form-group">
                        <button class="btn btn-primary form-control text-white" :style="btnBackground" @click="signin($event)">Sign in</button>
                    </div>
                </form>
                <div class="social-media-logins text-center">
                    <hr />
                    <small class="or">Or</small>
                    <small v-show="false" class="signin-text">{{ translation('sign_in_social_account') }}</small>
                    <ul v-show="false">
                        <li>
                            <span>
                                <i class="fa fa-facebook fa-2x" aria-hidden="true"></i>
                            </span>
                            <a href="javascript:;">Facebook</a>
                        </li>
                        <li>
                            <span>
                                <i class="fa fa-twitter fa-2x" aria-hidden="true"></i>
                            </span>
                            <a href="javascript:;">Twitter</a>
                        </li>
                        <li>
                            <span>
                                <i class="fa fa-google fa-2x" aria-hidden="true"></i>
                            </span>
                            <a href="javascript:;">Google</a>
                        </li>
                    </ul>
                    <router-link :to="{ name: 'register' }">
                        <div class="dont-have-account text-dark">
                            {{ translation('dont_have_account','Don\'t have an account?') }}
                            <span class="text-primary text-sign-up">{{ translation('sign_up') }}</span>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import LangSwitch from '@/layouts/components/selector/LangSwitch'
import { Customer, Concept } from "@/services/SOLO";
import { mapGetters } from 'vuex';
import { TranslationMixin, HelperMixin } from "@/mixins";
import ErrorModal from '@/layouts/components/global/ErrorModal.vue';

export default {
    data() {
        return {
            customer: {
                username: '',
                password: ''                
            },
            showErrorModal: false,
            errorMessage: '',
            okTitle: '',
            auth: this.$root.AuthService
        };
    },
    mixins: [ TranslationMixin, HelperMixin ],
    components: { ErrorModal },
    computed: {
        ...mapGetters({
            getSettings: 'app/getSettings',
            getConcept: 'concept/getConcept',
            getCart: 'cart/getCart',
            getUser: 'account/getUser',
        }),
        btnBackground() {
            return {
                background: this.$root.mainColor,
                backgroundColor: this.$root.mainColor,
                border: '1px solid ' + this.$root.mainColor
            }
        },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            // access to component instance via `vm`
            if (vm.$store.getters['concept/getConcept'].attributes['menu-only']) {
                next('/');
            } else {
                next();
            }
        });
    },
    mounted()
    {        
        this.$root.sidebarOpen = true;
    },
    methods: {
        saveAddress() {
            if(this.getCart.temporaryDelivery.hasOwnProperty('line1'))
            {
                Customer.createAddress(this.getUser.id, this.getCart.temporaryDelivery)
                    .then(response => {
                        this.setUserAddresses(response.data.data);
                        this.setDefault(response.data.data[0])
                    })
                    .catch(err => {
                        console.log(err.response)
                    });
            }
        },
        getTapPublicKey() {
            Concept.getTapPublicKey().then(response => {
                this.setTapCredentials(response.data.data)
            })
        },
        signin(e) {
            let defaultText = e.target.innerHTML;
            e.target.innerHTML ='<i class="fa fa-circle-o-notch fa-spin"></i> Waiting...';
            e.target.disabled = true;
            this.auth.login(this.customer)
            .then(response => {
                console.log('response: ', response);
                e.target.innerHTML = defaultText;
                e.target.disabled = false;
                
                this.$store.commit("account/setUser", data);
                this.$router.push({ path: '/' });
                
            })
            .catch(err => {         
                    
                e.target.innerHTML = defaultText;
                e.target.disabled = false;
                this.showErrorModal = true
                this.errorMessage = err.response.data.error[0].detail
                this.okTitle = this.translation('okay')
            });
        }
    }
};
</script>
