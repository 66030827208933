<template>
    <b-modal
        id="modalOtp"
        centered
        :no-close-on-backdrop="true"
        :no-close-on-esc="false"
        :hide-footer="showHeaderFooter"
        :hide-header="showHeaderFooter"
        ok-only
    >
        <div class="row mt-4">
            <div class="col-md-8 offset-md-2">
                <h5 class="text-center font-weight-bold text-blue mt-4">{{translation('verify_your_phone_number', 'Verify your phone number')}}</h5>
                <p
                    class="text-center"
                >{{translation('we_have_sent_verification_code', 'We have sent verification code to sample, usually it takes 2-3 mins.')}}</p>
                <div class="form-group text-center">
                    <input type="text" v-model="code" class="form-control text-center" :placeholder="translation('enter_code_caps', 'ENTER CODE')" />
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6">
                            <b-button
                                type="button"
                                @click="resendCode($event)"
                                variant="secondary"
                                class="form-control"
                            >{{translation('resend', 'Resend')}}</b-button>
                        </div>
                        <div class="col-md-6">
                            <b-button
                                type="submit"
                                @click="onSubmit($event)"
                                :disabled="disabled"
                                variant="primary"
                                class="form-control"
                            >{{translation('submit')}}</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>
<script>
import { Customer } from "../../services/SOLO";
import { TranslationMixin } from "@/mixins";
import { eventHub } from '../../assets/plugins/events';
export default {
    data() {
        return {
            showHeaderFooter: true,
            updateMobile: true,
            code: "",
            customerResponse: {},
            isValidCode: false,
            errorText: "",
            invalidMobile: false,
            mobileErrorText: "",
            disabled: true,
            data: {},
            customer: this.$root.customerService
        };
    },
    mixins: [TranslationMixin],
    computed: {
        isLoggedIn() {
            return this.$store.getters["account/isLoggedIn"];
        },
        user() {
            return this.$store.getters["account/getUser"];
        }
    },
    mounted() {
        eventHub.$on("main-otp", data => {
            this.data = data;
            this.show();
        });
        this.disableDoubleTap();
    },
    methods: {
        show() {
            this.$bvModal.show("modalOtp");
        },
        resendCode(e) {
            let defaultText = e.target.innerHTML;
            e.target.innerHTML = '<i class="fa fa-circle-o-notch fa-spin"></i> Resending...';
            e.target.disabled = true;
            this.customer.resendCode({
                id: this.data.id,
                mobile: this.data.attributes.mobile
            })
                .then(response => {
                    e.target.innerHTML = defaultText;
                    e.target.disabled = false;
                })
                .catch(error => {
                    e.target.innerHTML = defaultText;
                    e.target.disabled = false;
                });
        },
        onSubmit(e) {
            let defaultText = e.target.innerHTML;
            e.target.innerHTML = '<i class="fa fa-circle-o-notch fa-spin"></i> loading...';
            e.target.disabled = true;
            this.customer.verifyCode(this.data.id, {
                pincode: this.code
            })
            .then(response => {
                e.target.innerHTML = '<i class="fa fa-circle-o-notch fa-spin"></i> signing in...';
                this.$bvModal.hide("modalOtp");
                this.$router.push({ path : '/login' })
            })
            .catch(error => {
                let errMsg = error.response.data.error.map(error => { return error.detail; }).join(", ");
                this.$toasted.global.error({
                    message: errMsg
                });
                e.target.innerHTML = defaultText;
                e.target.disabled = false;
            });
        },
        disableDoubleTap(){
            var doubleTouchStartTimestamp = 0;
            document.addEventListener("touchstart", function(event){
                var now = +(new Date());
                if (doubleTouchStartTimestamp + 500 > now){
                    event.preventDefault();
                };
                doubleTouchStartTimestamp = now;
            }, { passive: false });
        }
    }
};
</script>
