<template>
    <b-container fluid class="add-to-cart" :style="wrapperBorderTop" v-if="$parent.modifiers.hasOwnProperty('data') && !getConcept.attributes['menu-only']">
        <error-modal :show="showErrorModal" :ok-title="okTitle" :error-message="errorMessage" @close="closeErrorModal"></error-modal>
        <b-row>
            <b-col md="3" class="quantity" offset-lg="2">
                <b-button-group variant="light" class="qty-modifier">
                    <b-button variant="light" @click="incrementQty" class="btn-increment" :style="btnIncStyle" >
                        +
                    </b-button>
                    <b-button variant="light">{{ translateNumber($parent.item.quantity, 0) }}</b-button>
                    <b-button variant="light" @click="decrementQty" class="btn-decrement" :style="btnIncStyle">
                        -
                    </b-button>
                </b-button-group>
            </b-col>
            <b-col md="5" class="button" :style="{ '--main-color': $root.mainColor }">
                <b-form-group class="add_to_crt">
                    <button :style="AddToCartStyle" class="btn form-control p-1" @click="addToCart">{{ translateNumber(($parent.item.attributes.price + modifierPrice) * $parent.item.quantity) }} {{ translation(currency.key, currency.value) }}
                        <span v-if="$parent.item.attributes" :class="getLocale === 'AR' ? '' : ''"> {{translation('add_to_cart', 'ADD TO BAG')}}</span>
                    </button>
                </b-form-group>
            </b-col>
        </b-row>
    </b-container>
</template>
<style lang="scss" scoped>

</style>
<script>
import { TranslationMixin, DataLayerMixin, HelperMixin } from '@/mixins'
import { eventHub } from '../../../assets/plugins/events'
import { mapMutations, mapGetters } from 'vuex'
import ErrorModal from '@/layouts/components/global/ErrorModal.vue'

const _ = require('lodash')
export default {
    mixins: [TranslationMixin, DataLayerMixin, HelperMixin],
    data()
    {
        return {
            modifierPrice: 0,
            selectedExtras: [],
            removeIngredients: [],
            comboItems: {},
            item: {},
            minRequiredIsMet: false,
            sizeModifier: {},
            selectedIngredients: [],
            showErrorModal: false,
            errorMessage: '',
            okTitle: this.translation('okay'),


        }
    },
    components: {
        ErrorModal
    },
    mounted()
    {
        // console.log(this.$parent.modifiers.hasOwnProperty('data'))
    },
    computed: {
        wrapperBorderTop()
        {
            return {

            }
        },
        AddToCartStyle()
        {
            return {
                background: this.$root.mainColor
            }
        },
        priceStyle()
        {
            return {
                color: this.$root.mainColor + '!important'
            }
        },
        btnIncStyle()
        {
            return {
                color: '#fff',
                background: this.$root.mainColor,
            }
        },
        btnDecStyle()
        {
            return {
                background: this.$root.secondaryColor,
                color: this.$root.mainColor,
                border: '1px solid ' + this.$root.mainColor
            }
        },
        spanStyle()
        {
            return {
                'border-color': this.$root.secondaryColor
            }
        },
        ...mapGetters({
            getCart: 'cart/getCart',
            getConcept: 'concept/getConcept',
            getLocale: 'app/getLocale',
            getCategories: 'app/getCategories',
            getModifiers: 'app/getModifiers',
            getIngredients: 'app/getIngredients',
            currency: 'concept/currency',
        }),
    },
    methods: {
        ...mapMutations({
            addItem: 'cart/addItem'
        }),
        getItemExcludedLocations(categoryId, itemId) {
            let category = this.getCategories.filter(category => {
                return Number(category.id) === Number(categoryId)
            })

            if(category.length) {
                let item = category[0].attributes.items.filter(item => {
                    return Number(item.id) === Number(itemId)
                })

                if(item.length) {
                    return {'excluded-locations': item[0]['excluded-locations']}
                }

                return {'excluded-locations': []}
            }

            return {'excluded-locations': []}

        },
        getItemName(categoryId, itemId) {
            let category = this.getCategories.filter(category => {
                return Number(category.id) === Number(categoryId)
            })

            let item = category[0].attributes.items.filter(item => {
                return Number(item.id) === Number(itemId)
            })

            return item[0].name
        },
        setTotalModifiersPrice()
        {           
            let totalModifiersPrice = this.$parent.$refs.modifiers.$refs.modifierGroup
                .filter(child => {
                    child.freeModifierCount = 0
                    return child.$refs.modifier
                })
                .map(child => {
                    child.$refs.modifier.map(modifier => {
                        if(modifier.quantity)
                        {
                            console.log(modifier, child.modifierGroup.attributes['free-modifiers-count'])
                            modifier.numOfFree = 0
                            while (child.freeModifierCount < child.modifierGroup.attributes['free-modifiers-count'] && modifier.numOfFree < modifier.quantity) {
                                child.freeModifierCount++
                                modifier.numOfFree++
                            }
                        }
                    })
                    return child
                })
                .map(child => {
                    var arr = []                     
                    
                    child.$refs.modifier.map(modifier => {
                        let billableQty =  modifier.quantity - modifier.numOfFree                        
                        if(billableQty > 0)
                        {
                            arr.push(modifier.modifier.attributes.price * billableQty)
                        }                           
                    })

                    return arr.reduce((a, b) => a + b, 0)
                })

            if(!totalModifiersPrice.length)
            {
                return 0
            }
            return totalModifiersPrice.reduce((a, b) => { return a + b })
        },
        setTotalComboMealsModifierPrice()
        {
            let totalModifiersPrice = this.$parent.$refs.modifiers.$refs.modifierGroup
                .filter(child => {
                    return child.$refs.modifier
                })
                .map(child => {
                    return child.$refs.modifier.map(modifier => {
                        if(modifier.modifier.type === 'combo-items')
                        {
                            if(modifier.modifier.attributes.sizes.length > 1)
                            {
                                return modifier.modifier.attributes.sizes.filter(size => {
                                    return size['meal-size-id'] === this.$parent.$refs.modifiers.$refs.sizeModifier.getActive()['meal-size-id']
                                })[0].price * modifier.quantity
                            }

                            return modifier.modifier.attributes.sizes[0].price * modifier.quantity

                        }
                        return modifier.modifier.attributes.price * modifier.quantity
                    }).reduce((a, b) => { return a + b })
                })
            let additionalModifierPrice = []
            if(this.$parent.$refs.modifiers.$refs.hasOwnProperty('AdditionalModifierGroup'))
            {
                this.$parent.$refs.modifiers.$refs.AdditionalModifierGroup.map(group => {
                    if(group.$refs.hasOwnProperty('AdditionalModifier'))
                    {
                        group.$refs.AdditionalModifier.map(additional => {
                            if(additional.$refs.modifier) {
                                additional.$refs.modifier.map(modifier => {
                                    if(modifier.checked)
                                    {
                                        additionalModifierPrice.push(modifier.quantity * modifier.modifier.attributes.price)
                                    }
                                })
                            }
                        })
                    }
                })
            }
            if(!totalModifiersPrice.length)
            {
                return 0
            }
            return [...totalModifiersPrice, ...additionalModifierPrice].reduce((a, b) => { return a + b })
        },
        decrementQty()
        {
            if(this.$parent.item.quantity > 1) this.$parent.item.quantity--
        },
        incrementQty()
        {
            this.$parent.item.quantity++
        },
        voidIfRequiredNotSelected()
        {
            this.minRequiredIsMet = true

            if(!this.$parent.item.attributes['is-combo'])
            {
                this.$parent.$refs.modifiers.$refs.modifierGroup.map((child, i) => {
                    if(child.$refs.modifier) {
                        let selectedQuantity = child.$refs.modifier.map(modifier => {
                            return modifier.quantity
                        })

                        if(child.modifierGroup.attributes.minimum > 0 || child.modifierGroup.attributes.minimum === null)
                        {
                            if((child.modifierGroup.attributes.minimum > selectedQuantity.reduce((a,b) => a + b, 1)) || child.modifierGroup.attributes.minimum === null && !selectedQuantity.reduce((a,b) => a + b, 1))
                            {
                                this.$parent.$refs.modifiers.$refs.modifierGroup[i].showError = 1
                                let msg = this.translation('select_modifier_toasted_msg', 'Please select up to MINIMUM_QTY items for MODIFIER_GROUP_NAME')
                                        .replace('MODIFIER_GROUP_NAME', this.$parent.$refs.modifiers.$refs.modifierGroup[i].modifierGroup.attributes.name)
                                        .replace('MINIMUM_QTY', this.$parent.$refs.modifiers.$refs.modifierGroup[i].modifierGroup.attributes.minimum);

                                msg = this.$parent.$refs.modifiers.$refs.modifierGroup[i].modifierGroup.attributes.minimum === 1 ? msg.replace('items', 'item') : msg;

                                this.showErrorModal = true
                                this.errorMessage = msg
                                if(this.minRequiredIsMet)
                                {
                                    this.minRequiredIsMet = false
                                }
                            }
                        }
                    }                    
                })
            }

            return this.minRequiredIsMet
        },
        closeErrorModal(show = false) {
            this.showErrorModal = show
        },
        addToCart()
        {
            this.selectedExtras = []
            this.comboItems = {}

            if((this.$parent.$refs.modifiers.modifiers.data.length || this.$parent.$refs.modifiers.modifiers.included) && !_.isEmpty(this.$parent.$refs.modifiers.$refs))
            {
                if(!this.voidIfRequiredNotSelected())
                {
                    return console.error('required modifier not met!')

                }

                this.$parent.$refs.modifiers.$refs.modifierGroup
                    .filter(child => {
                        return child.$refs.modifier
                    })
                    .map(child => {
                    child.$refs.modifier.filter(modifier => {
                        return modifier.modifier.type === 'modifier'
                    }).map(modifier => {
                        if(modifier.quantity)
                        {

                            let filteredModifier = this.getModifiers.filter(modifierobj => {
                                return Number(modifierobj.id) === Number(modifier.modifier.id)
                            })

                            console.log(filteredModifier[0].attributes, filteredModifier[0].id)

                            modifier.modifier.attributes = { ...modifier.modifier.attributes, name: filteredModifier[0].attributes.name }

                            this.selectedExtras.push({
                                ...modifier.modifier,
                                quantity: modifier.quantity
                            })
                        }
                    })
                })
            }

            let comboItems = []

            if(this.$parent.item.attributes['is-combo'])
            {
                let selectedSize = []
                if(this.$parent.$refs.modifiers.sizeModifier.length > 1)
                {
                    selectedSize = this.$parent.modifiers.included.filter(include => {
                        return Number(include.id) === this.$parent.$refs.modifiers.$refs.sizeModifier.getActive()['meal-size-id']
                    })
                }else{

                    selectedSize = this.$parent.modifiers.included.filter(include => {
                        return Number(include.id) === this.$parent.$refs.modifiers.sizeModifier[0]['meal-size-id']
                    })
                }

                if(selectedSize.length)
                {
                    this.selectedExtras.push({
                        ...selectedSize[0],
                        quantity: 1
                    })
                }

                this.$parent.$refs.modifiers.$refs.modifierGroup.filter(group => {
                    return group.$refs.hasOwnProperty('modifier')
                }).map(group => {
                    return group.$refs.modifier.map(modifier => {
                        if(modifier.checked && !modifier.childs.length)
                        {
                            let mealSize = {}
                            if(this.$parent.$refs.modifiers.sizeModifier.length > 1)
                            {
                                mealSize = modifier.modifier.attributes.sizes.filter(size => { return size['meal-size-id'] === this.$parent.$refs.modifiers.$refs.sizeModifier.getActive()['meal-size-id'] })[0]
                            }else
                            {
                                mealSize = modifier.modifier.attributes.sizes[0]
                            }
                            comboItems.push({
                                "id": mealSize['modifier-id'],
                                "name": modifier.modifier.attributes.name,
                                "price": mealSize.price,
                                "quantity": 1,
                                "ingredients": [],
                                "modifiers": []
                            })
                        }
                    })
                })


                if(this.$parent.$refs.modifiers.$refs.hasOwnProperty('AdditionalModifierGroup'))
                {
                    this.$parent.$refs.modifiers.$refs.AdditionalModifierGroup.map(group => {
                        let mealSize = {}
                        if(this.$parent.$refs.modifiers.sizeModifier.length > 1)
                        {
                            mealSize = group.modifier.modifier.attributes.sizes.filter(size => { return size['meal-size-id'] === this.$parent.$refs.modifiers.$refs.sizeModifier.getActive()['meal-size-id'] })[0]
                        }else
                        {
                            mealSize = group.modifier.modifier.attributes.sizes[0]
                        }

                        let comboItemModifiers = []

                        if(group.$refs.hasOwnProperty('AdditionalModifier'))
                        {
                            group.$refs.AdditionalModifier.map(additional => {
                                if(additional.$refs.modifier) {
                                    additional.$refs.modifier.map(option => {
                                        if(option.checked)
                                        {
                                            comboItemModifiers.push({
                                                'name': option.modifier.attributes.name,
                                                'id': option.modifier.id,
                                                'price': option.modifier.attributes.price,
                                                'quantity': option.quantity
                                            })
                                        }
                                    })
                                }
                            })
                        }

                        comboItems.push({
                            "id": mealSize['modifier-id'],
                            "name": group.modifier.modifier.attributes.name,
                            "price": mealSize.price,
                            "quantity": 1,
                            "ingredients": [],
                            "modifiers": comboItemModifiers
                        })

                    })
                }
            } else if(Object.keys(this.sizeModifier).length) {
                this.selectedExtras.push({
                    ...this.sizeModifier,
                    quantity: 1
                })
            }

            this.comboItems = comboItems.length ? { 'combo-items': comboItems } : { }

            this.selectedIngredients = []
            this.removeIngredients = []
            if(this.$parent.$refs.hasOwnProperty('ingredients'))
            {
                if(this.$parent.$refs.ingredients.$refs.hasOwnProperty('ingredient'))
                {
                    this.$parent.$refs.ingredients.$refs.ingredient.map(ingredient => {
                      if (
                          this.getIngredients &&
                          Array.isArray(this.getIngredients) &&
                          this.getIngredients.length
                      ) {
                        let filteredIngredients = this.getIngredients.filter(ingredientobj => {
                          if(ingredientobj.id == ingredient.ingredient.attributes['ingredient-id']) {
                            console.log(ingredientobj, ingredient.ingredient)
                          }
                          return ingredientobj.id == ingredient.ingredient.attributes['ingredient-id']
                        })
                        let ingredientNameObj = { ...ingredient.ingredient, name: filteredIngredients[0].attributes.name }
                        if(!ingredient.checked)
                        {

                          this.selectedIngredients.push({
                            id: ingredient.ingredient.id,
                            quantity: ingredient.ingredient.attributes.quantity,
                            name: ingredientNameObj.name,
                            checked: ingredient.checked
                          })
                        } else {
                          this.removeIngredients.push({
                            id: ingredient.ingredient.id,
                            quantity: ingredient.ingredient.attributes.quantity,
                            name: ingredientNameObj.name,
                            checked: ingredient.checked
                          })
                        }
                      }
                    })
                }
            }


            let attributes = {...this.$parent.item.attributes, ...this.getItemExcludedLocations(this.$route.params.categoryId.split("-").pop(), this.$route.params.id.split("-").pop()), name: this.getItemName(this.$route.params.categoryId.split("-").pop(), this.$route.params.id.split("-").pop())}

            this.item = {
                id: this.$parent.item.id,
                attributes: attributes,
                discount: 0,
                imageUri: this.$parent.item.attributes["image-uri"],
                price: this.$parent.item.attributes.price,
                quantity: this.$parent.item.quantity,
                modifiers: this.selectedExtras,
                ingredients: this.selectedIngredients,
                removed: this.removeIngredients,
                ...this.comboItems,
                menuId: this.$parent.getConcept.attributes['default-menu-id'],
                categoryId: this.$route.params.id.split("-").pop(),
                modifierPrice: this.modifierPrice,
                subtotal: (this.$parent.item.attributes.price + this.modifierPrice) * this.$parent.item.quantity,
                total: (this.$parent.item.attributes.price + this.modifierPrice) * this.$parent.item.quantity,
            };

            this.setDataLayer(this.$route.name, this.getLocale, 'productName: ' + this.item.name, this.item.quantity)

            this.addItem(this.item)

            eventHub.$emit("cart-added", true);

            this.$toasted.global.info({
                message: this.translation('item_added_to_cart')
            });

            this.$router.push({name: 'home'})

        },
        isEmptyObj(obj) {
            for(var key in obj) {
                if(obj.hasOwnProperty(key))
                    return false;
            }
            return true;
        },
        filterModifier(id)
        {

            return this.$parent.$refs.modifiers.modifiers.included.filter(data => {
                return data.id === id
            })
        },
        isEmptyObj(obj) {
            for(var key in obj) {
                if(obj.hasOwnProperty(key))
                    return false;
            }
            return true;
        }
    }
}
</script>
