<template>
    <section class="header-wrapper">
        <h3>
            <span class="header-back-content" @click="setProfileComponent('Navigator')">
                <i v-if="getLocale === 'en'" class="fa fa-long-arrow-left"></i> 			
                <i v-else class="fa fa-long-arrow-right"></i> 			
            </span>
			{{translation('edit_profile')}}
        </h3>
    </section>
</template>
<script>
import { TranslationMixin } from "@/mixins";
import { mapGetters } from 'vuex';
export default {
    mixins: [TranslationMixin],
    computed: {
        ...mapGetters({
            getLocale: "app/getLocale",
        })
    },
    methods: {
        setProfileComponent(component)
        {
            this.$parent.$parent.current = component
        }
    }
};
</script>