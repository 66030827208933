<template>
    <div>
        <ul class="coupon-container">
            <li class="coupon-item" v-for="(coupon, i) in coupons" :key="i">
                <i class="fa fa-check-circle check"></i>
                <span class="tag"><i class="fa fa-tag"></i></span>
                <p class="description">{{ coupon.attributes.description }}</p>
            </li>
        </ul>
    </div>

</template>
<script>
export default {
  props: ['coupons'],
}
</script>
<style lang="scss">
    .coupon-container {
        padding: 10px;
        margin-top: 25px;
        margin-bottom: 0;
        overflow-x: scroll;
        white-space:nowrap;
        .coupon-item {
            list-style: none;
            border: 1px solid var(--main-color);
            display: inline-block;
            padding: 15px 35px 15px 35px;
            border-radius: 10px;
            position: relative;
            margin-right: 16px;
            .tag {
                position: absolute;
                top: 14px;
                left: 12px;
                border-radius: 50%;
                border: 1px solid var(--main-color);
                padding: 4px 8px 4px 8px;
                background-color: #fff;
                i {
                    color: var(--main-color);
                }
            }
            .check {
                color: var(--main-color);
                position: absolute;
                top: 8px;
                right: 8px;
            }
            .description {
                margin-top: 8px;
                margin-bottom: 8px;
                font-size: 12px;
                font-weight: bold;
                width: 160px;
                padding-left: 20px;
            }
        }
    }
</style>
