<template>
    <section class="header-wrapper order-history">
        <h3 @click="setProfileComponent('Navigator')">
            <span class="header-back-content">
                <img src="/images/icons/Back.svg" alt />
            </span>
        </h3>                
    </section>
</template>
<script>
import { TranslationMixin } from "@/mixins";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            orders: [],
        };
    },
    mixins: [TranslationMixin],    
    computed: {
        ...mapGetters({
            getLocale: "app/getLocale"
        })
    },    
    methods: {        
        setProfileComponent(component) {
            this.$parent.current = component;
        }
    }
};
</script>
