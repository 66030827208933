var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SwitchView',{ref:"switch-view"}),_c('div',{staticStyle:{"position":"relative"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeIdx == 0),expression:"activeIdx == 0"}],staticClass:"locate-me"},[_c('button',{on:{"click":_vm.myLocation}},[_c('i',{staticClass:"material-icons",attrs:{"aria-hidden":"true"}},[_vm._v("")])])]),_c('gmap-map',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeIdx == 0),expression:"activeIdx == 0"}],ref:"mapRef",staticStyle:{"width":"100%","height":"600px"},attrs:{"center":{lat: _vm.lat, lng: _vm.lng},"zoom":_vm.zoom,"options":{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: false
            }}},_vm._l((_vm.branches),function(m,index){return _c('GmapMarker',{key:index,staticClass:"info-window",attrs:{"position":m.position,"clickable":true,"icon":'/images/illustrations/Delivery location pin.png',"z-index":1},on:{"click":function($event){return _vm.markerClicked(m)}}},[(_vm.shouldOpenInfoWindow(m.id))?_c('gmap-info-window',{attrs:{"options":_vm.infoWindowStyle,"fontFamily":"Poppins"}},[_c('small',{staticClass:"d-block info-window-title"},[_vm._v(_vm._s(_vm.translation('location', 'Location')))]),_c('small',{staticClass:"font-weight-bold info-window-sub-title"},[_vm._v(_vm._s(m.attributes.name))]),_c('button',{staticClass:"btn btn-sm btn-select",style:(_vm.btnSelectStyle),on:{"click":function($event){return _vm.setDefault(m)}}},[_vm._v(_vm._s(_vm.translation('select_this_branch')))])]):_vm._e()],1)}),1)],1),_c('StoreLocationList',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeIdx == 1),expression:"activeIdx == 1"}],staticClass:"list-view"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }