<template>
  <b-modal ref="modal-schedule"      
      modal-header="false"
      @mousedown.self="closeModal"
      @close="closeModal"
      @hide="closeModal"
      :hide-header="true"
      :hide-footer="true"
      size="sm"
      ok-only>
    <h1 class="sched-title" :style="{ '--main-color': mainColor }">{{translation('schedule_order','SCHEDULE ORDER')}}</h1>
    <div class="close" :style="{ '--main-color': mainColor }" @click="closeModal">&times;</div>
    <h5 class="sched-sub-title" :style="{ '--main-color': mainColor }">{{translation('pick_a_day','PICK A DAY')}}</h5>            
    <calendar ref="calendar" v-model="date" :min-date="mindate" :max-date="maxdate" :style="{ '--main-color': mainColor }" @dayclick="onDayClick"></calendar>    
    <p class="pick-timer text-center" :style="{ '--main-color': mainColor }">{{translation('please_choose_time_text','Please choose a time after _day_ _time_').replace('_day_', getDate(mindate).format('MMM D')).replace('_time_', getDate(mindate).format('hh:m'))}}</p>    
    <b-card class="card-timer" :style="{ '--main-color': mainColor }">
      <b-time v-model="time" locale="en" class="timer"></b-time>
    </b-card>
    <button v-if="date && time_is_valid" :style="{ '--main-color': mainColor }" class="btn btn-secondary btn-lg confirm-schedule" @click="setSchedule">
      {{translation('confirm_schedule','Confirm Schedule')}}
    </button>
  </b-modal>  
</template>
<style lang="scss">
  .close {
    font-weight: 800;
    font-size: 21px;
    position: absolute;
    right: 20px;
    top: 20px;
    height: 20px;
    width: 20px;
    border: 1px solid var(--main-color) !important;
    border-radius: 100%;
    line-height: 18px;
    text-align: center;
    opacity: 1;
    color: var(--main-color) !important;
  }
  .confirm-schedule {
    background: var(--main-color) !important;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    border: 0;
    display: block;
    height: 48px;
    font-family: Cairo;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;    
    text-align: center;
    color: #FFFFFF;
    width: 100%;
    margin-top: 20px;
  }
  /** calendar */
  .sched-title {   
    font-family: Cairo; 
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 34px;
    color: var(--main-color) !important;
    text-align: center;
  }

  .pick-timer {
    margin-top: 20px;
    color: var(--main-color) !important;
  }
  .sched-sub-title {
    font-family: Cairo;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 26px;
    color: #ACACAC;
    margin-left: 20px;
  }
  .vc-container {    
    width: 100%;
    padding: 0px;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    border: 0;
  }

  .vc-arrows-container {
    .vc-arrow {
      border-radius: 100%;
      width: 32px;
      height: 32px;
      background: #FFFFFF;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);      
      i {
        color: var(--main-color) !important;
        font-size: 11px;
      }      
      svg {
        display: none;
      }
    }
  }

  .vc-pane {    
    min-width: 310px !important;
  }

  .vc-title {    
    font-family: Cairo;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 20px !important;
    line-height: 23px !important;    
    text-align: center !important;
    letter-spacing: 0.24px !important;
    color: var(--main-color) !important;
  }

  .vc-weekday {
    font-family: Cairo;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 30px !important;    
    text-align: center !important;
    color: #474747 !important;

  }

  .vc-day-box-center-center {
    margin-bottom: 20px;
  }
  .vc-day-content {
    font-family: Cairo; 
    border-radius: 100%;
    background: #fff;
    width: 33px !important;
    height: 33px !important;    
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    // color: rgba(0, 0, 0, 0.1);
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    &.is-disabled {
      background: #F1F1F1;
    }
    &:hover { //, &[tabindex="0"] 
      background-color: var(--main-color) !important;
      color: #fff !important;
    }
  }

  /** time */
  .card-timer {
    background: #FFFFFF;
    border: 0;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
  }

  .b-time {
    margin: 0 auto;
    display: block !important;
    direction: ltr !important;
  }

  .b-time .b-time-header {
    display: none;
  }

  .b-form-spinbutton.flex-column {
    border: 0;
  }

  .b-form-spinbutton .btn {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04) !important;
    border-radius: 5px !important;
    &[aria-label="Increment"] {
      font-family: Cairo;
      font-size: 11px;
      color: var(--main-color) !important;
      svg {
        display: none;
      }
    }
    &[aria-label="Decrement"] {
      font-family: Cairo;
      font-size: 11px;
      color: var(--main-color) !important;
      svg {
        display: none;
      }
    }
  }

  .b-form-spinbutton output {
    border: 0 !important;
  }

  .b-form-spinbutton output > div, .b-form-spinbutton output > bdi {
    font-family: Cairo;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;    
    text-align: center;
    color: #141414;
  }

  body.rtl {
    .sched-sub-title {
      text-align: right;
    }
  }
</style>
<script>
import Vue from 'vue';
import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { mapGetters } from 'vuex';
import moment from 'moment'
import { TranslationMixin, HelperMixin } from "@/mixins";

Vue.component('calendar', Calendar)
Vue.component('date-picker', DatePicker)

export default {
  name: 'ScheduleCalendar',
  props: {
    show: Boolean,
  },
  data() {
    return {
      date: null,
      time: '',
      mindate: new Date(),
      maxdate: null,
      time_is_valid: true,
    }
  },
  components: {
    Calendar,
    DatePicker
  },
  mixins: [TranslationMixin],
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
      getConcept: 'concept/getConcept'
    }),
    mainColor() {
      return this.$root.mainColor
    }
  },  
  watch: {
    show(val) {       
      if (val) {
        this.$refs['modal-schedule'].show();
        this.date = null
        this.setNow()
        setTimeout(() => {
          document.querySelector('.vc-arrows-container .vc-arrow:first-child').innerHTML = this.getLocale === 'en' ? '<i class="fa fa-chevron-left"></i>' : '<i class="fa fa-chevron-right"></i>'          
          document.querySelector('.vc-arrows-container .vc-arrow:nth-child(2)').innerHTML = this.getLocale === 'en' ? '<i class="fa fa-chevron-right"></i>' : '<i class="fa fa-chevron-left"></i>'

          document.querySelectorAll('.b-form-spinbutton .btn[aria-label="Increment"]').forEach(function(el) {
            el.innerHTML = '<i class="fa fa-chevron-up"></i>'
          })          

          document.querySelectorAll('.b-form-spinbutton .btn[aria-label="Decrement"]').forEach(function(el) {
            el.innerHTML = '<i class="fa fa-chevron-down"></i>'  
          })          
          
        }, 100)
      } else {
        this.$refs['modal-schedule'].hide();
      }
    },
    time(val) {
      if(window.location.hostname.includes(process.env.VUE_APP_MINIM_SCHEDULE_URL) && moment(moment(val, 'hh:mm:ss').toDate()).unix() < moment().unix()) {
        this.time_is_valid = false
      } else if (window.location.hostname.includes(process.env.VUE_APP_MINIM_SCHEDULE_URL) && moment(moment(val, 'hh:mm:ss').toDate()).unix() >= moment().unix()) {
        this.time_is_valid = true
      }
      
    }
  },
  mounted() {       
    
    if(window.location.hostname.includes(process.env.VUE_APP_MINIM_SCHEDULE_URL)) {
      this.mindate = new Date(moment().add(1, 'day'))   
    }    
    this.maxdate = moment(this.mindate).add(this.getConcept.attributes['max-days-allowed-in-advance-order'], 'days').format('YYYY-MM-DD');    
  },
  methods: {
    getDate(date) {
      return moment(date).locale(this.getLocale === 'en' ? 'en-US' : 'ar-SA')
    },
    setSchedule() {
      let datetime = `${this.date} ${this.time}`
      if(moment(datetime).isValid()) {        
        this.$emit('set-schedule', moment(datetime).format('YYYY-MM-DD HH:mm:ss'))
        this.closeModal()
      } else {
        this.$toasted.global.error({
            message: 'Please pick a date!'
        }); 
      }
    },
    changeDate(day) {
      this.date = moment(day.date).format('YYYY-MM-DD')
    },
    onDayClick(day) {           
      var now;
      if(window.location.hostname.includes(process.env.VUE_APP_MINIM_SCHEDULE_URL)) {
        now = moment(moment().add(1, 'day').format('YYYY-MM-DD'))
      } else {
        now = moment(moment().format('YYYY-MM-DD'))
      }      
      let days = now.diff(moment(day.date).format('YYYY-MM-DD'), "days")            
      
      if(days > 0) {
        this.$parent.showErrorMessage(this.translation('you_cant_select_date_in_past','You cannot select a date in the past'), this.translation('okay')) 
        this.date = null     
      } else if((this.getConcept.attributes['max-days-allowed-in-advance-order'] + days) < 0) {
        if(!this.getConcept.attributes['max-days-allowed-in-advance-order']) {
          this.$parent.showErrorMessage(`You can only select current date`, this.translation('okay'))           
        } else {
          this.$parent.showErrorMessage(this.translation('you_may_only_order_up_to_days','You may only order up to _DAYS_ days in advance').replace('_DAYS_', this.getConcept.attributes['max-days-allowed-in-advance-order']), this.translation('okay'))           
        }        
        this.date = null     
      } else {        
        this.date = moment(day.date).format('YYYY-MM-DD')
      }            
    },
    closeModal() {      
      this.$emit("close");
    },
    setNow() {
      const now = new Date()      
      this.time = now.toTimeString().slice(0, 8)
    },
    clearTime() {
      this.time = ''
    }
  }
}
</script>