<template>
    <div class="cart-payment">
        <error-modal :show.sync="error" @close="error = false" :error-message.sync="errorMessage" :ok-title.sync="okText"></error-modal>
        <schedule-calendar @set-schedule="setSchedule" class="modal-schedule" :show.sync="showScheduleModal" @close="showScheduleModal = false"></schedule-calendar>
        <b-card class="cart-promo-code" v-if="couponCode === ''">
            <b-form-group>
                <input type="text" class="form-control" :placeholder="translation('promo_code')" v-model="promo"/>
                <button class="btn" @click="applyPromo" :style="btnBackground">{{translation('apply')}}</button>
            </b-form-group>
        </b-card>
        <b-card class="cart-promo-code" v-else>
            <b-form-group>
                <label>{{couponCode}} <span :style="{ color: $root.mainColor, fontWeight: 600 }">{{getDiscountText}}</span></label>
                <button class="btn" @click="removeCoupon" :style="btnBackground">{{translation('coupon_remove')}}</button>
            </b-form-group>
        </b-card>
        <div class="cart-payment-details" v-if="getConcept.attributes">
            <ul>
                <li class="text-muted">
                    {{translation('subtotal')}}
                    <span class="numeric-text">{{ translateNumber(getSubTotal ? getSubTotal : 0, 2) }} {{translation(currency.key, currency.value)}}</span>
                </li>
                <li class="text-muted" v-if="getDiscount">
                     {{translation('discount', 'Discount')}}
                    <!-- <span class="numeric-text">{{translateNumber(convertDecimal(getDiscount * -1 , getConcept.attributes['decimal-places'], getLocale))}} {{translation('sr')}}</span> -->
                    <span class="numeric-text">{{ translateNumber(getDiscount, 2) }} {{translation(currency.key, currency.value)}}</span>
                </li>
                <li class="text-muted" v-if="getItem">
                    {{translation('free_item', 'Free item')}}
                    <span>{{getItem}}</span>
                </li>
                <li class="text-muted" v-if="getOrderType === 'deliver' && !freeDelivery && (hasDefault || (getTemporaryDelivery && !isLoggedIn) )">
                    {{translation('delivery_charge', 'Delivery charge')}}
                    <span class="numeric-text">{{ translateNumber(getConcept.attributes['default-delivery-charge']) }} {{ translation(currency.key, currency.value) }}</span>
                </li>

                <li class="text-muted" v-if="getConcept.attributes['vat-type']=='exclusive'">
                    {{translation('vat')}}
                    <span class="numeric-text">{{ translateNumber(vatAmount, 2) }} {{ translation(currency.key, currency.value) }}</span>
                </li>

                <li class="text-muted">
                    {{translation('total')}}
                    <span class="numeric-text">{{ translateNumber(totalPrice, 2) }} {{ translation(currency.key, currency.value) }}</span>
                </li>

                <li class="text-muted" v-if="getConcept.attributes['vat-type']=='inclusive'">
                    {{translation('includes_vat').replace('VAT_RATE', !getConcept.attributes['vat-rate'] ? 0 : getConcept.attributes['vat-rate'])}}
                </li>
            </ul>
            <b-card class="cart-schedule-label" v-if="schedule">
                <b-form-group>
                    <label>
                    {{translation('order_scheduled_for', 'Order scheduled for: _MONTH_DAY_ at _TIME_').replace('_MONTH_DAY_',getSchedule()).replace('_TIME_', getSchedule('t')) }} <i class="fa fa-clock" :style="{ color: $root.mainColor }"></i>
                    </label>
                    <button class="btn" @click="clearSched">
                        <i class="fa fa-times"></i>
                    </button>
                </b-form-group>
            </b-card>
            <div class="form-group mt-3 mb-0" v-if="$route.name != 'checkout'">
                <button :class="{ 'allowed': getConcept.attributes['allow-advance-order'] }" @click="showSchedModal(true)" class="btn text-white bg-blue btn-schedule" :style="{color: $root.mainColor + '!important', background: '#fff'}">
                    <i class="fa fa-calendar"></i>
                </button>
                <button :class="{ 'allowed': getConcept.attributes['allow-advance-order'] }" class="btn text-white bg-blue btn-continue" @click="checkOutOrder" :style="btnBackground">{{translation('checkout')}}</button>
            </div>
            <p class="minimum-delivery" :class="{ 'mt-2': !getUser }" v-if="getOrderType=='deliver' && getConcept.attributes['minimum-order-amount-delivery'] > 0">{{ translation('minimum_delivery_amount_is_caps', 'Minimum delivery amount is _AMOUNT_').replace('_AMOUNT_', translateNumber(getConcept.attributes['minimum-order-amount-delivery'])) }}</p>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .btn-login {
        font-family: Work Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        text-align: center;
        display: block;
        margin: 5px;
    }
    .numeric-text {
        direction: ltr !important;
    }
    .minimum-delivery {
        font-family: Work Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 10px;
        color: #1E1E1E;
        text-align: center;

    }
</style>
<script>
import ErrorModal from '@/layouts/components/global/ErrorModal'
import ScheduleCalendar from './ScheduleCalendar.vue'
import { mapGetters, mapMutations } from "vuex";
import { TranslationMixin, HelperMixin } from "@/mixins";
import { eventHub } from '@/assets/plugins/events';
import { Order, Coupon } from '@/services/SOLO';
import moment from 'moment'
import { ErrorMixin } from '@/mixins'
const _ = require('lodash')

export default {
    data() {
        return {
            promo:'',
            types: {
                'deliver': 'delivery-location',
                'pickup': 'pickup-location',
                'eat-in': 'dinein-location'
            },
            showScheduleModal: false,
            schedule: null,
            error: false,
            errorMessage: null,
            okTitle: this.translation('okay'),
            qtyIsDisabled: false
        }
    },
    components: {
        ScheduleCalendar,
    },
    mixins: [TranslationMixin],
    created() {
      if (this.$route.query.coupon) {
        this.promo = this.$route.query.coupon;
        this.applyPromo();
      }
    },
    computed: {
        ...mapGetters({
            getItems: "cart/getItems",
            getConcept: "concept/getConcept",
            getUser: "account/getUser",
            getCart: "cart/getCart",
            isLoggedIn: "account/isLoggedIn",
            hasDefault: 'cart/hasDefault',
            getOrderType: 'cart/getOrderType',
            couponResult: 'cart/couponResult',
            couponCode: 'cart/couponCode',
            getLocale: 'app/getLocale',
            getAllowedOrderTypes: "app/getAllowedOrderTypes",
            getAllowedDeliveryPayments: "app/getAllowedDeliveryPayments",
            getAllowedPickupPayments: "app/getAllowedPickupPayments",
            getTapOrderID: "app/getTapOrderID",
            getTapOrderAmount: "app/getTapOrderAmount",
            getTapPaymentStatus: 'app/getTapPaymentStatus',
            getTapCredentials: 'app/getTapCredentials',
            getLocation: 'cart/getLocation',
            getPaymentType: 'app/getPaymentType',
            getDiscount: 'cart/getDiscount',
            getTemporaryDelivery: 'cart/getTemporaryDelivery',
            currency: 'concept/currency',
            getDiscounted: 'cart/getDiscounted'
        }),
        okText()
        {
            return this.translation('okay')
        },
        btnLoginStyle()
        {
            return {
                color: this.$root.mainColor
            }
        },
        btnBackground()
        {
            return {
                background: this.$root.mainColor,
                backgroundColor: this.$root.mainColor
            }
        },
        btnSecondaryBackground() {
            return {
                background: this.$root.secondaryColor,
                backgroundColor: this.$root.secondaryColor,
                color: this.$root.mainColor
            }
        },
        btnAddMoreFoodStyle()
        {
            return {
                color: this.$root.mainColor,
                border: '1px solid ' + this.$root.mainColor
            }
        },
        getSubTotal() {
            if (this.getCart.items.length) {
                return this.getCart.items
                    .map(item => (item.price + item.modifierPrice) * item.quantity)
                    .reduce((a, b) => a + b);
            }
        },
        freeDelivery() {
            if(Number(this.getConcept.attributes['minimum-order-amount-free-delivery'])) {
                if(this.getSubTotal >= Number(this.getConcept.attributes['minimum-order-amount-free-delivery']))  {
                    return true
                }
                return false
            }
            return false;
        },
        preTotalPrice() {
            if (this.getCart.items.length) {
                let finalTotal = this.getCart.items
                    .map(item => (item.price + item.modifierPrice) * item.quantity)
                    .reduce((a, b) => a + b);
                if(this.getDiscount) {
                    finalTotal = finalTotal - this.getDiscount
                }
                if (this.getOrderType === "deliver" && (this.hasDefault || (this.getTemporaryDelivery && !this.isLoggedIn)) && !this.freeDelivery) {
                    let deliveryCharge = this.getConcept.attributes['default-delivery-charge'] != undefined ? this.getConcept.attributes['default-delivery-charge'] : 0;
                    finalTotal = finalTotal + deliveryCharge;
                }
                return Number(finalTotal);

            }
            return 0;
        },
        totalPrice()
        {
            return this.getDiscounted ? this.getDiscounted : this.preTotalPrice + this.vatAmount;
        },
        getDiscountText() {
            if(this.couponResult["discount-type"] == "percentage") {
                return this.couponResult["discount-value"] + '% OFF'
            } else {
                return this.couponResult["discount-value"] + ' ' + this.translation(this.currency.key, this.currency.value) + ' OFF';
            }
        },
        getDiscountTest(){
           if (this.couponResult.hasOwnProperty("discount-value")) {
                if (this.couponResult["discount-type"] == "percentage") {
                    if (this.getCart.items.length) {
                        let finalTotal = this.getCart.items
                            .map(item => (item.price + item.modifierPrice) * item.quantity)
                            .reduce((a, b) => a + b);
                        this.getCart.discount = this.couponResult["discount-value"] / 100;
                        finalTotal =(finalTotal * this.couponResult["discount-value"]) / 100;
                        if(this.couponResult['discount-max'] <= 0 || (this.couponResult['discount-max'] > 0 && Number(finalTotal) < Number(this.couponResult['discount-max'])))
                        {
                            return finalTotal
                        }
                        return Number(this.couponResult['discount-max'])
                    }
                } else if (this.couponResult["discount-type"] == "fixed") {
                    this.getCart.discount = this.couponResult["discount-value"];
                    return this.couponResult["discount-value"];
                }
            }
            return 0;
        },

        getItem(){
            if(this.couponResult["item-name"] != null){
                this.getCart.discount = 0;
                return this.couponResult["item-name"];
            }
        },
        vatAmount(){
            let vatAmount = 0;

            if (this.getConcept.attributes['vat-type'] === 'exclusive') {
                vatAmount = this.preTotalPrice * (this.getConcept.attributes['vat-rate'] / 100);
            }

            return vatAmount;
        },
        vatTxt()
        {
            return this.getConcept.attributes['vat-type'] === 'exclusive' ? '' : this.translation('inclusive_vat','(inclusive of VAT)');
        }
    },
    mixins: [TranslationMixin, HelperMixin, ErrorMixin],
    methods: {
        ...mapMutations({
            setRedirectTo: 'cart/setRedirectTo',
            setCoupon: 'cart/setCoupon',
            setCouponResult: 'cart/setCouponResult',
            setDiscount: 'cart/setDiscount',
            setDiscounted: 'cart/setDiscounted'
        }),
        roundDown(dis) {

            return Math.floor(dis);
        },
        clearSched() {
            this.schedule = null
        },
        getSchedule(format = 'md') {
            if(format === 'md') {
                return moment(this.schedule).locale(this.getLocale).format('MMM DD')
            } else {
                return moment(this.schedule).locale(this.getLocale).format('h:mm A')
            }
        },
        setSchedule(datetime) {
            this.schedule = datetime
        },
        showSchedModal(val) {
            console.log(val)
            this.showScheduleModal = val
        },
        applyPromo(){
            if (this.promo != '' || this.couponCode != '') {

                let subtotal = 0

                if(this.getCart.items.length) {
                    subtotal = this.getCart.items
                    .map(item => (item.price + item.modifierPrice) * item.quantity)
                    .reduce((a, b) => a + b);
                }

                let total = subtotal;
                let location = null;
                let address = null;
                let delivery = 0;


                if ( this.getCart.type === 'deliver' && this.hasDefault && !this.freeDelivery ) {
                    total += this.getConcept.attributes["default-delivery-charge"];
                    delivery = this.getConcept.attributes["default-delivery-charge"];
                }

                let vatAmount = 0;

                if (this.getConcept.attributes["vat-type"] === "exclusive") {
                    vatAmount = total * (this.getConcept.attributes["vat-rate"] / 100);
                }

                total += vatAmount;


                if (this.getCart.couponCode) {
                    // this.coupons.push(this.getCart.couponCode);
                }

                let schedule = {}
                let isValidSchedule = moment(this.$route.params.datetime).isValid()
                if(isValidSchedule) {
                    schedule = {
                        "scheduled-time": this.$route.params.datetime,
                    }
                }

                let code = this.promo
                if(this.couponCode != '') {
                    code = this.couponCode
                }

                let params = {
                    source: "Web",
                    subtotal: subtotal,
                    discount: this.getDiscount,
                    // discount: this.convertDecimal(this.getDiscount,this.getConcept.attributes["decimal-places"]),
                    ...schedule,
                    location: location,
                    type: this.getCart.type,
                    "table-label": this.getCart.table,
                    "vat-amount": vatAmount,
                    total: total,
                    "coupon-code": code,
                    customer: this.getUser?.id,
                    items: this.getCart.items.map(item => {
                        let comboItems = [];

                        if (
                            item.attributes.hasOwnProperty("is-combo") &&
                            item.hasOwnProperty("combo-items")
                        ) {
                            if (item["combo-items"].length) {
                                item["combo-items"].map(combo => {
                                    comboItems.push({
                                        id: combo.id,
                                        price: combo.price,
                                        quantity: combo.quantity,
                                        ingredients: combo.ingredients,
                                        modifiers: combo.modifiers
                                            .filter(option => {
                                                return option.quantity;
                                            })
                                            .map(option => {
                                                return {
                                                    id: option.id,
                                                    price: option.price,
                                                    quantity: option.quantity
                                                };
                                            })
                                    });
                                });
                            }
                        }

                        let comboItemObj = comboItems.length
                            ? { "combo-items": comboItems }
                            : {};
                        return {
                            id: item.id,
                            price: item.price,
                            quantity: item.quantity,
                            notes: '',
                            discount: item.discount,
                          ingredients: item.hasOwnProperty("removed") ? item.removed.filter(ingredient => { return ingredient.checked })
                              .map(ingredient => {
                                return {
                                  id: ingredient.id,
                                  quantity: ingredient.quantity
                                };
                              }) : [],
                            modifiers: item.modifiers.map(modifier => {
                                return {
                                    id: modifier.id,
                                    price: modifier.attributes.price,
                                    quantity: modifier.quantity
                                };
                            }),
                            ...comboItemObj
                        };
                    }),
                    "delivery-charge": delivery,
                    address: address,
                    payment: {
                        method: 'cash',
                        "cash-presented": 0,
                        amount: total
                    }
                };

                Coupon.promotions(params).then(response => {
                    if(response.data.hasOwnProperty('error')) {
                        this.error = true
                        this.errorMessage = response.data.error
                        this.removeCoupon()
                    } else {
                        this.setDiscount(response.data.discount)
                        this.setDiscounted(response.data.total)
                        this.tempTotal = this.tempTotal - this.getDiscount
                        this.couponDiscount(code)
                    }
                }).catch(err => {
                    this.removeCoupon()
                })

            } else {
                this.$emit('promoApplied')
            }
        },
        couponDiscount(code) {
            Order.coupon(code).then(response => {
                if(response.data.data.length)
                {
                    if(!response.data.data[0].attributes['is-expired']) {
                        this.setCoupon(code)
                        this.setCouponResult(response.data.included[0].attributes)
                    } else {
                        this.removeCoupon()
                        this.$toasted.global.error({
                            message: this.translation('coupon_default_error')
                        });
                    }
                }
                this.$emit('promoApplied')
            }).catch(err => {
                this.$emit('promoApplied')
                this.removeCoupon()
                this.$toasted.global.error({
                    message: this.translation('coupon_default_error')
                });
            });
        },
        removeCoupon() {
            this.promo = ''
            this.setCoupon('')
            this.setCouponResult({})
            this.setDiscount(0)
            this.setDiscounted(0)
        },
        checkOutOrder() {

            if (!this.getItems.length && this.couponCode === '') {
                this.error = true
                this.errorMessage = this.translation('no_item_selected','No item selected')
            } else if(window.location.hostname.includes(process.env.VUE_APP_MINIM_SCHEDULE_URL) && !this.schedule) {
                this.showSchedModal(true)
            } else if(!this.hasDefault && (this.getCart.type === 'deliver' && _.isEmpty(this.getCart.temporaryDelivery))) {
                this.$router.push({name: this.types[this.getOrderType]});
            } else if(!this.isLoggedIn) {
                this.$root.$children[0].$refs.sidebar.$refs.GuestPhoneNumberModal.show();
            } else if(this.$route.name !== "checkout") {
                this.promo = '';
                let routeParams = {}

                if(this.schedule) {
                    routeParams = { name: "checkout", params: { datetime: moment(this.schedule).utc().format('YYYY-MM-DD HH:mm:ss') } }
                } else {
                    routeParams = { name: "checkout" }
                }
                this.$router.push(routeParams);
            } else {
                this.$toasted.global.error({
                  message: "Already in checkout page!"
                });
            }
        }
    }
};
</script>
