<template>
    <div id="search-box">
        <input
            id="pac-input"
            class="form-control"
            type="text"
            @focus="activateBox"
            @blur="deactivateBox"
            :placeholder="translation('type_delivery_location')"
        />
    </div>
</template>
<script>
import { TranslationMixin } from "../../../../../mixins";
export default {
    mixins: [TranslationMixin],
    methods: {
        activateBox()
        {
            this.$parent.$refs.searchBox.$el.classList.add('active')            
        },
        deactivateBox()
        {
            this.$parent.$refs.searchBox.$el.classList.remove('active')
        },
        createSearchControl(map, google) {
            var self = this;
            var input = document.getElementById("pac-input");
            var searchBox = new google.maps.places.SearchBox(input);
            let markers = [];
            searchBox.addListener("places_changed", function() {
                var places = searchBox.getPlaces();                
                
                if (places.length == 0) {
                    return;
                }

                markers.forEach(function(marker) {
                    marker.setMap(null);
                });

                var bounds = new google.maps.LatLngBounds();
                places.forEach(function(place) {
                    if (!place.geometry) {
                        console.log("Returned place contains no geometry");
                        return;
                    }             
                    console.log(place)
                    self.$parent.address.line1 = place.formatted_address
                    self.$parent.addMarker(place.geometry.location.lat(), place.geometry.location.lng())
                    self.$parent.zoom = 13
                    if (place.geometry.viewport) {
                        bounds.union(place.geometry.viewport);
                    } else {
                        bounds.extend(place.geometry.location);
                    }
                });
                map.fitBounds(bounds);
            });
        }
    }
};
</script>