import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import main from './main.routes'
import store from '../store'
const moment = require('moment')

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    ...main
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});


router.beforeEach(function (to, from, next) {
    const computed = {
        isLoggedIn: store.getters['account/isLoggedIn'],
        getPagesRules: store.getters['app/getPagesRules'],
        getItemUpdateTime: store.getters['cart/getItemUpdateTime']
    }      

    if (computed.getPagesRules.authenticatedRoutes.includes(to.name) && !computed.isLoggedIn) {        
        next('/home')
    } else{
        if (computed.isLoggedIn && to.name === 'login') {
            next(from.path)
        } else {
            next()
        }
    }
        
});

router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609  
});

export default router;
