<template>
	<section id="fast-food">
		<b-row>
			<b-col md="8">
				<h5>Fast food <small class="pl-4 text-muted">16 items</small></h5>
			</b-col>
			<b-col md="4" class="text-right">
				<button class="btn mr-3" @click="prev">
					<i class="fa fa-angle-left" aria-hidden="true"></i>
				</button>				
				<button class="btn"  @click="next">
					<i class="fa fa-angle-right" aria-hidden="true"></i>
				</button>
			</b-col>
		</b-row>
		<b-row class="mt-4">
			<b-col md="12">
				<carousel :autoplay="false" :nav="false" :dots="false" :items="3">
					<FoodCard></FoodCard>
					<FoodCard></FoodCard>
					<FoodCard></FoodCard>
					<FoodCard></FoodCard>
					<FoodCard></FoodCard>
					<FoodCard></FoodCard>
				</carousel>
			</b-col>
		</b-row>
	</section>
</template>

<script>
import carousel from 'vue-owl-carousel'
import FoodCard from './FoodCard'
export default {
	name: "Menu",
	components: { carousel, FoodCard },
	methods: {
		next(){
            $('.owl-next').click()                                
        },
        prev()
        {                
            $('.owl-prev').click()                
        }
	}
}
</script>