var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('gmap-map',{ref:"mapRef",staticStyle:{"width":"100%","height":"650px"},attrs:{"center":{lat: _vm.lat, lng: _vm.lng},"zoom":_vm.zoom,"options":{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false
    }}},[_c('GmapMarker',{attrs:{"position":_vm.myPosition,"clickable":true,"icon":'/images/illustrations/Delivery location pin.png'}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }