<template>
  <b-col md="12" id="order-page">
    <!-- <bookmark-modal :show.sync="showBookmarkModal" @close="showBookmarkModal = false"></bookmark-modal> -->
    <Header></Header>
    <StatusCard></StatusCard>
  </b-col>
</template>

<script>
import { Order } from "@/services/SOLO";
import Header from "./components/Header";
import StatusCard from "./components/StatusCard";
import BookmarkModal from './components/BookmarkModal.vue';
export default {
  data() {
    return {
      showBookmarkModal: false
    }
  },
  components: { Header, StatusCard, BookmarkModal },
  mounted() {
    this.showBookmarkModal = true
  }
};
</script>    