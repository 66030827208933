import BaseService from '../base.service'

let authService = class AuthService extends BaseService {

    constructor(){
        super();        
    }
    
    login(params: {}){
        let url = this.baseURL + '/login';        
        return super.post(url, params);
    }    

    logout(){
        let url = this.baseURL + '/logout';

        return super.post(url, {});
    }

    facebookLogin(params: {}){
        let url = this.baseURL + '/auth/facebook';

        return super.post(url, params);
    }
};

export default authService
