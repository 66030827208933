import CustomerService from './customer.service'
import ConceptService from './concept.service'
import LocationService from './location.service'
import MenuService from './menu.service'
import CategoryService from './category.service'
import ItemService from './item.service'
import OrderService from './order.service'
import SliderService from './slider.service'
import ConfigService from './config.service'
import CmsService from './cms.service'
import CheckoutService from './checkout.service'
import CouponService from './coupon.service'
import PaymentService from './payment.service'

let Customer = new CustomerService();
let Concept = new ConceptService();
let Location = new LocationService();
let Menu = new MenuService();
let Category = new CategoryService();
let Item = new ItemService()
let Order = new OrderService()
let Slider = new SliderService()
let Config = new ConfigService()
let Cms = new CmsService();
let Checkout = new CheckoutService()
let Coupon = new CouponService()
let Payment = new PaymentService()

export {
  Coupon,
  Customer,
  Concept,
  Location,
  Menu,
  Category,
  Item,
  Order,
  Slider,
  Config,
  Cms,
  Checkout,
  Payment
}