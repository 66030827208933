<template>
    <b-col v-if="!checkLocation && !isDisabled" md="4" class="card-hd">
        <b-card :style="cardStyle" @click="customizeItem({ name: 'item', params: { categoryId: strtoslug(getCategoryName($parent.$parent.category), $parent.$parent.category.id),id: strtoslug(getName(item), item.id) } })">
            <img
                class="product-img"
                :src="getImageSrc(item['image-uri'])"
                alt="Card image"
                width="100"
                v-if="item['image-uri']"
                style="border-radius:8px"
            />

            <div class="content-hd">
                <div class="description">
                    <p id="item-name" class="p-0 m-0">
                        <a style="color: #4A4A4A" href="#">{{ getName(item) }}</a>
                    </p>
                    <small
                        id="item-description"
                        v-if="item.description != null"
                    >{{ getDescription(item) }}</small>
                </div>
                <b-row style="display: block;">
                    <b-col class="calories" v-if="item.calories">
                        {{item.calories}} {{translation('kcal', 'Kcal')}}
                    </b-col>
                </b-row>

                <b-row :style="getLocale != 'en' ? 'display: flex; width: 100%;' : ''">
                    <b-col class="price" v-if="item.price">
                        <span
                            class="text-primary"
                            :style="priceStyle"
                            id="item-price"
                        >{{ translateNumber(item.price)}} </span><span
                            :style="priceStyle" class="text-primary sr">{{translation(currency.key, currency.value)}}</span>
                    </b-col>
                    <b-col class="tags" v-if="!getConcept.attributes['menu-only']">
                        <button
                            class="btn"
                            @click.stop="getModifiers"
                            :style="checkoutBtnStyle"
                        >{{translation('add_to_cart')}}</button>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </b-col>
</template>

<style lang="scss" scoped>
    .content-hd {
        .row {
            direction: ltr;
        }
    }
    body.rtl {
        .content-hd {
            .row {
                direction: rtl;
            }
        }
    }
</style>

<script>
import { TranslationMixin, HelperMixin, DataLayerMixin } from "@/mixins";
import { eventHub } from "@/assets/plugins/events";
import { mapGetters, mapMutations } from "vuex";
import { Item } from '@/services/SOLO';

export default {
    data() {
        return {
            priceStyle: {
                color: this.$root.mainColor + "!important"
            },
            customizeStyle: {
                color: this.$root.mainColor
            },
            checkoutBtnStyle: {
                color: this.$root.mainColor,
                border: "1px solid " + this.$root.mainColor,
                padding: "5px 18px"
            },
            modifiers: [],
            locales: {
                en: 'en-us',
                ar: 'ar-sa'
            }
        };
    },
    name: "Restaurant",
    props: ["item"],
    mixins: [TranslationMixin, HelperMixin, DataLayerMixin],
    mounted()
    {
        console.log(this.item, this.item.name[this.locales[this.getLocale.toLowerCase()]])
    },
    computed: {
        ...mapGetters({
            getConcept: 'concept/getConcept',
            getSettings: 'app/getSettings',
            getLocale: 'app/getLocale',
            getLastLocation: 'cart/getLastLocation',
            getOrderType: 'cart/getOrderType',
            isLoggedIn: 'account/isLoggedIn',
            getTemporaryDelivery: 'cart/getTemporaryDelivery',
            getCategories: 'app/getCategories',
            currency: 'concept/currency',
        }),
        cardStyle() {
            return {
                "--main-color": this.$root.mainColor
            };
        },
        isDisabled() {
            if(this.getOrderType === 'deliver') {
                if((!this.isLoggedIn && this.getTemporaryDelivery) || this.isLoggedIn) {
                    return this.item['disable-for-delivery'] === 1 ? true : false
                }
                return false
            } else {
                return this.item['disable-for-pickup'] === 1 ? true : false
            }
        },
        checkLocation()
        {
            if(this.item['excluded-locations'].length)
            {
                let arrayOfBol = this.item['excluded-locations'].map(location => {
                    return Number(location) === Number(this.getLastLocation.id)
                })

                return arrayOfBol.includes(true)
            }

            return false
        },
    },
    methods: {
        ...mapMutations("cart", {
            addItem: "addItem"
        }),
        getImageSrc(url) {
          return `https://cdn.getsolo.io/cdn-cgi/image/fit=cover,width=450,height=450/${url.replace('https://cdn.getsolo.io/','')}`
        },
        getName(item) {
            return item.name[this.locales[this.getLocale.toLowerCase()]] || item.name['en-us'];
        },
        getCategoryName(category) {
            return category.name[this.locales[this.getLocale.toLowerCase()]] || category.name['en-us'];
        },
        getDescription(item) {
            return item.description[this.locales[this.getLocale.toLowerCase()]] || item.description['en-us']
        },
        customizeItem(to)
        {
            this.$router.push(to)
        },
        addToCart() {
            
            const itemModifiers = [];

            this.modifiers.data.map(modifier => {
                const item = this.modifiers.included.map( data => {                    
                    if(modifier.attributes['default-modifiers']) {
                        if(modifier.attributes['default-modifiers'].includes(Number(data.id))) {
                            itemModifiers.push({
                                ...data,
                                quantity: 1
                            })   
                        }
                    }                    
                } )                
            })                     

            let attributes = { ...this.item, name: this.getItemName(this.$parent.$parent.category.id, this.item.id) }

            let item = {
                id: this.item.id,
                attributes: attributes,
                discount: 0,
                imageUri: this.item["image-uri"],
                price: this.item.price,
                quantity: 1,
                modifiers: itemModifiers,
                modifierPrice: 0,
                menuId: this.getSettings.attributes["default-menu-id"],
                categoryId: this.$parent.$parent.$refs.menu.activeCategory.id,
                subtotal: this.item.price,
                total: this.item.price,
                disabledPerLocation: this.item['excluded-locations']
            };

            this.addItem(item);

            eventHub.$emit("cart-added", true);

            this.$toasted.global.info({
                message: this.translation("item_added_to_cart")
            });
        },
        getModifiers()
        {
            console.log(this.item['must-be-customized'])

            if(this.item['must-be-customized'] || this.item['is-combo'])
            {
                return this.customizeItem({ name: 'item', params: { categoryId: this.strtoslug(this.$parent.$parent.category.name[this.locales[this.getLocale.toLowerCase()]], this.$parent.$parent.category.id),id: this.strtoslug(this.item.name[this.locales[this.getLocale.toLowerCase()]], this.item.id) } })
            }

            let params = {
                menuId: this.getSettings.attributes['default-menu-id'],
                categoryId: this.$parent.$parent.category.id,
                itemId: this.item.id
            };

            if(!this.item['is-combo'])
            {
                Item.fetchModifiers(params)
                .then(response => {
                    this.setDataLayer(this.$route.name, this.getLocale, 'productName: ' + this.item.name, 1)
                    this.modifiers = response.data
                    this.addToCart()
                })
                .catch(err => {
                    console.error(err)
                })
            }else
            {
                Item.fetchComboMealsModifiers(params)
                .then(response => {
                    this.setDataLayer(this.$route.name, this.getLocale, 'productName: ' + this.item.name, 1)
                    this.modifiers = response.data
                    this.addToCart()
                })
                .catch(err => {
                    console.error(err)
                })
            }
        },
        getItemName(categoryId, itemId) {
            let category = this.getCategories.filter(category => {
                return Number(category.id) === Number(categoryId)
            })

            let item = category[0].attributes.items.filter(item => {
                console.log(item.id, itemId)
                return Number(item.id) === Number(itemId)
            })

            return item[0].name
        },
        filterModifier(included, id)
        {
            return included.filter(data => {
                return data.id === id
            })
        },
    }
};
</script>
