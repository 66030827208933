<template>
    <b-row class="mt-4">
        <b-col md="12">
            <b-card class="bg-none border-0" id="main">
                <div class="addresses-wrapper pr-0">
                    <div class="list-content">
                        <b-card v-for="(address, index) in getUserAddresses" :key="index">
                            <button class="close">
                                <i class="fa fa-times" aria-hidden="true"></i>
                            </button>
                            <div class="title">
                                <img :src="getConcept.attributes['logo-uri']" height="30" alt />
                                {{address.attributes.label}}
                                <div class="address">
                                    <img src="/images/icons/blue-pin-small.png" height="15" alt />
                                    {{address.attributes.line1}}
                                </div>                                
                            </div>
                            <template v-slot:footer>
                                <button
                                    class="text-center"
                                    @click="setDefault(address)"
                                    :style="addressBtnStyle"
                                >{{translation('select_delivery_address')}}</button>
                            </template>
                        </b-card>
                    </div>
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>
<script>
import { TranslationMixin } from "../../../mixins";
import { mapGetters, mapMutations } from "vuex";
import { Customer } from "../../../services/SOLO";
import moment from "moment";
export default {
    data() {
        return {
            moment: moment(),
            types: {
                "dinein-location": "eat-in",
                "pickup-location": "pickup",
                "delivery-location": "deliver"
            },
            addressBtnStyle: {
                background: this.$root.mainColor
            }
        };
    },
    mixins: [TranslationMixin],
    computed: {
        ...mapGetters({
            getBranches: "app/getBranches",
            getOrderType: "cart/getOrderType",
            getUserAddresses: "account/getUserAddresses",
            getUser: "account/getUser",
            getConcept: 'concept/getConcept',
            getRedirectTo: 'cart/getRedirectTo',
        }),
        hasAddresses()
        {
            if(this.getUser.hasOwnProperty('addresses'))
            {
                if(this.getUser.addresses.length)
                {
                    return true
                }
                return false
            }
            return false
        }
    },
    mounted() {
        
    },
    methods: {
        ...mapMutations({
            setOrderType: "cart/setOrderType",
            setLocation: "cart/setLocation",
            setUserAddresses: "account/setUserAddresses",
            setRedirectTo: 'cart/setRedirectTo',
            setQueue: 'cart/setQueue',
            disableForceLocation: 'app/disableForceLocation'
        }),
        setDefault(address) {
            this.setOrderType(this.$route.meta.type);
            this.setLocation(address);
            this.setQueue(false)
            this.disableForceLocation(0)
            if (this.getRedirectTo === 'checkout') {
                this.setRedirectTo(null);
                this.$router.push({name: 'checkout'})
            } else  {
                if (this.getRedirectTo === 'cart') {
                    setTimeout(() => {
                        this.$root.$children[0].$refs.sidebar.showSideBar();
                    }, 100)
                }
                this.$router.push({name: 'home'})
            }
            this.$toasted.global.info({
                message: address.attributes.label + ' ' + this.translation('has_set_location', 'has been set as location')
            });
        },
        dayNumNow() {
            return moment().day();
        }
    }
};
</script>
