<template>
    <div class="row">
        <div class="col-md-12" id="profile-form">
            <div class="form-group">
                <div class="row">
                    <div class="col">
                        <b-form-input
                            v-model="$v.email.$model"
                            :placeholder="translation('email_address', 'Email Address')"
                            :state="$v.email.$dirty ? !$v.email.$error : null"
                            aria-describedby="input-3-live-feedback" :disabled="true"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-3-live-feedback">
                            This is a required field and must be a valid email.
                        </b-form-invalid-feedback>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col">
                        <b-form-input
                            :placeholder="translation('mobile', 'Mobile')"
                            v-model="$v.mobile.$model"
                            :state="$v.mobile.$dirty ? !$v.mobile.$error : null"
                            aria-describedby="input-4-live-feedback" :disabled="true"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-4-live-feedback">
                            This is a required field and must be a valid phone number.
                        </b-form-invalid-feedback>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col">
                        <b-form-input
                            type="text"
                            class="form-control"
                            :placeholder="translation('last_name', 'First name')"
                            v-model="$v.firstName.$model"
                            :state="$v.firstName.$dirty ? !$v.firstName.$error : null"
                            aria-describedby="input-1-live-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-1-live-feedback">
                            This is a required field and must be at least 2 characters.
                        </b-form-invalid-feedback>
                    </div>
                </div>
            </div>
            <div class="form-group" v-if="false">
                <div class="row">
                    <div class="col">
                        <b-form-input
                            type="text"
                            class="form-control"
                            :placeholder="translation('last_name', 'Last name')"
                            v-model="$v.lastName.$model"
                            :state="$v.lastName.$dirty ? !$v.lastName.$error : null"
                            aria-describedby="input-1-live-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-1-live-feedback">
                            This is a required field and must be at least 2 characters.
                        </b-form-invalid-feedback>
                    </div>
                </div>
            </div>
            <div class="form-group" v-show="getUser.attributes.provider === 3 && getUser.attributes['account-type'] === 'full' ? true : false">
                <div class="row">
                    <div class="col">
                        <b-form-input
                                id="example-input-5"
                                type="password"
                                name="example-input-5"
                                :placeholder="translation('password','Password')"
                                v-model="$v.password.$model"
                                :state="$v.password.$dirty ? !$v.password.$error : null"
                                aria-describedby="input-5-live-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-5-live-feedback">
                            This is a required field and should have 1 letter and number.
                        </b-form-invalid-feedback>
                    </div>
                </div>
            </div>
            <div class="form-group" v-show="getUser.attributes.provider === 3 && getUser.attributes['account-type'] === 'full' ? true : false">
                <div class="row">
                    <div class="col">
                        <b-form-input
                                id="example-input-6"
                                name="example-input-6"
                                type="password"
                                :placeholder="translation('re_enter_password','Re-enter password')"
                                v-model="$v.repeatPassword.$model"
                                :state="$v.repeatPassword.$dirty ? !$v.repeatPassword.$error : null"
                                aria-describedby="input-6-live-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-6-live-feedback">
                            This is a required field and must be equal with password field.
                        </b-form-invalid-feedback>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <button class="btn save-changes" :style="btnEditProfileStyle" @click="handleSubmit($event)">
                    {{translation('save_changes', 'SAVE CHANGES')}}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { TranslationMixin } from '@/mixins'
import {required, minLength, numeric, email, sameAs, helpers, maxLength} from 'vuelidate/lib/validators'
import { mapGetters, mapState } from 'vuex'
import { Customer } from '@/services/SOLO'
const alpha = helpers.regex('alpha', /^(?=.*[a-zA-Z])(?=.*\d).+$/)
export default {
    data()
    {
        return {
            password: '',
            repeatPassword: '',
            btnEditProfileStyle: {
                border: '1px solid ' + this.$root.mainColor,
                color: '#fff',
                background: this.$root.mainColor,
            }
        }
    },
    mixins: [TranslationMixin],
    validations: {
        firstName: {
            required,
            minLength: minLength(2)
        },
        lastName: {
            required,
            minLength: minLength(2)
        },
        email: {
            required,
            email
        },
        mobile: {
            required
        },
        password: {
            minLength: minLength(8)
        },
        repeatPassword: {
            sameAsPassword: sameAs('password')
        }
    },
    computed: {
        ...mapGetters({
            email: 'account/getEmail',
            mobile: 'account/getMobile',
            getUser: 'account/getUser'
        }),
        firstName: {
            get()
            {
                return this.$store.getters['account/getFirstName']
            },
            set(newEmail)
            {
                this.$store.commit('account/setFirstName', newEmail)
            }
        },
        lastName: {
            get()
            {
                if (this.$store.getters['account/getLastName'] === undefined) {
                    this.$store.commit('account/setLastName', '')
                }

                return this.$store.getters['account/getLastName']
            },
            set(newEmail)
            {   
                this.$store.commit('account/setLastName', newEmail)
            }
        }
    },
    methods: {
        handleSubmit(e)
    	{
            let defaultText = e.target.innerHTML
            e.target.innerHTML = '<i class="fa fa-circle-o-notch fa-spin"></i> ' + this.translation('loading_please_wait', 'Loading. Please wait...')
            e.target.disabled = true
    		this.$v.$touch();
    		if(this.$v.$anyError)
    		{
                e.target.innerHTML = defaultText
                e.target.disabled = false
    			return
    		}
    		let params = {
                'first-name': this.firstName,
                'last-name': this.lastName,
                'email': this.email,
                'mobile': this.mobile,
            };

            if(this.password != '')
            {
                if(this.password === this.repeatPassword) {
                    params.password = this.repeatPassword;
                }else{
                    return
                }
            }

            Customer.update(this.getUser.id, params).then((response) => {
                e.target.innerHTML = defaultText
                e.target.disabled = false
                this.password = ''
                this.repeatPassword = ''
                this.$v.$reset();
            }).catch((error) => {
                e.target.innerHTML = defaultText
                e.target.disabled = false
                this.$toasted.global.error({
                    message: "Sorry, something went wrong. Please try again!"
                });
            });
    	}
    }
}
</script>
