import store from '@/store'
const moment = require('moment');

export default {

    setTimestamp(state: {timestamp: any; }, data: any) {
        state.timestamp = data
    },

    setDiscount(state: { discount: any; }, data: any) {
        state.discount = data
    },

    setDiscounted(state: { discounted: any; }, data: any) {
        state.discounted = data
    },

    setTable(state: { table: any }, data: any) {
        state.table = data
    },

    setQueue(state: { queue: any }, data: boolean) {
        state.queue = data
    },

    setCoupon(state: { couponCode: any }, data: any) {
        state.couponCode = data
    },

    setCouponResult(state: { couponData: any }, data: any) {
        state.couponData = data
    },

    setOrderType(state: { type: any; }, data: any) {
        console.log(data)
        state.type = data
    },

    setTemporaryDelivery(state: { temporaryDelivery: any }, data: any) {
        state.temporaryDelivery = data
    },

    setDefaultStore(state: { defaultLocation: { pickup: any; }; }, data: any) {
        state.defaultLocation.pickup = data
    },

    setDefaultDelivery(state: { defaultLocation: { deliver: any; }; }, data: any) {
        state.defaultLocation.deliver = data
    },
    
    setLastLocation(state: { lastLocationSelected: any }, data: any) {
        state.lastLocationSelected = data
    },

    emptyLocations(state: { defaultLocation: { [x: string]: null; }; lastLocationSelected: any; })
    {
        // if(store.getters['concept/getConcept'].hasOwnProperty('attributes'))
        // {
        //     let types = store.getters['concept/getConcept'].attributes['allowed-order-types'].split(',')
        //     for (const i in types) {
        //         state.defaultLocation[types[i]] = null
        //         state.lastLocationSelected = {}
        //     }
        // }        
    },
    
    setLocation(state: { couponCode: any, couponData: any, discount: any, queue: boolean; type: string; defaultLocation: { [x: string]: any }; lastLocationSelected: any }, data: any) {
        state.queue = false        
        if (state.type != 'deliver') {
            let types = store.getters['app/getSettings'].attributes['allowed-order-types'].split(',')                        
            if (types.indexOf('deliver') >= 0) {
                types.splice(types.indexOf('deliver'), 1)                                
            }
            for (const i in types) {                
                state.defaultLocation[types[i]] = data
                state.lastLocationSelected = data
            }
        } else {            
            state.defaultLocation[state.type] = data
        }

        // state.couponCode = ''
        // state.couponData = {}
        // state.discount = 0        
        
    },

    resetCoupon(state: { couponCode: string; couponData: {}; discount: number; }) {
        state.couponCode = ''
        state.couponData = {}
        state.discount = 0        
    },

    addItem(state: { couponCode: any, couponData: any, discount: any, items: any[] }, data: any) {
        
        if (!state.items.length) {            
            state.items.push(data)            
        } else {
            let isDuplicate = false

            state.items.map(item => {
                if (data.id === item.id) {                    
                    if (checkModifiersIfSame({ data: data, item: item }) && checkIngredientsIfSame({ data: data, item: item })) {
                        item.quantity += data.quantity
                        if (item.modifiers.length) {
                            item.subtotal = ((item.attributes.price * item.quantity) + item.modifiers.map((modifier: any) => { return modifier.quantity * modifier.attributes.price }).reduce((a: any, b: any) => { return a + b })) * item.quantity
                            item.total = ((item.attributes.price * item.quantity) + item.modifiers.map((modifier: any) => { return modifier.quantity * modifier.attributes.price }).reduce((a: any, b: any) => { return a + b })) * item.quantity
                        } else {
                            item.subtotal = item.attributes.price * item.quantity
                            item.total = item.attributes.price * item.quantity
                        }

                        if (!isDuplicate) {
                            isDuplicate = true
                        }
                    }
                }
            })

            if (!isDuplicate) {
                state.items.push(data)
            }
        }

        // state.couponCode = ''
        // state.couponData = {}
        // state.discount = 0        
    },

    removeItem(state: { items: any[], couponCode: any, couponData: any, discount: any }, index: any) {
        state.items.splice(index, 1);
        // state.couponCode = ''
        // state.couponData = {}
        // state.discount = 0        
    },

    increaseQuantity(state: { items: { [x: string]: { quantity: number } }, couponCode: any, couponData: any, discount: any }, index: string | number) {
        state.items[index].quantity++
        // state.couponCode = ''
        // state.couponData = {}
        // state.discount = 0
    },

    decreaseQuantity(state: { items: { [x: string]: { quantity: number } }, couponCode: any, couponData: any, discount: any }, index: string | number) {
        if (state.items[index].quantity > 1) {
            state.items[index].quantity--
            // state.couponCode = ''
            // state.couponData = {}
            // state.discount = 0
        }
    },

    setType(state: { type: any }, data: any) {
        state.type = data
    },

    emptyCart(state: { items: never[], couponCode: any, couponData: any, discount: any }) {
        state.items = []
        // state.couponCode = ''
        // state.couponData = {}
        // state.discount = 0
    },

    setRedirectTo(state: { redirectTo: boolean }, data: any) {
        state.redirectTo = data;
    },

    setItemUpdateTime(state: { [x: string]: any }, data: any = null)
    {        
        state['items-updated-at'] = moment().format()
    },

    setLocationUpdateTime(state: { [x: string]: any })
    {
        state['location-update-at'] = moment().format()
    }


}

function checkIngredientsIfSame(data: { data: { modifiers: { id: number }[] }; item: { modifiers: { id: number }[] } } | any) {    
    if(data.data.hasOwnProperty('ingredients') && data.item.hasOwnProperty('ingredients'))
    {
        return JSON.stringify(data.data.ingredients.sort(function (a: { id: number }, b: { id: number }) { return a.id - b.id })) === JSON.stringify(data.item.ingredients.sort(function (a: { id: number }, b: { id: number }) { return a.id - b.id }))
    }
    return true    
}

function checkModifiersIfSame(data: { data: { modifiers: { id: number }[] }; item: { modifiers: { id: number }[] } } | any) {
    if (data.data.hasOwnProperty('combo-items')) {
        return JSON.stringify(data.data.modifiers.sort(function (a: { id: number }, b: { id: number }) { return a.id - b.id })) === JSON.stringify(data.item.modifiers.sort(function (a: { id: number }, b: { id: number }) { return a.id - b.id }))
            && JSON.stringify(data.data['combo-items'].sort(function (a: { id: number }, b: { id: number }) { return a.id - b.id })) === JSON.stringify(data.data['combo-items'].sort(function (a: { id: number }, b: { id: number }) { return a.id - b.id }))
    }
    return JSON.stringify(data.data.modifiers.sort(function (a: { id: number }, b: { id: number }) { return a.id - b.id })) === JSON.stringify(data.item.modifiers.sort(function (a: { id: number }, b: { id: number }) { return a.id - b.id }))
}