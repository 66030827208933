<template>
    <h5>Loading ....</h5>
</template>
<script>
import { Location } from '@/services/SOLO'
import { mapMutations, mapGetters } from 'vuex'
import { eventHub } from '@/assets/plugins/events'
import { TranslationMixin} from "@/mixins";
import { Order } from '@/services/SOLO';
export default {
    data()
    {
        return {
            params: {},
            branches: {},
        }
    },
    mixins: [TranslationMixin],
    computed: {
        ...mapGetters({
            getBranches: 'app/getBranches',
            getSettings: 'app/getSettings',
            getTimestamp: 'cart/getTimestamp',
            getConcept: 'concept/getConcept'
        })
    },
    mounted()
    {
        localStorage.setItem('isFromQueueBuster', true);
        this.params = this.getQueryParams()                         
        if(this.getBranches.length)    
        {
            console.log('test')
            this.branches = this.filterLocation(this.params.location)
            console.log(this.getBranches[0])
            this.setDefault(this.branches[0])
        }else
		{            
			eventHub.$on('app-ready', ()=> {	                	                
                this.getLocation()
            })                        	
        }
        
        
    },
    methods: {
        ...mapMutations({
            setOrderType: "cart/setOrderType",
            setLocation: "cart/setLocation",
            setTable: "cart/setTable",
            setQueue: "cart/setQueue",
            setBranches: 'app/setBranches',
            setCoupon: 'cart/setCoupon',
            setCouponResult: 'cart/setCouponResult',
            setTimestamp: 'cart/setTimestamp'            
        }),
        getLocation() {            
            Location.fetchLocations()
                .then(response => {                      
                    this.setBranches(response.data.data) 
                    eventHub.$emit('store-list')
                    this.branches = this.filterLocation(this.params.location)
                    if(this.branches.length)      
                    {
                        this.setDefault(this.branches[0])
                    }
                })
                .catch(err => {
                    console.error(err);
                });
        },
        filterLocation(id)
        {
            return this.getBranches.filter(branch => {
                return branch.id == id
            })
        },
        getQueryParams()
        {
            return this.$route.query
        },
        setDefault(branch) {            
            this.setOrderType(this.params['order-type']);                        
            if(this.params.table && this.params['order-type'] === 'eat-in')
            {                
                this.setLocation(branch);

                this.setTable(this.params.table)

                let date = new Date();
                
                date = date.setMinutes(date.getMinutes() + 30);
                
                this.setTimestamp(date)

                this.setQueue(true)
            } 
            
            if(this.params['order-type'] === 'deliver' && !this.getConcept.attributes['menu-only'])
            {
                this.$router.push({ name: "delivery-location" }); 
            } else {
                this.$router.push({ name: "home" });
            }    

            this.applyPromo();                            
            this.$toasted.global.info({
                message: branch.attributes.name + ' ' + this.translation('has_set_location', 'has been set as location')
            });
        },
        applyPromo(){
            if (this.params['coupon'] != '') {
                Order.coupon(this.params['coupon']).then(response => {
                    if(response.data.data.length)
                    {
                        this.setCoupon(this.params['coupon'])
                        this.setCouponResult(response.data.included[0].attributes)
                    }
                }).catch(err => {
                    console.log(err);
                });
            }
        },
    }
}
</script>