<template>
	<section id="jumbotron">
		<b-jumbotron>
			<b-row>
				<b-col md="2">
					
				</b-col>
				<b-col md="7">
					<p class="text-primary m-0">30% delivery free for 30 days</p>
					<small>Hello this is just a sample text.</small>
				</b-col>
				<b-col md="3">
					<b-button variant="primary">
						learn more
					</b-button>
				</b-col>
			</b-row>
		</b-jumbotron>
	</section>
</template>

<script>
export default {
	name: "Jumbotron"
}
</script>