<template>
    <b-container>
        <div v-if="!enableembed">
            <form id="payment-form" method="POST" action="https://merchant.com/charge-card">
                <label for="card-number">{{translation('checkout_card_number_caps', 'Card number')}}</label>
                <div class="input-container card-number">
                    <div class="icon-container">
                        <img id="icon-card-number" src="/images/card-icons/card.svg" alt="PAN" />
                    </div>
                    <div class="card-number-frame"></div>
                    <div class="icon-container payment-method">
                        <img id="logo-payment-method" />
                    </div>
                    <div class="icon-container">
                        <img id="icon-card-number-error" src="/images/card-icons/error.svg" />
                    </div>
                </div>

                <div class="date-and-code">
                    <div>
                        <label for="expiry-date">{{translation('checkout_expiry_date_caps','Expiry date')}}</label>
                        <div class="input-container expiry-date">
                            <div class="icon-container">
                                <img
                                    id="icon-expiry-date"
                                    src="/images/card-icons/exp-date.svg"
                                    alt="Expiry date"
                                />
                            </div>
                            <div class="expiry-date-frame"></div>
                            <div class="icon-container">
                                <img id="icon-expiry-date-error" src="/images/card-icons/error.svg" />
                            </div>
                        </div>
                    </div>

                    <div>
                        <label for="cvv">{{translation('checkout_security_code_caps','Security code')}}</label>
                        <div class="input-container cvv">
                            <div class="icon-container">
                                <img id="icon-cvv" src="/images/card-icons/cvv.svg" alt="CVV" />
                            </div>
                            <div class="cvv-frame"></div>
                            <div class="icon-container">
                                <img id="icon-cvv-error" src="/images/card-icons/error.svg" />
                            </div>
                        </div>
                    </div>
                </div>

                <button
                    id="pay-button"
                    disabled
                >{{translation('checkout_pay_button','Pay _AMOUNT_ _CURRENCY_').replace('_AMOUNT_',translateNumber(this.$route.params.amount)).replace('_CURRENCY_',translation(currency.key, currency.value))}}</button>
                <apple-pay-button buttonstyle="black" type="plain" locale="en"></apple-pay-button>

                <div>
                    <span class="error-message error-message__card-number"></span>
                    <span class="error-message error-message__expiry-date"></span>
                    <span class="error-message error-message__cvv"></span>
                </div>

                <p class="success-payment-message"></p>
            </form>
        </div>
        <div v-else>

            <iframe :src="embedURL" @load="onFrameLoad($event)" id="otpFrame" frameborder="0" height="500px"></iframe>
            <button class="btn btn-lg btn-transaction" :style="transactionBtnStyle" @click="toHome">
                {{translation('when_transaction_is_finished','When transaction has finished, click here')}}
            </button>
        </div>
    </b-container>
</template>
<style lang="scss" scoped>
.btn-transaction, .btn-cancel-transaction {
    background: var(--main-color);
    color: #fff;
    margin: 0 auto 0;
    display: block;
    border-radius: 40px;
    font-size: 13px;
    margin-bottom: 5px;
    min-width: 300px;
}
#otpFrame {
    width: 100%;
    height: 400px !important;
    margin-top: 50px;
    html {
        body {
            text-align: center;
        }
    }
}
html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
}
body {
    margin: 0;
}
main {
    display: block;
}
h1 {
    font-size: 2em;
    margin: 0.67em 0;
}
hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}
pre {
    font-family: monospace, monospace;
    font-size: 1em;
}
a {
    background-color: transparent;
}
abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
}
b,
strong {
    font-weight: bolder;
}
code,
kbd,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}
small {
    font-size: 80%;
}
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sub {
    bottom: -0.25em;
}
sup {
    top: -0.5em;
}
img {
    border-style: none;
}
button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}
button,
input {
    overflow: visible;
}
button,
select {
    text-transform: none;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
    -webkit-appearance: button;
}
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
    border-style: none;
    padding: 0;
}
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring,
button:-moz-focusring {
    outline: 1px dotted ButtonText;
}
fieldset {
    padding: 0.35em 0.75em 0.625em;
}
legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}
progress {
    vertical-align: baseline;
}
textarea {
    overflow: auto;
}
[type="checkbox"],
[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}
[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}
::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}
details {
    display: block;
}
summary {
    display: list-item;
}
template {
    display: none;
}
[hidden] {
    display: none;
}
*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    padding: 1rem;
    background-color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

iframe {
    /* This fixes a mobile Safari bug */
    height: 38px !important;
}

#payment-form {
    width: 280px;
    margin: 100px auto;
}

label {
    display: block;
    height: 10px;
    color: #13395e;
    font-size: 14px;
    font-weight: 500;
    line-height: 10px;
    margin: 8px 0;
}

.date-and-code {
    display: flex;
    margin-bottom: 8px;
}

.date-and-code > div:nth-child(1) {
    width: 55.715%;
}
.date-and-code > div:nth-child(2) {
    width: 45.719%;
}

.input-container {
    position: relative;
    display: flex;
    height: 40px;
}
.icon-container:last-child {
    right: 0;
}
.icon-container.payment-method {
    right: 0;
}

.input-container.card-number {
    margin-bottom: 8px;
}
.input-container.expiry-date {
    margin-right: 4px;
}
.input-container.cvv {
    margin-left: 4px;
}

.card-number-frame {
    padding-left: 40px;
}
.expiry-date-frame {
    padding-left: 40px;
}
.cvv-frame {
    padding-left: 40px;
}

div + button {
    margin-top: 8px;
}

.icon-container {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 26px;
    margin: 0 7px;
}

.icon-container.payment-method {
    transform: translateY(-50%) rotateY(90deg);
    transition: opacity 0.15s ease-out;
    opacity: 0;
    top: 50%;
}

.icon-container.payment-method.show {
    opacity: 1;
    transition: all 0.4s ease-out;
    transform: translateY(-50%) rotateY(0deg);
}

.icon-container.payment-method img {
    width: 100%;
}

[id$="-error"] {
    display: none;
}

.frame {
    opacity: 0;
}

.frame--activated {
    opacity: 1;
    border: solid 1px #13395e;
    border-radius: 3px;
    box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.2);
}

.frame--activated.frame--focus {
    border: solid 1px #13395e;
    box-shadow: 0 2px 5px 0 rgba(19, 57, 94, 0.15);
}

.frame--activated.frame--invalid {
    border: solid 1px #d96830;
    box-shadow: 0 2px 5px 0 rgba(217, 104, 48, 0.15);
}

.error-message {
    display: block;
    color: #c9501c;
    font-size: 0.9rem;
    margin: 8px 0 0 1px;
    font-weight: 300;
}

#pay-button {
    border: none;
    border-radius: 3px;
    color: #fff;
    font-weight: 500;
    height: 40px;
    width: 100%;
    background-color: #13395e;
    box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.4);
}

#pay-button:active {
    background-color: #0b2a49;
    box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.4);
}

#pay-button:hover {
    background-color: #15406b;
    box-shadow: 0 2px 5px 0 rgba(19, 57, 94, 0.4);
}

#pay-button:disabled {
    background-color: #697887;
    box-shadow: none;
}

#pay-button:not(:disabled) {
    cursor: pointer;
}

.success-payment-message {
    color: #13395e;
    line-height: 1.4;
}
.token {
    color: #b35e14;
    font-size: 0.9rem;
    font-family: monospace;
}

/**
IE11-targeted optimisations
 */
_:-ms-fullscreen,
:root .icon-container {
    display: block;
}

_:-ms-fullscreen,
:root .icon-container img {
    top: 50%;
    -ms-transform: translateY(-50%);
    position: absolute;
}

_:-ms-fullscreen,
#icon-card-number,
_:-ms-fullscreen,
#icon-expiry-date,
_:-ms-fullscreen,
#icon-cvv {
    left: 7px;
}

#checkout-frames-card-number::-ms-clear {
    display: none;
}

body.rtl {
    .input-container {
        direction: ltr !important;
    }
    // direction: ltr !important;
    label {
        text-align: right !important;
    }
    // .field {
    //     text-align: right !important;
    // }
    // .container {
    //     .container {
    //         form#payment-form {
    //             .card-number-frame {
    //                 padding-left: 0;
    //                 padding-right: 40px;
    //                 iframe#cardNumber {
    //                     direction: rtl !important;
    //                     html, body {
    //                         direction: rtl !important;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }
}
</style>
<script>
import { Checkout } from "@/services/SOLO";
import { mapGetters, mapMutations } from "vuex";
import { TranslationMixin } from "@/mixins";

export default {
    data() {
        return {
            enableembed: false,
            embedURL: null,
            status: null
        };
    },
    computed: {
        ...mapGetters({
            getConcept: "concept/getConcept",
            getCheckoutCredentials: "app/getCheckoutCredentials",
            currency: 'concept/currency',
        }),
        transactionBtnStyle()
        {
            return {
                '--main-color': this.$root.mainColor
            }
        }
    },
    mixins: [TranslationMixin],
    mounted() {
        let self = this;
        var payButton = document.getElementById("pay-button");
        var form = document.getElementById("payment-form");

        this.initGateway()

        var logos = generateLogos();
        function generateLogos() {
            var logos = {};
            logos["card-number"] = {
                src: "card",
                alt: "card number logo"
            };
            logos["expiry-date"] = {
                src: "exp-date",
                alt: "expiry date logo"
            };
            logos["cvv"] = {
                src: "cvv",
                alt: "cvv logo"
            };
            return logos;
        }

        var errors = {};
        errors["card-number"] = this.translation('checkout_enter_valid_card','Please enter a valid card number');
        errors["expiry-date"] = this.translation('checkout_enter_valid_expiry','Please enter a valid expiry date');
        errors["cvv"] = this.translation('checkout_enter_valid_cvv','Please enter a valid cvv code');

        Frames.addEventHandler(
            Frames.Events.FRAME_VALIDATION_CHANGED,
            onValidationChanged
        );
        function onValidationChanged(event) {
            var e = event.element;

            if (event.isValid || event.isEmpty) {
                if (e === "card-number" && !event.isEmpty) {
                    showPaymentMethodIcon();
                }
                setDefaultIcon(e);
                clearErrorIcon(e);
                clearErrorMessage(e);
            } else {
                if (e === "card-number") {
                    clearPaymentMethodIcon();
                }
                setDefaultErrorIcon(e);
                setErrorIcon(e);
                setErrorMessage(e);
            }
        }

        function clearErrorMessage(el) {
            var selector = ".error-message__" + el;
            var message = document.querySelector(selector);
            message.textContent = "";
        }

        function clearErrorIcon(el) {
            var logo = document.getElementById("icon-" + el + "-error");
            logo.style.removeProperty("display");
        }

        function showPaymentMethodIcon(parent, pm) {
            if (parent) parent.classList.add("show");

            var logo = document.getElementById("logo-payment-method");
            if (pm) {
                var name = pm.toLowerCase();
                logo.setAttribute("src", "/images/card-icons/" + name + ".svg");
                logo.setAttribute("alt", pm || "payment method");
            }
            logo.style.removeProperty("display");
        }

        function clearPaymentMethodIcon(parent) {
            if (parent) parent.classList.remove("show");

            var logo = document.getElementById("logo-payment-method");
            logo.style.setProperty("display", "none");
        }

        function setErrorMessage(el) {
            var selector = ".error-message__" + el;
            var message = document.querySelector(selector);
            message.textContent = errors[el];
        }

        function setDefaultIcon(el) {
            var selector = "icon-" + el;
            var logo = document.getElementById(selector);
            logo.setAttribute(
                "src",
                "/images/card-icons/" + logos[el].src + ".svg"
            );
            logo.setAttribute("alt", logos[el].alt);
        }

        function setDefaultErrorIcon(el) {
            var selector = "icon-" + el;
            var logo = document.getElementById(selector);
            logo.setAttribute(
                "src",
                "/images/card-icons/" + logos[el].src + "-error.svg"
            );
            logo.setAttribute("alt", logos[el].alt);
        }

        function setErrorIcon(el) {
            var logo = document.getElementById("icon-" + el + "-error");
            logo.style.setProperty("display", "block");
        }

        Frames.addEventHandler(
            Frames.Events.CARD_VALIDATION_CHANGED,
            cardValidationChanged
        );
        function cardValidationChanged(event) {
            payButton.disabled = !Frames.isCardValid();
        }

        Frames.addEventHandler(
            Frames.Events.CARD_TOKENIZATION_FAILED,
            onCardTokenizationFailed
        );
        function onCardTokenizationFailed(error) {
            Frames.enableSubmitForm();
        }

        // Frames.addEventHandler(Frames.Events.CARD_TOKENIZED, onCardTokenized);
        // function onCardTokenized(event) {
        //     var el = document.querySelector(".success-payment-message");
        //     el.innerHTML =
        //         "Card tokenization completed<br>" +
        //         'Your card token is: <span class="token">' +
        //         event.token +
        //         "</span>";
        // }

        Frames.addEventHandler(
            Frames.Events.PAYMENT_METHOD_CHANGED,
            paymentMethodChanged
        );
        function paymentMethodChanged(event) {
            var pm = event.paymentMethod;
            let container = document.querySelector(
                ".icon-container.payment-method"
            );

            if (!pm) {
                clearPaymentMethodIcon(container);
            } else {
                clearErrorIcon("card-number");
                showPaymentMethodIcon(container, pm);
            }
        }

        form.addEventListener("submit", function(event) {
            event.preventDefault();
            Frames.submitCard()
                .then(function(data) {
                    let params = {
                        order: self.$route.params.orderId,
                        token: data.token
                    };
                    self.submitOrderToken(params);
                })
                .catch(function(error) {
                    // handle error
                });
        });
    },
    methods: {
        ...mapMutations({
            emptyCart: 'cart/emptyCart',
            setCoupon: "cart/setCoupon",
            setCouponResult: "cart/setCouponResult",
            resetCoupon: "cart/resetCoupon"
        }),
        initGateway()
        {
            Frames.init({
                publicKey: this.getCheckoutCredentials['option_value'],
                localization: {
                    cardNumberPlaceholder: this.translation('checkout_card_number_caps', 'Card number'),
                    expiryMonthPlaceholder: this.translation('checkout_month_placeholder','MM'),
                    expiryYearPlaceholder: this.translation('checkout_year_placeholder','YY'),
                    cvvPlaceholder: this.translation('checkout_cvv_placeholder','CVV'),
                }
            });
        },
        submitOrderToken(params) {
            Checkout.submitToken(params).then(response => {
                this.enableembed = true
                if (response.data["is-successful"]) {
                    this.status = true
                    this.embedURL = response.data.redirection
                } else {
                    this.status = false
                    this.embedURL = response.data['failure-url']
                    this.$toasted.global.error({
                        message: this.translation('transaction_failed')
                    });
                }
            });
        },
        onFrameLoad(e)
        {
            // console.log(new XMLSerializer().serializeToString(e.target), e.target)
        },
        toHome(){
            if(this.status)
            {
                this.emptyCart()
                this.setCoupon('')
                this.setCouponResult({})
                this.resetCoupon()
            }
          this.$router.push({ name: 'status', params: { id: this.$route.params.orderId }});
        }
    }
};
</script>
