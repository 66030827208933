export default {
    getConcept(state: { concept: any; }){
        return state.concept;
    },

    getSettings(state: { concept: { settings: any; }; })
    {
        if(state.concept.settings)
        {
            return state.concept.settings
        }

        return []
    },
    getCategoryItems(state: { categoryItems: any; }){
        return state.categoryItems;
    },
    getInactiveCategories(state: { inactiveCategories: any; }) {
        return state.inactiveCategories;
    },
    currency(state: { concept: any }) {
        const currencyCode = state
            && state.concept
            && state.concept.attributes
            && state.concept.attributes['currency-code'] || 'SAR';
        return {
            key: currencyCode.toLowerCase(),
            value: currencyCode,
        };
    }
}
