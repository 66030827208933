<template>
    <section class="header-wrapper">
        <div class="toogle-back" :style="closeBtnStyle" @click="gotoMenu">
            <i class="fa fa-times" aria-hidden="true"></i>
        </div>
        <!-- <section class="header-thumbnail" :class="{'row': !isMoble}" style="padding-right:1.75rem" v-if="$parent.item.attributes" :style="{ backgroundImage: 'url('+ $parent.item.attributes['image-uri'] +')' }"> -->
        <section class="header-thumbnail" :class="{'row': !isMoble}"  v-if="$parent.item.attributes">
            <img class="header-thumbnail1"  :class="{'row': !isMoble}" v-if="$parent.item.attributes" :src="$parent.item.attributes['image-uri']" loading="lazy"/>
            <div :class="{'col-12': !isMoble}" v-show="false">
                <b-row>
                    <div class="col-11 col-md-11 offset-1 offset-md-1 card-item-description">
                        <b-card>
                            <h5 class="item-name">{{$parent.item.attributes.name}}</h5>
                            <small :style="priceStyle" class="item-price text-primary font-weight-bold pull">{{translateNumber($parent.item.attributes.price)}} {{translation(currency.key, currency.value)}}</small>
                            <small v-if="$parent.item.attributes.calories !== null">{{$parent.item.attributes.calories}} {{translation('kcal', 'Kcal')}}</small>
                            <p class="mb-0 pt-3 item-desc"><small>{{$parent.item.attributes.description}}</small></p>
                        </b-card>
                    </div>
                </b-row>
            </div>
        </section>
    </section>
</template>
<style lang="scss" scoped>
    .header-wrapper {
        .header-thumbnail {
            .header-thumbnail1 {
                direction: ltr;
                height: 352px;
                width: 373px;
                object-fit: cover;
            }
        }
    }
//    body.rtl {
//         .header-thumbnail {
//             .header-thumbnail1 {
//                 // margin-right: 10px;
//             }
//         }
//     }
</style>
<script>
import { TranslationMixin } from '@/mixins'
import { mapGetters } from 'vuex';
export default {
    data(){
        return {
            priceStyle: {
                color: this.$root.mainColor + '!important'
            }
        }
    },
    mixins: [TranslationMixin],
    computed: {
        ...mapGetters({
            currency: 'concept/currency',
        }),
        isMoble()
        {
            return screen.width < 768 ? true : false
        },
        closeBtnStyle()
        {
            return {
                background: this.$root.mainColor,
                color: '#fff'
            }
        }
    },
    methods: {
        gotoMenu()
        {
            this.$router.push({ name: 'home' })
        }
    }

}
</script>
