<template>
  <section class="header-wrapper">
    <h3>
      	<span class="header-back-content" @click="backToItem">
				<!-- <img src="/images/icons/Back.svg" alt=""> -->
			<i v-if="getLocale === 'en'" class="fa fa-long-arrow-left"></i> 			
			 {{translation('checkout')}}
			<i v-if="getLocale === 'ar'" class="fa fa-long-arrow-right"></i> 
		</span>
    </h3>
  </section>
</template>
<style lang="scss" scoped>
	.header-back-content {
		.fa-long-arrow-left {
			font-size: 30px;
		}
	}
</style>
<script>
import { TranslationMixin } from '@/mixins'
import { eventHub } from '../../../assets/plugins/events'
import { mapGetters } from 'vuex';
export default {
	mixins: [TranslationMixin],
	computed: {
		...mapGetters({
			getLocale: "app/getLocale",
		})
	},
	methods: {
		backToItem()
		{
			if (this.isMobile()) {
				eventHub.$emit("checkout-m-back", true);
			}
			this.$router.go(-1);
		},
		isMobile(){
            return screen.width < 768 ? true : false
        }
	}
}
</script>
