import store from '../store'

let userMixin = {        
    computed:
    {
        isLoggedIn() : boolean
        {
            return store.getters["account/isLoggedIn"];
        },
    },
    methods: {
        user() : Object | null
        {
            return store.getters['account/getUser']
        }
    }
}

export default userMixin