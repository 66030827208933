<template>
    <div>
        <SwitchView ref="switch-view"></SwitchView>
        <div style="position: relative;">
            <div class="locate-me" v-show="activeIdx == 0">
                <button @click="myLocation">
                    <i class="material-icons" aria-hidden="true">&#xe55c;</i>
                </button>
            </div>
            <gmap-map
                ref="mapRef"
                :center="{lat: lat, lng: lng}"
                v-show="activeIdx == 0"
                :zoom="zoom"
                style="width: 100%; height: 600px;"
                :options="{
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    disableDefaultUi: false
                }"
            >
                <GmapMarker
                    :key="index"
                    v-for="(m, index) in branches"
                    :position="m.position"
                    :clickable="true"
                    :icon="'/images/illustrations/Delivery location pin.png'"
                    @click="markerClicked(m)"
                    :z-index="1"
                    class="info-window"
                >
                    <gmap-info-window
                        v-if="shouldOpenInfoWindow(m.id)"
                        :options="infoWindowStyle"
                        fontFamily="Poppins"
                    >
                        <small class="d-block info-window-title">{{translation('location', 'Location')}}</small>
                        <small class="font-weight-bold info-window-sub-title">{{ m.attributes.name }}</small>
                        <button
                            class="btn btn-sm btn-select"
                            :style="btnSelectStyle"
                            @click="setDefault(m)"
                        >{{translation('select_this_branch')}}</button>
                    </gmap-info-window>
                </GmapMarker>
            </gmap-map>
        </div>
        <StoreLocationList class="list-view" v-show="activeIdx == 1"></StoreLocationList>
    </div>
</template>
<style lang="scss">
.gm-style {
    .gm-style-iw-c,.gm-style-iw-d {
        padding: 0;
        overflow: hidden;
    }
    .gm-style-iw-d {
        overflow: unset !important;
        padding: 10px;
    }
}

.locate-me {
    position: absolute;
    top: 450px;
    right: 10px;
    user-select: none;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    border-radius: 2px;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    width: 40px;
    height: 40px;
    z-index: 100;
    button {
        background: none;
        display: block;
        border: 0px;
        margin: 0px;
        padding: 0px;
        position: relative;
        cursor: pointer;
        user-select: none;
        overflow: hidden;
        width: 40px;
        height: 40px;
        top: 0px;
        left: 0px;
        color: #666;
        &:hover {
            color: #000;
        }
        i {
            vertical-align: middle;
            font-size: 27px !important;
        }
    }
}
.list-view {
    .card-body {
        @media screen and (max-width: 375px) {
            padding: 0;
        }
    }
    .store-list-wrapper {
        .list-content {
            .card {
                border: none;
                box-shadow: 0px 15px 34px -23px #efefef;
                margin-bottom: 30px;

                &:first-child {
                    margin: 10px 0 30px 0;
                }

                .close {
                    position: absolute;
                    top: -10px;
                    right: -5px;
                    font-size: 10px;
                    padding: 5px 7px;
                    background: #fff;
                    border: 1px solid #a5a5a5;
                    color: #a5a5a5;
                    border-radius: 100%;
                    opacity: 1;
                }

                .card-body {
                    font-size: 12px;
                    border: 1px solid #f9f9f9;
                    @media screen and (max-width: 375px) {
                        padding: .75rem;
                    }    
                    .title {
                        position: relative;
                        span{
                            font-size: 13px;
                        }
                        > img {
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }
                }

                .card-footer {
                    background: none;
                    padding: 0;

                    button {
                        display: block;
                        background: #3d99ff;
                        color: #fff;
                        border: none;
                        border-radius: 0;
                        width: 100%;
                        font-size: 13px;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;
                    }
                }

                .logo-holder{
                    display: inline-block; 
                    vertical-align: top;
                }

                .content-holder{
                    display: inline-block; 
                    width: calc(100% - 50px); 
                    padding-left: 15px;
                }
            }
        }
    }
}

body.rtl{
    .store-list-wrapper {
        .list-content {
            .card {
                .card-body {
                    .title {
                        text-align: right;
                        padding-right: 15px;
                    }
                }
            }
        }
    }
}
</style>
<script>
import Vue from "vue";
import { gmapApi } from "vue2-google-maps";
import { mapGetters, mapMutations } from "vuex";
import { Location } from "../../../../services/SOLO";
import SwitchView from "./components/SwitchView";
import StoreLocationList from "@/layouts/components/cart-components/StoreLocationList";
import { TranslationMixin } from "@/mixins";
import { eventHub } from '@/assets/plugins/events'

export default {
    data() {
        return {
            parentEl: this.$parent.$el,
            lat: 24.774265,
            lng: 46.738586,
            zoom: 7,
            branches: [],
            selectedLocation: null,
            directionsService: null,
            directionsRenderer: null,
            activeIdx: 0,
            infoWindowStyle: {
                minWidth: "150px"
            }
        };
    },
    mixins: [TranslationMixin],
    components: { SwitchView, StoreLocationList },
    computed: {
        google: gmapApi,
        ...mapGetters({
            getUser: "account/getUser",
            isLoggedIn: "account/isLoggedIn",
            getFirstName: "account/getFirstName",
            getLastName: "account/getLastName",
            getEmail: "account/getEmail",
            getMobile: "account/getMobile",
            getBranches: "app/getBranches",
            getLocation: "cart/getLocation",
            getDefaultLocations: "cart/getDefaultLocations",
            hasDefault: "cart/hasDefault",
            getConcept: "concept/getConcept",
            getRedirectTo: 'cart/getRedirectTo',
            getSettings: 'app/getSettings',
            getLastLocation: 'cart/getLastLocation',
            getLocale: 'app/getLocale'
        }),
        btnSelectStyle() {
            return {
                display: "block",
                background: this.$root.mainColor,
                color: "#fff",
                fontSize: "12px",
                width: "100%",
                marginTop: "5px"
            };
        },
        markerIcon() {
            var image = {
                url: this.getConcept.attributes["logo-uri"],
                className: "test"
            };
            return image;
        },
        activeView() {
            return this.$refs["switch-view"].activeIdx;
        }
    },
    watch: {
        getLocale(data) {
            // this.$parent.$parent.$refs.sidebar.$refs.cart.$refs.map.getLocation()
            // this.myLocation()
        },
        getLastLocation(data) {
            this.zoom = 14;
            this.$refs.mapRef.$mapPromise.then(map => {
                map.panTo(data.position);
            });
            this.markerClicked(data);
        }
    },
    mounted() {
        this.$root.sidebarOpen = true;
        this.$root.sidebarContainerRow = 4;
        this.$root.rootRowHeight = "100%";   
        // this.$parent.$parent.$refs.sidebar.$refs.cart.$refs.map.getLocation()         
        // this.myLocation()                 
        if (this.contains("col-md-6")) {
            this.removeClass("col-md-6");
            this.addClass("col-md-8");
        }

        if(this.getSettings.hasOwnProperty('attributes') && this.getConcept.hasOwnProperty('attributes'))
		{			
			this.displayBranches(this.getBranches);
            this.initMap();
            if (this.getLastLocation.hasOwnProperty('id')) {
                this.markerClicked(this.getLastLocation);
            }
		}else
		{
			eventHub.$on('app-ready', ()=> {	                	
				this.displayBranches(this.getBranches);
                this.initMap();
                if (this.getLastLocation.hasOwnProperty('id')) {
                    this.markerClicked(this.getLastLocation);
                }
			})
		}
        
    },
    methods: {
        ...mapMutations({
            setOrderType: "cart/setOrderType",
            setLocation: "cart/setLocation",
            setRedirectTo: 'cart/setRedirectTo',
            setQueue: 'cart/setQueue',
            disableForceLocation: 'app/disableForceLocation'
        }),
        myLocation()
        {            
            let self = this
            let google = this.google;             
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    function(position) {
                        self.$refs.mapRef.$mapPromise.then(map => {
                            let infoWindow = new google.maps.InfoWindow;
                            let lat = Number(position.coords.latitude);
                            let lng = Number(position.coords.longitude);
                            let pos = { lat: lat,lng: lng };
                            var geocoder = new google.maps.Geocoder();
                            self.$parent.$parent.$refs.sidebar.$refs.cart.$refs.map.getLocation(lat, lng)
                            geocoder.geocode(
                                {
                                    latLng: pos
                                },
                                function(results, status) {
                                    if (status == google.maps.GeocoderStatus.OK) {
                                        if (results[0]) {

                                            var icon = {
                                                url: '/images/illustrations/user\'s current GPS location.gif',
                                                scaledSize: new google.maps.Size(50, 50), // scaled size
                                                origin: new google.maps.Point(0,0), // origin
                                                anchor: new google.maps.Point(25,25) // anchor
                                            };

                                            var marker = new google.maps.Marker({
                                                position: pos,
                                                map: map,
                                                title: self.translation('your_location', 'Your Location'),
                                                icon: icon
                                            });
                                          
                                            let html = '<small class="d-block info-window-title">' + self.translation('your_location', 'Your Location') + '</small>';
                                            html += '<small class="font-weight-bold info-window-sub-title">' + results[0].formatted_address + '</small>';
                                          
                                           
                                            infoWindow.setPosition(pos);
                                            infoWindow.setContent(html);
                                            infoWindow.open(map, marker);
                                            map.setCenter(pos);
                                        }
                                    } else {
                                        self.$toasted.global.error({
                                            message: status
                                        });
                                    }
                                }
                            );                      
                        });
                        self.zoom = 16;
                    },
                    function() {
                        self.$refs.mapRef.$mapPromise.then((map) => {     
                            self.handleLocationError(true, infoWindow, self.myPosition, map);         
                        });
                    }
                );
            } else {
                this.$parent.$parent.$refs.sidebar.$refs.cart.$refs.map.getLocation(this.lat, this.lng)
                self.$refs.mapRef.$mapPromise.then((map) => {    
                    self.handleLocationError(true, infoWindow, self.myPosition, map);         
                });
            }
        },
        setDefault(branch) {
            this.setOrderType(this.$route.meta.type);            
            this.setLocation(branch);
            this.setQueue(false)
            this.disableForceLocation(0)
            if (this.getRedirectTo === 'checkout') {
                this.setRedirectTo(null);
                this.$router.push({name: 'checkout'})
            } else  {
              if (this.getRedirectTo === 'cart') {
                setTimeout(() => {
                  this.$root.$children[0].$refs.sidebar.showSideBar();
                }, 100)
              }
              this.$router.push({name: 'home'})
            }
            this.$toasted.global.info({
                message: branch.attributes.name + ' ' + this.translation('has_set_location')
            });
        },
        contains(className) {
            return this.$parent.$el.classList.contains(className)
                ? true
                : false;
        },
        removeClass(className) {
            this.$parent.$el.classList.remove(className);
        },
        addClass(className) {
            this.$parent.$el.classList.add(className);
        },
        ...mapMutations({
            setBranches: "app/setBranches"
        }),
        initMap() {
            let branches = this.branches;
            this.$refs.mapRef.$mapPromise.then(map => {
                let google = this.google;
                this.zoom = 11;                
                this.myLocation()
                this.directionsService = new google.maps.DirectionsService();
                this.directionsRenderer = new google.maps.DirectionsRenderer();
                this.directionsRenderer.setMap(this.$refs.mapRef.$mapObject);
            });
        },
        displayBranches(data) {
            if (data.length) {
                data.forEach(row => {
                    this.addMarker(row);
                });
            }
        },
        addMarker(branch) {
            this.branches.push(
                Object.assign(branch, {
                    position: {
                        lat: branch.attributes.lat,
                        lng: branch.attributes.long
                    },
                    opacity: 1,
                    draggable: true,
                    enabled: true,
                    clicked: 0,
                    rightClicked: 0,
                    dragended: 0,
                    ifw: false
                })
            );
        },
        centerMarker(branch) {
            this.$refs.mapRef.$mapPromise.then(map => {
                this.zoom = 13;
                map.panTo({
                    lat: branch.attributes.lat,
                    lng: branch.attributes.long
                });
            });
        },
        markerClicked(branch) {
            this.selectedLocation = branch;            
            this.centerMarker(branch);
        },
        shouldOpenInfoWindow(branchId) {
            if (this.selectedLocation) {
                return branchId === this.selectedLocation.id;
            }

            return false;
        },
        displayBranchTime(branch) {
            let branchDay = branch.attributes["opening-hours"][moment().day()];
            return branchDay.open + " - " + branchDay.closed;
        },
        setDirections(attributes) {
            let google = this.google;
            this.directionsRenderer.setMap(this.$refs.mapRef.$mapObject);
            let self = this;
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function(position) {
                    var start = new google.maps.LatLng(
                        position.coords.latitude,
                        position.coords.longitude
                    );
                    var end = new google.maps.LatLng(
                        attributes.lat,
                        attributes.long
                    );
                    let request = {
                        origin: start,
                        destination: end,
                        travelMode: "DRIVING"
                    };
                    self.directionsService.route(request, (result, status) => {
                        if (status == "OK") {
                            self.directionsRenderer.setDirections({
                                routes: []
                            });
                            self.directionsRenderer.setDirections(result);
                        } else {
                            self.$toasted.global.info({
                                message: "CAN'T LOCATE STREET DIRECTION"
                            });
                        }
                    });
                });
            } else {
                this.$toasted.global.info({
                    message: "Browser doesn't support Geolocation"
                });
            }
        },
        handleLocationError(browserHasGeolocation, infoWindow, pos, map) {
            infoWindow.setPosition(pos);
            infoWindow.setContent(browserHasGeolocation ?
                                  'Error: The Geolocation service failed.' :
                                  'Error: Your browser doesn\'t support geolocation.');
            infoWindow.open(map);
        }
    }
};
</script>
