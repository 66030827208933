<template>
    <div class="lang-selector" style="direction: ltr;">        
        <b-button-group size="sm" class="switch-btn">
            <b-button
                v-for="(btn, idx) in buttons"
                :key="idx"
                :class="{ 'active': getLocale.toLowerCase() === btn.caption }"
                @click="selected(btn)"
                variant="white"
                :id="'lang-id-' + idx"
                :style="{...btnActiveBackground(btn.caption)}"

            >{{ btn.text }}</b-button>
        </b-button-group>
    </div>
</template>
<style lang="scss" scoped>
    .btn-group {        
        display: block;
        text-align: center;
        direction: ltr !important;
        &.switch-btn {
            height: 40px;
            width: 120px;
            background: #FFFFFF;
            box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.1);
            border-radius: 100px;
            padding: 5px;            
            .btn {
                height: 30px;
                width: 55px;
                color: #B5B5B5;
                &.active {
                    background: #FFFFFF;
                    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
                    border-radius: 100px;
                }
            }
        }
    }
</style>
<script>
import { TranslationMixin } from "@/mixins";
import { mapGetters } from 'vuex';
import { eventHub } from '@/assets/plugins/events'
export default {
    data()
    {
        return {
            buttons: [
                { caption: "en", text: 'EN' ,state: false },
                { caption: "ar", text: 'ع' , state: true }
            ],
        }
    },
    computed: {
        btnBackground()
        {
            return {
                background: this.$root.secondaryColor,
                backgroundColor: this.$root.secondaryColor,
                border: '1px solid ' + this.$root.mainColor
            }
        },
        ...mapGetters({
            getLocale: 'app/getLocale'
        })
    },
    mixins: [TranslationMixin],
    mounted()
    {        
        this.selected({ caption: this.getLocale });
    },
    methods: {
        btnActiveBackground(caption) {                        
            if(this.getLocale.toLowerCase() === caption)
            {
                return {
                    color: this.$root.mainColor
                }
            }

            return {
                
            }
        },
        selected(btn) {
            const idx = this.buttons.findIndex(b => b.caption === btn.caption);
            const locale = btn.caption.toLowerCase()
            if (idx === 0) {
                this.buttons[idx].state = true;
                this.buttons[idx + 1].state = false;
                this.setLocale(locale);
                if(document.body.classList.contains('rtl'))
                {
                    document.body.classList.remove('rtl')
                }
            } else if (idx === 1) {
                this.buttons[idx].state = true;
                this.buttons[idx - 1].state = false;
                this.setLocale(locale);
                document.body.classList.add('rtl')
            }            
        },
    }
}
</script>
