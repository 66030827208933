import BaseService from '../base.service'

export default class MenuService extends BaseService{

    constructor(){
        super();
        this.baseURL = this.baseURL + '/menus'
    }

    all(page = 1){
        let url = this.baseURL + '';

        return super.get(url, {
            page: page
        });
    }

    find(menuId: string | number){
        let url = this.baseURL + '/' + menuId;

        return super.get(url)
    }

    upsells(menuId: string | number){

      let url = this.baseURL + '/'+ menuId +'/upsells'

      return super.get(url)
    }
}
