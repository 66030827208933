<template>
    <div id="order-page">
        <div class="status-card" :style="{ '--main-color': $root.mainColor }" v-if="order">
            <b-card class="order-id-card">
                <p class="order-id-label">{{translation('your_order_id')}}</p>
                <h1 class="order-id">{{order['display-id']}}</h1>
            </b-card>
            <b-card class="promised-time-card"  v-if="!order['scheduled-time']">
                <span class="promised-time-label">
                    {{ translation('promised_time', 'PROMISED TIME') }}
                </span>
                <span class="promised-time-get">{{ getPromisedTime(order['promised-time']) }}</span>
            </b-card>

            <b-card class="promised-time-card"  v-if="order['scheduled-time']">
                <span class="promised-time-label">
                    {{ translation('scheduled_at_caps', 'SCHEDULED AT') }}
                </span>
                <span class="promised-time-get">{{ getScheduledAt(order['scheduled-time']) }}</span>
            </b-card>

            <b-card class="order-status-card">
                <ul v-for="(status, index) in statuses" :key="index">
                    <li>
                        <span v-if="status['changed-at']">
                            {{UTC(status['changed-at'])}}
                        </span>
                        <span v-else>
                            &nbsp;
                        </span>
                    </li>
                    <li>
                        <span :class="{ 'is-done': status['is-done'] }">
                            <i class="fa" :class="{ 'fa-check' : status['changed-at'], 'fa-times' : !status['changed-at'] }"></i>
                        </span>
                    </li>
                    <li>
                        {{status.description}}
                    </li>
                </ul>
            </b-card>
            <b-card class="order-location-card">
                <div class="container">
                    <div class="left-label">
                        {{order.type === 'deliver' ? translation('address', 'Address') : translation('branch_name')}}
                    </div>
                    <div class="right-label">
                        {{ order['location-name'] }}
                    </div>
                </div>
                <!-- <router-link :to="{name: 'g-map', params: {lat: lat, lng: lng }}" v-if="order.attributes['type'] === 'to-go' || order.attributes['type'] === 'eat-in'">
                        <button class="btn btn-gmap">OPEN IN GOOGLE MAPS</button>
                </router-link> -->

            </b-card>

            <b-card class="order-type-card">
                <div class="container">
                    <div class="left-label">
                        {{translation('order_mode')}}
                    </div>

                    <div class="right-label">
                        {{order.type}}
                    </div>
                </div>
            </b-card>

            <b-card class="order-payment-card">
                <div class="container">
                    <div class="left-label">
                        {{translation('payment_method')}}
                    </div>
                    <div class="right-label">
                        {{ order['payment-method'] }}
                    </div>
                </div>
            </b-card>

            <b-card class="order-branch-number" v-if="callPhone">
                <div class="container">
                    <div class="left-label">
                        {{ translation('payment_branch_number', 'BRANCH NUMBER') }}
                    </div>
                    <div class="right-label">
                        <span class="numPhone"><a :href="'tel:+966' + callPhone" style="color: black">{{callPhone}}</a></span><i class="num fa fa-phone" ></i>
                    </div>
                </div>
            </b-card>

            <b-card class="order-items-card">
                <div class="container">
                    <div class="left-label">
                        {{translation('items_quantity_caps', 'ITEMS & QUANTITY')}}
                    </div>
                    <div class="right-label">
                        {{translation('price_caps', 'PRICE')}}
                    </div>
                </div>
                <div class="container" v-for="(item, index) in items" :key="index">
                    <div class="left-label">
                        {{ item.attributes.name }} <span class="qty">{{ item.attributes.quantity }}x</span>
                        <!-- <p class="modifiers" v-if="getIngredients(item).length">{{translation('remove')}} {{getIngredients(item).map(ingredient => { return ingredient.attributes.name }).join(', ')}}</p> -->
                    </div>
                    <div class="right-label">
                        {{ translateNumber(item.attributes['current-price'] * item.attributes.quantity, getConcept.attributes['decimal-places']) }}
                        <!-- {{ translateNumber(convertDecimal(getItemTotalPrice(item) * item.attributes.quantity, getConcept.attributes['decimal-places'])) }} -->
                    </div>
                </div>

                <div class="container subtotal">
                    <div class="left-label">
                        {{translation('subtotal', 'Sub Total')}}
                    </div>
                    <div class="right-label">
                        {{ translateNumber(order.subtotal) }}
                    </div>
                </div>

                <div class="container">
                    <div class="left-label">
                        {{ translation('discount', 'Discount') }}
                    </div>
                    <div class="right-label">
                        {{ translateNumber(order.discount) }}
                    </div>
                </div>

                <div class="container" v-if="order.type === 'deliver' || order.type === 'ايصال'">
                    <div class="left-label">
                        {{ translation('delivery_charge') }}
                    </div>
                    <div class="right-label">
                        {{ translateNumber(order['delivery-charge']) }}
                    </div>
                </div>

                <div class="container" v-if="getConcept.attributes['vat-type'] === 'exclusive'">
                    <div class="left-label">
                        {{translation('vat')}}
                    </div>
                    <div class="right-label">
                        {{ translateNumber(order['vat-amount']) }}
                    </div>
                </div>

                <div class="container end">
                    <div class="left-label">
                        {{ translation('total', 'Total') }}
                    </div>
                    <div class="right-label">
                        {{ translateNumber(order.total) }}
                    </div>
                </div>

                <div class="container inclusive-vat" v-if="getConcept.attributes['vat-type']=='inclusive'">
                    {{translation('includes_vat').replace('VAT_RATE', !getConcept.attributes['vat-rate'] ? 0 : getConcept.attributes['vat-rate'])}}
                </div>

            </b-card>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    #order-page {
        width: 100%;
        padding: 15px;
    }
    .content {
        margin-top: 100px;
        text-align: center;
    }
</style>
<script>
import { mapGetters, mapMutations } from 'vuex';
import { Customer, Order, Payment } from '@/services/SOLO'
import { TranslationMixin, HelperMixin } from '@/mixins'
import moment from 'moment'
export default {
    data() {
        return {
            processing: true,
            btnStyle: {
				'--main-color': this.$root.mainColor
            },
            titleStyle: {
                '--main-color': this.$root.mainColor
            },
            order: null,
            statuses: [],
            items: [],
            callPhone: null
        }
    },
    mixins: [TranslationMixin, HelperMixin],
    computed: {
        ...mapGetters({
            getKioskOrder: 'app/getKioskOrder',
            getTapPaymentResponse: 'app/getTapPaymentResponse',
            getKioskVerifyResponse: 'app/getKioskVerifyResponse',
            getLocale: 'app/getLocale',
            getToken: 'app/getToken',
            getConcept: "concept/getConcept",
        })
    },
    mounted() {
        this.kioskPaymentOrder()
        // console.log(this.getKioskVerifyResponse)
        // if(!this.getKioskVerifyResponse) {
        //     console.log(goSell)
        //     setTimeout(() => {
        //         goSell.showResult({
        //             callback: response => {
        //                 console.log('testing')
        //                 this.setTapPaymentResponse(response)
        //                 this.verifyCharges(this.getTapPaymentResponse.callback.status)
        //             }
        //         });
        //     }, 3000)
        // } else {

        // }
    },
    methods: {
        ...mapMutations({
            setTapPaymentResponse: 'app/setTapPaymentResponse',
            setKioskVerifyResponse: 'app/setKioskVerifyResponse'
        }),
        getPromisedTime(datetime) {
            return moment.utc(datetime,'YYYY-MM-DD h:mm:ss').locale(this.getLocale).local().format('h:mm A')
        },
        verifyCharges(status)
        {
            let params = {
                "order-id": this.getKioskOrder.id,
                "charge-id": this.getTapPaymentResponse.callback.id
            }

            Customer.tapVerification(params).then(response => {
                this.processing = false
                this.kioskPaymentOrder()
            }).catch((err) => {
                // this.emptyCart()
                // this.setCoupon('')
                // this.setCouponResult({})
            })
        },
        kioskPaymentOrder() {
            Payment.kioskPaymentOrder(this.getToken, 'items.modifiers,statuses').then(response => {
                this.order = response.data.data.attributes.order
                this.statuses = response.data.data.attributes.statuses
                this.items = response.data.included.items
                console.log(this.statuses)

            }).catch(err => {
                console.log(err.response)
            })
        },
        UTC(time)
        {
            return moment.utc(time,'YYYY-MM-DD h:mm:ss').locale(this.getLocale).local().format('h:mm A')
        },
        getIngredients(item) {
            if(item.relationships) {
                return item.relationships.ingredients.data.map(modifier => {
                    return this.included.filter(include => {
                        return modifier.id === include.id
                    })[0]
                })
            }
            return []
        },
    }
}
</script>
