<template>
    <div class="status-card" :style="{ '--main-color': $root.mainColor }" v-if="order.attributes !== undefined">
        <b-card class="order-id-card"  v-if="hyperpaySuccessStatus">
            <p class="order-id-label">{{translation('your_order_id')}}</p>
            <h1 class="order-id">{{order.attributes['display-id']}}</h1>
        </b-card>
        <b-card class="promised-time-card"  v-if="hyperpaySuccessStatus && !order.attributes['scheduled-time']">
            <span class="promised-time-label">
                {{ translation('promised_time', 'PROMISED TIME') }}
            </span>
            <span class="promised-time-get">{{ getPromisedTime(order.attributes['promised-time']) }}</span>
        </b-card>

        <b-card class="promised-time-card"  v-if="hyperpaySuccessStatus && order.attributes['scheduled-time']">
            <span class="promised-time-label">
                {{ translation('scheduled_at_caps', 'SCHEDULED AT') }}
            </span>
            <span class="promised-time-get">{{ getScheduledAt(order.attributes['scheduled-time']) }}</span>
        </b-card>

        <b-card class="order-hyperpay-card" v-if="hyperpayReadyStatus" :class="{ 'mt-4': !hyperpaySuccessStatus }">
            {{ paymentStatus }}
        </b-card>

        <b-card class="order-status-card" v-if="hyperpaySuccessStatus">
            <ul v-for="(status, index) in statuses" :key="index">
                <li>
                    <span v-if="status.attributes['changed-at']">
                        {{UTC(status.attributes['changed-at'])}}
                    </span>
                    <span v-else>
                        &nbsp;
                    </span>
                </li>
                <li>
                    <span :class="{ 'is-done': status.attributes['is-done'] }">
                        <i class="fa" :class="{ 'fa-check' : status.attributes['changed-at'], 'fa-times' : !status.attributes['changed-at'] }"></i>
                    </span>
                </li>
                <li>
                    {{status.attributes.description}}
                </li>
            </ul>
            <button class="btn btn-refresh" @click="trackOrder($event)">{{ translation('refresh_caps', 'REFRESH') }}</button>
        </b-card>
        <b-card class="order-location-card"  v-if="hyperpaySuccessStatus">
            <div class="container">
                <div class="left-label">
                    {{order.attributes.type === 'deliver' ? translation('address', 'Address') : translation('branch_name')}}
                </div>
                <div class="right-label">
                    {{ order.attributes['location-name'] }}
                </div>
            </div>
            <a :href="GmapUrl" target="_blank" v-if="order.attributes['type'] === 'to-go' || order.attributes['type'] === 'eat-in' || order.attributes['type'] === 'أكلت داخل' || order.attributes['type'] === 'سفري'">
                    <button class="btn btn-gmap" >{{ translation('g_map', 'OPEN IN GOOGLE MAPS') }}</button>
            </a>

        </b-card>
        <b-card class="order-type-card"  v-if="hyperpaySuccessStatus">
            <div class="container">
                <div class="left-label">
                    {{translation('order_mode')}}
                </div>
                <div class="right-label">
                    {{order.attributes.type}}
                </div>
            </div>
        </b-card>
        <b-card class="order-payment-card" v-if="hyperpaySuccessStatus">
            <div class="container">
                <div class="left-label">
                    {{translation('payment_method')}}
                </div>
                <div class="right-label">
                    {{ order.attributes['payment-method'] }}
                </div>
            </div>
        </b-card>

        <b-card class="order-branch-number" v-if="hyperpaySuccessStatus && callPhone">
            <div class="container">
                <div class="left-label">
                    {{ translation('payment_branch_number', 'BRANCH NUMBER') }}
                </div>
                <div class="right-label">
                    <span class="numPhone"><a :href="'tel:+966' + callPhone" style="color: black">{{callPhone}}</a></span><i class="num fa fa-phone" ></i>
                </div>
            </div>
        </b-card>

        <b-card class="order-items-card"  v-if="hyperpaySuccessStatus">
            <div class="container">
                <div class="left-label">
                    {{translation('items_quantity_caps', 'ITEMS & QUANTITY')}}
                </div>
                <div class="right-label">
                    {{translation('price_caps', 'PRICE')}}
                </div>
            </div>
            <div class="container" v-for="(item, index) in items" :key="index">
                <div class="left-label">
                    {{ item.attributes.name }} <span class="qty">{{ item.attributes.quantity }}x</span>
                    <p class="modifiers" v-if="getIngredients(item).length">{{translation('remove')}} {{getIngredients(item).map(ingredient => { return ingredient.attributes.name }).join(', ')}}</p>
                </div>
                <div class="right-label">
                    {{ translateNumber(getItemTotalPrice(item) * item.attributes.quantity) }}
                </div>
            </div>

            <div class="container subtotal">
                <div class="left-label">
                    {{ translation('subtotal', 'Sub Total') }}
                </div>
                <div class="right-label">
                    {{ translateNumber(order.attributes.subtotal) }}
                </div>
            </div>

            <div class="container">
                <div class="left-label">
                    {{translation('discount', 'Discount')}}
                </div>
                <div class="right-label">
                    {{ translateNumber(order.attributes.discount) }}
                </div>
            </div>

            <div class="container" v-if="order.attributes.type === 'deliver' || order.attributes.type === 'ايصال'">
                <div class="left-label">
                    {{ translation('delivery_charge') }}
                </div>
                <div class="right-label">
                    {{ translateNumber(order.attributes['delivery-charge']) }}
                </div>
            </div>

            <div class="container" v-if="hyperpaySuccessStatus && getConcept.attributes['vat-type']=='exclusive'">
                <div class="left-label">
                    {{translation('vat')}}
                </div>
                <div class="right-label">
                    {{translateNumber(order.attributes['vat-amount'])}}
                </div>
            </div>

            <div class="container end">
                <div class="left-label">
                    {{translation('total', 'Total')}}
                </div>
                <div class="right-label">
                    {{translateNumber(order.attributes.total)}}
                </div>
            </div>

            <div class="container inclusive-vat" v-if="hyperpaySuccessStatus && getConcept.attributes['vat-type']=='inclusive'">
                {{translation('includes_vat').replace('VAT_RATE', !getConcept.attributes['vat-rate'] ? 0 : getConcept.attributes['vat-rate'])}}
            </div>

        </b-card>
        <div class="container" v-if="getConcept.attributes['instagram-link'] || getConcept.attributes['facebook-link'] || getConcept.attributes['snapchat-link'] || getConcept.attributes['twitter-link']">
            <p class="text-center mt-4">{{translation('social_media', 'Follow us on our social media')}} </p>
            <div class="d-flex flex-row justify-content-center">
                <a class="p-1" :href="getConcept.attributes['instagram-link']" target="_blank" v-if="getConcept.attributes['instagram-link']"><img src="/images/social-icons/Instagram.png" /></a>
                <a class="p-1" :href="getConcept.attributes['facebook-link']" target="_blank" v-if="getConcept.attributes['facebook-link']"><img src="/images/social-icons/Facebook.png" /></a>
                <a class="p-1" :href="getConcept.attributes['snapchat-link']" target="_blank" v-if="getConcept.attributes['snapchat-link']"><img src="/images/social-icons/Snapchat.png" /></a>
                <a class="p-1" :href="getConcept.attributes['twitter-link']" target="_blank" v-if="getConcept.attributes['twitter-link']"><img src="/images/social-icons/Twitter.png" /></a>
            </div>
        </div>
    </div>
</template>

<script>
import { TranslationMixin, HelperMixin } from '@/mixins'
import { Order, Item } from '@/services/SOLO'
import moment from 'moment'
import { mapGetters, mapMutations } from 'vuex'
import { eventHub } from '@/assets/plugins/events'
export default {
    data()
    {
        return {
            order: {},
            items: [],
            statuses: [],
            included: [],
            paymentStatus: '',
            hyperpayReadyStatus: false,
            hyperpaySuccessStatus: false,
            callPhone: '',
            locations: [],
            lat: '',
            lng: '',
            GmapUrl: ''
        }
    },
    mixins: [TranslationMixin, HelperMixin],
    computed: {
        ...mapGetters({
            getLocale: 'app/getLocale',
            getConcept: "concept/getConcept",
            getPaymentType: 'app/getPaymentType'
        }),
        colorStyle()
        {
            return {
                color: this.$root.mainColor
                //background: this.$root.secondaryColor
            }
        },
        txtStyle()
        {
            return {
                color: this.$root.mainColor,
                'font-size': '12px'
            }
        },
        chckStyle()
        {
            return {
                background: this.$root.secondaryColor,
                'border-radius' : '3px',
                'padding': '2px'
            }
        },
        vatTxt()
        {
            return this.getConcept.attributes['vat-type'] === 'exclusive' ? '' : this.translation('inclusive_vat','(inclusive of VAT)');
        }
    },
    mounted()
    {
        this.trackOrder()

        if(this.$route.params.orderId) {
            this.hyperpayStatus()
        } else {
            this.hyperpaySuccessStatus = true
        }

        eventHub.$on('change-lang', ()=> {
            this.trackOrder()
        })
    },
    methods: {
        ...mapMutations({
            emptyCart: 'cart/emptyCart',
            setCoupon: "cart/setCoupon",
            setCouponResult: "cart/setCouponResult"
        }),
        getPromisedTime(datetime) {
            return moment.utc(datetime,'YYYY-MM-DD h:mm:ss').locale(this.getLocale).local().format('h:mm A')
        },
        getScheduledAt(datetime) {
            return moment.utc(datetime,'YYYY-MM-DD h:mm:ss').locale(this.getLocale).local().format('MMM DD Y h:mm A')
        },
        hyperpayStatus() {
            let params = {
                checkout_id: this.$route.params.orderId,
                method: this.getPaymentType()
            };

            Order.hyperpayStatus(params)
                .then((response) => {
                this.hyperpayReadyStatus = true
                this.hyperpaySuccessStatus = true
                this.paymentStatus = this.translation('hyperpayment_success', 'Your payment was successful')
                this.emptyCart();
                this.setCoupon('')
                this.setCouponResult({})

                })
                .catch((error) => {
                this.hyperpayReadyStatus = true
                this.hyperpaySuccessStatus = false
                this.emptyCart();
                this.setCoupon('')
                this.setCouponResult({})
                this.paymentStatus = error.response.data.messages.result.description
            });
        },
        trackOrder(e = null)
        {
            let defaultText = ''
            if(e) {
                defaultText = e.target.innerHTML;
                e.target.innerHTML ='<i class="fa fa-circle-o-notch fa-spin"></i> ' + this.translation('loading');
            }
            Order.find(this.$route.params.id).then(response => {
                this.order = response.data.data
                let locations = response.data.included
                // console.log("order status", this.order);
                // console.log("included", response.data.included);
                locations.forEach(location => {
                    if(location.type === 'location') {
                        this.lat = (location.attributes.lat).toFixed(6)
                        this.lng = (location.attributes.long).toFixed(6)
                        this.callPhone =  location.attributes['telephone'];

                    }
                });
                this.GmapUrl = 'https://www.google.com/maps/?q=' + this.lat + ',' + this.lng;
                // console.log('lat', this.lat);
                // console.log('lng', this.lng);
                if(e) {
                    e.target.innerHTML = defaultText;
                    e.target.disabled = false;
                }
            }).catch(err => {
                console.log(err)
                if(e) {
                    e.target.innerHTML = defaultText;
                    e.target.disabled = false;
                }
            })

            Order.findItems(this.$route.params.id).then(response => {
                this.items = response.data.data
                this.included = response.data.included
                console.log(this.items, this.included)

            }).catch(err => {
                console.log(err)
            })

            Order.statuses(this.$route.params.id).then(response => {
                this.statuses = response.data.data
                console.log(this.statuses)
            }).catch(err => {
                console.log(err)
            })

        },
        findItem() {
            Item.find(params, "ingredients") //category,allergen,ingredients
            .then(response => {

            })
            .catch(err => {
                console.error(err)
            })
        },
        getModifiers(item)
        {
            return item.relationships.modifiers.data.map(modifier => {
                return this.included.filter(include => {
                    return modifier.id === include.id
                })[0]
            })
        },
        getIngredients(item) {
            return item.relationships.ingredients.data.map(modifier => {
                return this.included.filter(include => {
                    return modifier.id === include.id
                })[0]
            })
        },
        getItemTotalPrice(item)
        {
            let totalModifiersPrice = item.relationships.modifiers.data.map(modifier => {
                return this.included.filter(include => {
                    return modifier.id === include.id
                })[0]
            }).map(modifier => {
                return modifier.attributes.price * modifier.attributes.quantity;
            })

            if(totalModifiersPrice.length)
            {
                totalModifiersPrice = totalModifiersPrice.reduce((a, b) => {
                    return a + b
                })
            }else
            {
                totalModifiersPrice = 0
            }

            return item.attributes.price;

        },
        UTC(time)
        {
            return moment.utc(time,'YYYY-MM-DD h:mm:ss').locale(this.getLocale).local().format('h:mm A')
        },
    }
}
</script>
