<template>
    <div class="toogle-menu"
         v-if="getConcept.attributes.hasOwnProperty('menu-only') && !getConcept.attributes['menu-only']"
         :style="toogleMenuStyle"
         :class="{'item-page-menu': $route.name == 'item'}"
         @click="showSideBar">
        <i class="fa fa-bars" aria-hidden="true"></i>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { eventHub } from '../../assets/plugins/events'
export default {
    data()
    {
        return {
            top: '15px'
        }
    },
	computed: {
        ...mapGetters({
            getLocale: 'app/getLocale',
            getConcept: 'concept/getConcept',
        }),
        toogleMenuStyle()
        {
            return {
                background: this.$root.mainColor,
                color: '#fff',
                top: this.top
            }
        }
    },
    mounted() {
        eventHub.$on("cart-added", isTrue => {
            this.top = '40px'
            setTimeout(() => {
                this.$toasted.clear()
                this.top = '15px'
            }, this.$toasted.options.duration);
        });
    },
    methods: {
		showSideBar()
		{
			this.$root.$children[0].$refs.sidebar.showSideBar();
		}
	}
}
</script>
