import axios from 'axios'
import config from './config'
import store from '@/store'
import { eventHub } from "@/assets/plugins/events";

let baseService = class BaseService {

    baseURL: string;
    translationURL: string;
    headers: any;
    $http: any;
    config = config;
    appObjectKey: string;
    conceptId: string | number = ''
    appConfigHeaders: Object = {};
    activeDomain: string = '';

    constructor() {

        this.config = config
        this.appObjectKey = 'Solo-App'
        this.baseURL = process.env.VUE_APP_API_URL
        this.translationURL = this.config.translationURL
        /**
         * kebabcreation.ordernosh.com/sandwiches-4448/item/pesto-meat-kebab-37630
         */

        // this.activeDomain = window.location.hostname.includes('localhost') ? 'wayback.ordernosh.com' : window.location.hostname
        // this.activeDomain = window.location.hostname.includes('localhost') ? 'qburger.ordernosh.com' : window.location.hostname
        // this.activeDomain = window.location.hostname.includes('localhost') ? `janburger.ordernosh.com` : window.location.hostname
        // this.activeDomain = window.location.hostname.includes('localhost') || window.location.hostname.includes('192.168.43.9') ? 'order.pizzaratti.com' : window.location.hostname
        // this.activeDomain = window.location.hostname.includes('localhost') || window.location.hostname.includes('192.168.43.9') ? 'bk-ksa.ordernosh.com' : window.location.hostname
        // this.activeDomain = window.location.hostname.includes('localhost') ? 'order.pizzaratti.com' : window.location.hostname
        // this.activeDomain = window.location.hostname.includes('localhost') ? 'demos.ordernosh.com' : window.location.hostname
        // this.activeDomain = window.location.hostname.includes('localhost') ? 'test.ordernosh.com' : window.location.hostname
        // this.activeDomain = window.location.hostname.includes('localhost') ? 'smokinburger.ordernosh.com' : window.location.hostname
        // this.activeDomain = window.location.hostname.includes('localhost') ? 'quick-market.ordernosh.com' : window.location.hostname
        // this.activeDomain = window.location.hostname.includes('localhost') ? 'kyochon.ordernosh.com' : window.location.hostname
        // this.activeDomain = window.location.hostname.includes('localhost') ? 'tazajordering.ordernosh.com' : window.location.hostname
        this.activeDomain = window.location.hostname.includes('localhost') ? 'mawarid.ordernosh.com' : window.location.hostname

        this.headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            // 'Solo-App': null
        };

        this.$http = axios.create({
            timeout: 1600000
        });

        this.$http.interceptors.request.use((config: any) => {
            return config
        })

        this.$http.interceptors.response.use((response: any) => {
            return response
        })
    }

    getHeaders(additionalHeaders = {}, multipart = false) {
        let defaultHeaders = this.headers;
        if (store.getters['app/getLocale']) {
            defaultHeaders = { ...defaultHeaders, ...{ 'Accept-Language': store.getters['app/getLocale'] === 'en' ? 'en-us' : 'ar-sa' } }
        } else {
            if (store.getters['concept/getConcept'].hasOwnProperty('attributes')) {
                defaultHeaders['Accept-Language'] = store.getters['concept/getConcept'].attributes['primary-language']
            } else {
                defaultHeaders['Accept-Language'] = 'en-us'
            }
        }

        if(store.getters['concept/getConcept'].hasOwnProperty('attributes'))
        {
            additionalHeaders = { ...additionalHeaders, ...{'Solo-App' : store.getters['concept/getConcept'].attributes.key}}
        }

        if (store.getters['account/isLoggedIn']) {
            defaultHeaders.Authorization = 'Bearer ' + store.getters['account/getUser'].attributes.token
        }

        return {
            ...defaultHeaders,
            ...additionalHeaders
        }
    }

    prepareUrl(url: string, params: any) {
        for (let index in params) {
            let identifier = ':' + index;
            url = url.replace(identifier, params[index]);
        }
        return url;
    }

    getQueryString(params: { [x: string]: string | number | boolean; }) {
        return (
            Object
                .keys(params)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                .join('&')
        )
    }

    post(uri: string, data: {}, additionalHeaders = {}) {
        return this.$http.post(uri, data, {
            headers: this.getHeaders(additionalHeaders),
        })
    }

    put(uri: any, data: any, additionalHeaders = {}) {
        return this.$http.put(uri, data, {
            headers: this.getHeaders(additionalHeaders),
        })
    }

    patch(uri: any, data: any, additionalHeaders = {}) {
        return this.$http.patch(uri, data, {
            headers: this.getHeaders(additionalHeaders),
        })
    }

    remove(uri: any, additionalHeaders = {}) {
        return this.$http.delete(uri, {
            headers: this.getHeaders(additionalHeaders),
        })
    }

    get(uri: string, data = {}, additionalHeaders = {}) {
        if (Object.keys(data).length > 0) {
            uri = `${uri}?${this.getQueryString(data)}`
        }

        return this.$http.get(uri, {
            headers: this.getHeaders(additionalHeaders),
        })
    }

    getJson(uri: string, data = {}, additionalHeaders = {}) {
        if (Object.keys(data).length > 0) {
            uri = `${uri}?${this.getQueryString(data)}`
        }

        return this.$http.get(uri)
    }

    mcxGet(uri: string, data = {}, additionalHeaders = {}) {
        if (Object.keys(data).length > 0) {
            uri = `${uri}?${this.getQueryString(data)}`
        }

        return this.$http(uri, {
            method: 'GET',
            mode: 'no-cors',
            headers: {
                'Accept-Ranges': 'bytes',
                'Content-Type': 'application/json',
                'Connection': 'keep-alive'
            },
        })
    }
};

export default baseService
